import { Fragment, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import {
  Alert,
  Backdrop,
  Card,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { useFormikProps } from 'utils/ui/formik';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import PublicIcon from '@mui/icons-material/Public';
import RowingIcon from '@mui/icons-material/Rowing';
import { PATH_POLICY } from 'routes/paths';
import InfoItem from './components/InfoItem';
import SaveIcon from '@mui/icons-material/Save';
import CountriesSelect from '../step-1/components/CountrySelect';
import { useSelector } from 'redux/store';
import { useFromDateToDateString } from 'utils/dates';
import { TextInput } from './components/TextInput';
import { useSaveOverprotectionPolicyDataRequest } from 'redux/slices/baseApi';
import { getErrorMessage } from 'utils/errors';
import { getLifestyleActivityLabel } from 'assets/data/lifestyleActivityOptions';
import { PhoneInput } from './components/PhoneInput';
import { InsuranceOwnerChip } from './components/InsuranceOwnerChip';
import { InsuranceParticipantChip } from './components/InsuranceParticipantChip';
import { DateFormat } from 'utils/ui/dateFormat';

type Owner = {
  participantId: string | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  email: string | null;
  phone: string | null;
  birthDate: Date | null;
};

type Participant = {
  participantId: string;
  firstName: string | null;
  lastName: string | null;
  travelCountriesIds: number[];
  birthDate: Date;
  riskSet: string;
  citizenship: string;
  lifestyleActivityTypeCode: number | null;
};

type FormProps = {
  owner: Owner;
  participants: Participant[];
  afterSubmit?: string;
};

export default function Covid19PolicyPriceForm() {
  const navigate = useNavigate();
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const translations = useTranslations();
  const calculation = useSelector((s) => s.policy.overprotectionCalculation);
  const policyProcessId = useSelector((s) => s.policy.overprotectionPolicyProcessId);
  const responseParticipants = calculation?.participants || [];
  const [savePolicyData] = useSaveOverprotectionPolicyDataRequest();

  const initialValues: FormProps = {
    owner: {
      participantId: responseParticipants.length > 0 ? responseParticipants[0].participantId : null,
      firstName: null,
      lastName: null,
      middleName: null,
      email: null,
      phone: null,
      birthDate: null,
    },
    participants: responseParticipants.map((p) => ({
      participantId: p.participantId,
      firstName: null,
      lastName: null,
      travelCountriesIds: calculation?.travelCountriesIds || [],
      birthDate: new Date(p.birthDate),
      riskSet: p.riskSet,
      citizenship: p.citizenshipValue,
      lifestyleActivityTypeCode: p.selectedLifestyleActivityTypeCode,
    })),
  };

  const requiredFieldMessage = translations.forms.requiredField();
  const ownerNameRequirementsRegex = /^[\u0401\u0451\u0410-\u044f .'-]+$/i;
  const ownerNameRequirements = translations.forms.russianNameRequirements();
  const foreignPasswordNameRegex = /^[a-z .'-]+$/i;
  const nameRequirements = translations.forms.foreignNamesRequirements();

  const PolicySchema = Yup.object().shape({
    owner: Yup.object().shape({
      firstName: Yup.string()
        .matches(ownerNameRequirementsRegex, ownerNameRequirements)
        .typeError(requiredFieldMessage)
        .required(requiredFieldMessage),
      lastName: Yup.string()
        .matches(ownerNameRequirementsRegex, ownerNameRequirements)
        .typeError(requiredFieldMessage)
        .required(requiredFieldMessage),
      middleName: Yup.string()
        .typeError(requiredFieldMessage)
        .matches(ownerNameRequirementsRegex, ownerNameRequirements)
        .required(requiredFieldMessage),
      email: Yup.string()
        .email(translations.forms.incorrectEmail())
        .typeError(translations.forms.incorrectEmail())
        .required(requiredFieldMessage),
      phone: Yup.string()
        .matches(/^\+79[0-9]{9,9}$/, translations.forms.invalidPhone())
        .typeError(translations.forms.invalidPhone())
        .required(requiredFieldMessage),
    }),
    participants: Yup.array(
      Yup.object().shape({
        firstName: Yup.string()
          .typeError(requiredFieldMessage)
          .matches(foreignPasswordNameRegex, nameRequirements)
          .required(requiredFieldMessage),
        lastName: Yup.string()
          .typeError(requiredFieldMessage)
          .matches(foreignPasswordNameRegex, nameRequirements)
          .required(requiredFieldMessage),
        travelCountriesIds: Yup.array(Yup.number()).min(1, requiredFieldMessage).required(requiredFieldMessage),
        birthDate: Yup.date().typeError(requiredFieldMessage).required(requiredFieldMessage),
        riskSet: Yup.string().required(requiredFieldMessage),
        citizenship: Yup.string().required(requiredFieldMessage),
        lifestyleActivity: Yup.string().nullable(),
      }),
    )
      .min(1, requiredFieldMessage)
      .required(requiredFieldMessage),
  });

  const formik = useFormik<FormProps>({
    enableReinitialize: true,
    initialValues,
    validationSchema: PolicySchema,
    onSubmit: async (values, { setErrors }) => {
      setIsLoaderShown(true);

      try {
        const ownerParticipant = values.participants.find((p) => p.participantId === values.owner.participantId);

        const response = await savePolicyData({
          policyProcessId: policyProcessId || 0,
          owner: {
            firstName: values.owner.firstName!,
            lastName: values.owner.lastName!,
            middleName: values.owner.middleName || '',
            email: values.owner.email!,
            phone: values.owner.phone!,
            gender: 'male',
            birthDate: DateFormat.server(ownerParticipant!.birthDate!),
          },
          insured: values.participants.map((p) => ({
            participantId: p.participantId!,
            firstName: p.firstName!,
            lastName: p.lastName!,
            birthDate: DateFormat.server(p.birthDate),
            travelCountriesIds: p.travelCountriesIds,
          })),
        }).unwrap();
        console.log('SAVE POLICY DATA RESPONSE: ', response);

        navigate(PATH_POLICY.insurance.travelCancellationInsuranceStep3);
      } catch (error: any) {
        console.error(error);
        setErrors({ afterSubmit: getErrorMessage(error) });
      } finally {
        setIsLoaderShown(false);
      }
    },
  });

  const { values, errors, isSubmitting, handleSubmit } = formik;
  const getFormikProps = useFormikProps(formik);
  const formatPrice = useMemo(() => {
    return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format;
  }, []);

  const travelDurationString = useFromDateToDateString(
    calculation && new Date(calculation.effectiveDate),
    calculation && new Date(calculation.terminationDate),
  );

  if (!calculation) {
    return <Fragment />;
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={3}>
              <FormControl fullWidth>
                <Card sx={{ py: 3, px: 3 }}>
                  <Typography variant="h6">{translations.policy.travelCancellation.travelDataTitle()}</Typography>
                  <Divider sx={{ mt: 2 }} />
                  <InfoItem icon={<DateRangeIcon />} title={'Даты'} message={travelDurationString} sx={{ mt: 3 }} />
                  <InfoItem
                    icon={<CreditCardIcon />}
                    title={'Цена'}
                    message={formatPrice(calculation.tourPrice)}
                    sx={{ mt: 3 }}
                  />
                </Card>
              </FormControl>

              <FormControl fullWidth>
                <Card sx={{ py: 3, px: 3 }}>
                  <Typography variant="h6">{translations.policy.travelCancellation.policyDataTitle()}</Typography>
                  <Divider sx={{ mt: 2 }} />
                  <InfoItem
                    icon={<AccountBalanceIcon />}
                    title={'Сумма страхования'}
                    message={formatPrice(calculation.sumInsured)}
                    sx={{ mt: 3 }}
                  />
                  <InfoItem
                    icon={<AccountBalanceWalletIcon />}
                    title={'Цена'}
                    message={formatPrice(calculation.premium)}
                    sx={{ mt: 3 }}
                  />
                </Card>
              </FormControl>
            </Stack>

            <FieldArray
              name="participants"
              render={(arrayHelpers) => (
                <Fragment>
                  {values.participants.map((p, index) => {
                    const isOwner = p.participantId === values.owner.participantId;

                    return (
                      <Card key={p.participantId} sx={{ py: 3, px: 3, pb: 4, mt: 3, mb: 2 }}>
                        <Stack direction="row">
                          <Typography variant="h6">Участник {index + 1}</Typography>
                          <InsuranceParticipantChip />
                          <InsuranceOwnerChip
                            isOwner={isOwner}
                            changeToOwner={() => formik.setFieldValue('owner.participantId', p.participantId)}
                          />
                        </Stack>
                        <Divider sx={{ mt: 2, mb: 1 }} />
                        <Stack direction="row">
                          <FormControl fullWidth>
                            <InfoItem
                              icon={<DateRangeIcon />}
                              title={'Дата рождения'}
                              message={DateFormat.short(p.birthDate)}
                              sx={{ mt: 3 }}
                            />
                            <InfoItem
                              icon={<RowingIcon />}
                              title={'Вид активности'}
                              message={getLifestyleActivityLabel(p.lifestyleActivityTypeCode) || 'Обычный'}
                              sx={{ mt: 3 }}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <InfoItem
                              icon={<AddModeratorIcon />}
                              title={'Набор рисков'}
                              message={p.riskSet}
                              sx={{ mt: 3 }}
                            />
                            <InfoItem
                              icon={<PublicIcon />}
                              title={'Гражданство'}
                              message={p.citizenship}
                              sx={{ mt: 3 }}
                            />
                          </FormControl>
                        </Stack>

                        <Divider sx={{ mt: 3, mb: 2 }} />

                        {isOwner && (
                          <Fragment>
                            <Typography variant="subtitle2" sx={{ ml: 1, mt: 3 }}>
                              Паспорт:
                            </Typography>

                            <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
                              <TextInput label="Фамилия" formikProps={getFormikProps('owner.lastName')} />
                              <TextInput label="Имя" formikProps={getFormikProps('owner.firstName')} />
                              <TextInput label="Отчество" formikProps={getFormikProps('owner.middleName')} />
                            </Stack>
                          </Fragment>
                        )}

                        <Typography variant="subtitle2" sx={{ mt: 3, ml: 1 }}>
                          Загранпаспорт:
                        </Typography>

                        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
                          <TextInput label="Фамилия" formikProps={getFormikProps(`participants.${index}.lastName`)} />
                          <TextInput label="Имя" formikProps={getFormikProps(`participants.${index}.firstName`)} />
                          <FormControl fullWidth />
                        </Stack>

                        {isOwner && (
                          <Fragment>
                            <Typography variant="subtitle2" sx={{ mt: 3, ml: 1 }}>
                              Контакты:
                            </Typography>

                            <Stack direction="row" spacing={3} sx={{ mt: 1 }}>
                              <PhoneInput formikProps={getFormikProps(`owner.phone`)} />
                              <TextInput label="Email" formikProps={getFormikProps(`owner.email`)} />
                              <FormControl fullWidth />
                            </Stack>
                          </Fragment>
                        )}

                        <CountriesSelect
                          sx={{ mt: 3 }}
                          multiple
                          disabled
                          label={translations.policy.travelCancellation.visitingCountries()}
                          placeholder={translations.policy.travelCancellation.visitingCountries()}
                          formikProps={getFormikProps(`participants.${index}.travelCountriesIds`)}
                          initialCountriesIds={calculation.travelCountriesIds}
                        />
                      </Card>
                    );
                  })}
                </Fragment>
              )}
            />

            {typeof errors.owner === 'string' ? (
              <Alert severity="error">{errors.participants || errors.afterSubmit}</Alert>
            ) : null}

            {typeof errors.participants === 'string' ? (
              <Alert severity="error">{errors.participants || errors.afterSubmit}</Alert>
            ) : null}

            {typeof errors.afterSubmit === 'string' ? <Alert severity="error">{errors.afterSubmit}</Alert> : null}

            <Grid sx={{ mt: 4, mx: 4 }}>
              <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                sx={{ ml: 0 }}
                type="submit"
                disabled={isSubmitting}
                style={{ textTransform: 'none' }}
              >
                <SaveIcon sx={{ mr: 1 }} />
                {translations.common.saveAndContinue()}
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoaderShown}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </FormikProvider>
  );
}
