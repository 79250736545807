import { Icon } from '@iconify/react';
import MIconButton from 'components/@material-extend/MIconButton';
import { useTranslations } from 'hooks/useTranslations';
import { useSnackbar } from 'notistack';

export const useErrorSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const translations = useTranslations();

  return (message?: string) => {
    const messageText = message || translations.common.operationError();
    return enqueueSnackbar(messageText, {
      variant: 'error',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon="ci:error-outline" />
        </MIconButton>
      ),
    });
  };
};
