import { Container, Grid } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import TotalBalanceCounter from 'components/_dashboard/tour-agent/balance/TotalBalanceCounter';
import AvailableBalanceCounter from 'components/_dashboard/tour-agent/balance/AvailableBalanceCounter';
import InsuranceOrderList from 'components/_dashboard/tour-agent/overprotection-policy/InsuranceOrderList';
import NotCompletedInsuranceOrderList from 'components/_dashboard/tour-agent/overprotection-policy/NotCompletedInsuranceOrderList';
import SravniPolicyOrderList from 'components/_dashboard/sravni-vzr-policy/SravniPolicyOrderList';
import { usePageTitle } from 'hooks/ui/usePageTitle';
import { useTranslations } from 'hooks/useTranslations';

export default function DashboardPage() {
  const { themeStretch } = useSettings();
  const translations = useTranslations();
  const pageTitle = usePageTitle(translations.common.overview());

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TotalBalanceCounter />
          </Grid>

          <Grid item xs={12} md={6}>
            <AvailableBalanceCounter />
          </Grid>

          <Grid item xs={12} md={12}>
            <NotCompletedInsuranceOrderList />
          </Grid>

          <Grid item xs={12} md={12} sx={{ mt: 1 }}>
            <SravniPolicyOrderList mode={'tour-agent'} />
          </Grid>

          <Grid item xs={12} md={12}>
            <InsuranceOrderList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
