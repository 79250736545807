import useAuth from '../hooks/useAuth';
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { profile, displayName } = useAuth();

  return (
    <MAvatar
      src={profile?.photoURL}
      alt={displayName}
      color={profile?.photoURL ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </MAvatar>
  );
}
