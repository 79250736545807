import { useMemo } from 'react';
import { Box, Step, StepButton, Stepper, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';

type Props = {
  heading: string;
  currentStep: number;
};

export const TravelCancellationPolicyStepper = (props: Props) => {
  const { heading, currentStep } = props;
  const translations = useTranslations();

  const steps = useMemo(
    () => [
      {
        name: translations.policy.travelCancellation.calculatePolicy(),
      },
      {
        name: translations.policy.travelCancellation.policyData(),
      },
      {
        name: translations.policy.travelCancellation.payment(),
      },
      {
        name: translations.policy.travelCancellation.policyGiving(),
      },
    ],
    [translations],
  );

  return (
    <Box sx={{ mb: 6 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h4" gutterBottom sx={{ ml: 1 }}>
          {heading}
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Stepper nonLinear activeStep={currentStep}>
          {steps.map((step, index) => (
            <Step key={step.name} sx={{}}>
              <StepButton color="inherit" disableRipple>
                {(index === currentStep && <strong>{step.name}</strong>) || step.name}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};
