import { Card, Table, TableRow, TableBody, TableCell, TableHead, CardHeader, TableContainer } from '@mui/material';
import Scrollbar from '../../../Scrollbar';
import { useOverprotectionPoliciesHistoryQuery } from 'redux/slices/baseApi';
import { useTranslations } from 'hooks/useTranslations';
import { useCurrentRoleId } from 'hooks/user/useCurrentRoleId';
import { InsuranceOrderItem } from './InsuranceOrderItem';
import NoTransferIcon from '@mui/icons-material/NoTransfer';

export default function InsuranceOrderList() {
  const roleId = useCurrentRoleId();
  const { data = [] } = useOverprotectionPoliciesHistoryQuery(roleId);
  const translations = useTranslations();

  return (
    <Card sx={{ pb: 3 }}>
      <CardHeader
        avatar={<NoTransferIcon />}
        title={translations.dashboard.overprotectionPoliciesHistory()}
        sx={{ mb: 3 }}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.common.client()}</TableCell>
                <TableCell>{translations.common.price()}</TableCell>
                <TableCell>{translations.common.status()}</TableCell>
                <TableCell>{translations.common.premium()}</TableCell>
                <TableCell>{translations.common.bonusActivationDate()}</TableCell>
                <TableCell>{translations.common.issuedDate()}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((policyData) => (
                <InsuranceOrderItem key={policyData.id} item={policyData} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
