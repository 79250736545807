import { useCallback, useState } from 'react';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useTranslations } from 'hooks/useTranslations';
import { useCashbackDeeplinkRequest } from 'redux/slices/baseApi';
import { useSuccessSnackbar } from 'hooks/ui/useSuccessSnackbar';
import { useErrorSnackbar } from 'hooks/ui/useErrorSnackbar';

export const useCashbackLink = (offerId: number) => {
  const translations = useTranslations();
  const role = useCurrentRole();
  const [createDeeplink] = useCashbackDeeplinkRequest();
  const [link, setLink] = useState('');
  const [isCopingDeeplink, setIsCopingDeeplink] = useState(false);
  const [isOpeningDeeplink, setOpeningDeeplink] = useState(false);

  const showSuccessSnackbar = useSuccessSnackbar();
  const showErrorSnackbar = useErrorSnackbar();

  const getDeeplink = useCallback(async () => {
    let deeplink = link;

    if (!link) {
      const response = await createDeeplink({
        tourAgentRoleId: role.id,
        tourAgencyId: role.tourAgency?.id,
        offerId: offerId,
      }).unwrap();

      deeplink = response.deeplink;
      setLink(deeplink);
    }

    return deeplink;
  }, [link, role, offerId, createDeeplink]);

  const copyToClipboard = useCallback(async () => {
    try {
      setIsCopingDeeplink(true);
      const deeplink = await getDeeplink();
      await navigator.clipboard.writeText(deeplink);

      showSuccessSnackbar(translations.cashback.linkCopied());
      setIsCopingDeeplink(false);
    } catch (err) {
      showErrorSnackbar(translations.cashback.deeplinkLinkError());
      setIsCopingDeeplink(false);
    }
  }, [getDeeplink, translations, showSuccessSnackbar, showErrorSnackbar]);

  const openDeeplink = useCallback(async () => {
    try {
      setOpeningDeeplink(true);
      const deeplink = await getDeeplink();
      window.open(deeplink, '_blank')?.focus();
      setOpeningDeeplink(false);
    } catch (err) {
      showErrorSnackbar(translations.cashback.deeplinkLinkError());
      setOpeningDeeplink(false);
    }
  }, [getDeeplink, translations, showErrorSnackbar]);

  return { link, isCopingDeeplink, isOpeningDeeplink, copyToClipboard, openDeeplink };
};
