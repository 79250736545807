import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { INITIAL_ROUTE, PATH_AUTH } from '../../../routes/paths';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { useSignInRequest } from 'redux/slices/baseApi';
import { useTranslations } from 'hooks/useTranslations';
import { getErrorMessage } from 'utils/errors';
import { useSuccessSnackbar } from 'hooks/ui/useSuccessSnackbar';
import { useTourAgencyInviteToken } from 'hooks/user/useTourAgencyInviteToken';
import DemoAccountBanner from './components/DemoAccountBanner';
import ErrorAlert from 'components/alerts/ErrorAlert';
import { EmailInput } from 'components/inputs/EmailInput';
import { PasswordInput } from 'components/inputs/PasswordInput';
import { useValidationRules } from 'hooks/ui/useValidationRules';
import { useEmailQueryParam } from 'hooks/utils/useEmailQueryParam';

type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const isMountedRef = useIsMountedRef();
  const [signIn, { isLoading }] = useSignInRequest();
  const translations = useTranslations();
  const navigate = useNavigate();
  const showSuccessSnackbar = useSuccessSnackbar();
  const validationRules = useValidationRules();

  const tourAgencyInviteToken = useTourAgencyInviteToken();
  const emailQueryParam = useEmailQueryParam();

  const LoginSchema = Yup.object().shape({
    email: validationRules.email(),
    password: validationRules.password(),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: emailQueryParam || '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const { email, password } = values;
        await signIn({ email, password, tourAgencyInviteToken }).unwrap();

        navigate(INITIAL_ROUTE);
        showSuccessSnackbar(translations.signIn.successMessage());
      } catch (error: any) {
        if (!isMountedRef.current) return;

        console.error(error);
        setErrors({ afterSubmit: getErrorMessage(error) });
      }
    },
  });

  const { errors, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <DemoAccountBanner />

          {errors.afterSubmit && <ErrorAlert error={errors.afterSubmit} />}

          <EmailInput disabled={!!emailQueryParam} />
          <PasswordInput />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label={translations.signIn.rememberMe()}
          /> */}
          <div></div>

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            {translations.signIn.forgetPassword()}
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
          {translations.common.signIn()}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
