type Environment = {
  name: string;
  baseUrl: string;
};

const developmentEnvironment: Environment = {
  name: 'development',
  baseUrl: 'http://localhost:3000',
};

const stagingEnvironment: Environment = {
  name: 'staging',
  baseUrl: 'https://touragent-club-staging.herokuapp.com',
};

const productionEnvironment: Environment = {
  name: 'production',
  baseUrl: 'https://touragent-club-production.herokuapp.com',
};

export const CURRENT_ENVIRONMENT = (() => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  if (!environment) throw new Error('No value for REACT_APP_ENVIRONMENT env var');

  console.log('ENVIRONMENT: ' + environment);

  if (environment === 'staging') {
    return stagingEnvironment;
  }

  if (environment === 'production') {
    return productionEnvironment;
  }

  return developmentEnvironment;
})();
