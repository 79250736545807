import { useTourAgencyInviteToken } from 'hooks/user/useTourAgencyInviteToken';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD } from '../routes/paths';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();

  const tourAgencyInviteToken = useTourAgencyInviteToken();
  const shouldActivateAccount = !!tourAgencyInviteToken;

  if (isAuthenticated && !shouldActivateAccount) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
