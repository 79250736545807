import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextField, Alert, Stack, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { useTranslations } from 'hooks/useTranslations';
import { useChangePasswordRequest } from 'redux/slices/baseApi';
import { getErrorMessage } from 'utils/errors';
import { useNavigate } from 'react-router';
import { PATH_AUTH } from 'routes/paths';
import { useSuccessSnackbar } from 'hooks/ui/useSuccessSnackbar';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useState } from 'react';

type InitialValues = {
  newPassword: string;
  repeatNewPassword: string;
  afterSubmit?: string;
};

type ChangePasswordFormProps = {
  token: string;
};

export default function ChangePasswordForm({ token }: ChangePasswordFormProps) {
  const isMountedRef = useIsMountedRef();
  const translations = useTranslations();
  const [changePassword] = useChangePasswordRequest();
  const navigate = useNavigate();
  const showSuccessSnackbar = useSuccessSnackbar();

  const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, translations.forms.minLength({ length: 8 }))
      .required(translations.forms.requiredField()),
    repeatNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], translations.errorCodes.RESET_PASSWORDS_SHOULD_MATCH())
      .required(translations.forms.requiredField()),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      newPassword: '',
      repeatNewPassword: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const { newPassword, repeatNewPassword } = values;
        await changePassword({ token, newPassword, repeatNewPassword }).unwrap();

        if (isMountedRef.current) {
          setSubmitting(false);
        }

        navigate(PATH_AUTH.login);
        showSuccessSnackbar(translations.changePassword.passwordChanged());
      } catch (error: any) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: getErrorMessage(error) });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const showPasswordProps = {
    type: showPassword ? 'text' : 'password',
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={handleShowPassword} edge="end">
            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
          </IconButton>
        </InputAdornment>
      ),
    },
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            {...getFieldProps('newPassword')}
            label={translations.common.password()}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
            {...showPasswordProps}
          />

          <TextField
            fullWidth
            {...getFieldProps('repeatNewPassword')}
            label={translations.common.repeatPassword()}
            error={Boolean(touched.repeatNewPassword && errors.repeatNewPassword)}
            helperText={touched.repeatNewPassword && errors.repeatNewPassword}
            {...showPasswordProps}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {translations.changePassword.change()}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
