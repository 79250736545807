import { useMemo } from 'react';
import { useYandexMetrics } from './useYandexMetrics';

export const useAnalytics = () => {
  const yandexMetrics = useYandexMetrics();

  const analytics = useMemo(() => {
    return {
      routing: {
        pathChanged: () => yandexMetrics('hit', window.location.href),
      },
      signUp: {
        tourAgent: () => {
          yandexMetrics('reachGoal', 'sign-up');
          yandexMetrics('reachGoal', 'sign-up-fiz');
        },
        tourAgency: () => {
          yandexMetrics('reachGoal', 'sign-up');
          yandexMetrics('reachGoal', 'sign-up-ur');
        },
      },
    };
  }, [yandexMetrics]);

  return analytics;
};
