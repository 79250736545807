import { useCallback, useMemo, useState } from 'react';
import { Alert, Stack } from '@mui/material';
import { useProfile } from 'hooks/user/useProfile';
import { useContractorStatusQuery, useWithdrawTourAgentRewardsRequest } from 'redux/slices/baseApi';
import { useTourAgentBalance } from 'hooks/tour-agency/useTourAgentBalance';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import FillTourAgentProfileView from './FillTourAgentProfileView';
import CheckingStatusCard from '../CheckingContractorStatusCard';
import ContractorCreationErrorCard from '../ContractorCreationErrorCard';
import ProfileVerificationView from '../ProfileVerificationView';
import PayoutRequisitesView from '../PayoutRequisitesView';
import WithdrawAmountAlert from '../common/WithdrawAmountAlert';
import WithdrawalButton from '../common/WithdrawalButton';
import { PaymentMethod } from '../common/payment-method';
import { useWithdrawalResultHandler } from 'hooks/ui/useWithdrawalRequestHandler';

type Props = {
  goToProfileEditing: () => void;
};

export default function TourAgentWithdrawalView(props: Props) {
  const { goToProfileEditing } = props;
  const profile = useProfile();
  const role = useCurrentRole();
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const { data: contractorStatus, isLoading: isLoadingContractorStatus } = useContractorStatusQuery(role.id);
  const [withdraw, { isLoading }] = useWithdrawTourAgentRewardsRequest();
  const { isBalanceLoading, canPayout } = useTourAgentBalance();

  const handleWithdrawalResult = useWithdrawalResultHandler();
  const withdrawReward = useCallback(async () => {
    if (!selectedCardId) return;

    await handleWithdrawalResult(
      withdraw({ requisitesId: selectedCardId, userId: profile.id, tourAgentRoleId: role.id }),
    );
  }, [selectedCardId, handleWithdrawalResult, withdraw, profile.id, role.id]);

  const isProfileDataMissing = useMemo(() => {
    const { firstName, lastName, phone, inn } = profile;
    return [firstName, lastName, phone, inn].some((i) => !i);
  }, [profile]);

  if (isProfileDataMissing) {
    return <FillTourAgentProfileView goToProfileEditing={goToProfileEditing} />;
  }

  if (isLoadingContractorStatus) {
    return <CheckingStatusCard type={'self-employed-or-individual'} />;
  }

  if (contractorStatus === 'creation-failed') {
    return <ContractorCreationErrorCard />;
  }

  const paymentMethod: PaymentMethod = 'jump-finance';

  return (
    <Stack spacing={3}>
      <Alert color="info">
        <b>Для вывода вознаграждения необходимо:</b> <br />
        1. Зарегистрироваться в приложении "Мой налог" (доступно в App Store / Google Play) <br />
        2. Зарегистрироваться в приложении "Jump.Работа" (доступно в App Store / Google Play) <br />
        3. Принять условия договора в приложении "Jump.Работа"
      </Alert>

      <ProfileVerificationView />

      <PayoutRequisitesView selectedCardId={selectedCardId} selectCard={(id: number) => setSelectedCardId(id)} />

      <WithdrawAmountAlert paymentMethod={paymentMethod} />

      <WithdrawalButton
        canWithdraw={!!selectedCardId && canPayout}
        isLoading={isLoading || isBalanceLoading}
        withdraw={withdrawReward}
        paymentMethod={paymentMethod}
      />
    </Stack>
  );
}
