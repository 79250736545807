import { TextField } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useState } from 'react';
import { useFormikInputProps } from 'utils/ui/formik';

type Props = {
  sx?: SxProps<Theme>;
  disabled?: boolean;
  size?: 'small' | 'medium';
};

export const InnInput = (props: Props) => {
  const { sx, size, disabled } = props;
  const translations = useTranslations();
  const { fieldProps, setFieldValue, hasError, helperText } = useFormikInputProps('inn');

  const [inn, setInn] = useState(fieldProps.value);

  const handleChange = useCallback(
    (e: any) => {
      const inputValue: string = e.target.value;
      const onlyNumbers = inputValue.replace(/[^0-9]/g, '').slice(0, 12);
      setInn(onlyNumbers);
      setFieldValue(onlyNumbers);
    },
    [setInn, setFieldValue],
  );

  return (
    <TextField
      {...fieldProps}
      sx={sx}
      size={size || 'small'}
      disabled={disabled}
      label={translations.common.inn()}
      variant="outlined"
      fullWidth
      value={inn}
      onChange={handleChange}
      error={hasError}
      helperText={helperText}
    />
  );
};
