import { TextField } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { useFormikInputProps } from 'utils/ui/formik';

type Props = {
  sx?: SxProps<Theme>;
  disabled?: boolean;
  size?: 'small' | 'medium';
  placeholder: string;
  InputProps: InputProps;
};

type InputProps = {
  error: boolean;
  disabled: boolean;
  startAdornment: React.ReactNode;
};

export const WebsiteInput = (props: Props) => {
  const { sx, size, disabled, placeholder, InputProps } = props;
  const { fieldProps, hasError, helperText } = useFormikInputProps('website');

  return (
    <TextField
      {...fieldProps}
      fullWidth
      size={size || 'small'}
      error={hasError}
      placeholder={placeholder}
      helperText={helperText}
      disabled={disabled}
      sx={sx}
      InputProps={InputProps}
    />
  );
};
