import { Alert, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import PaymentStepTitle from './PaymentStepTitle';
import { useSelector } from 'redux/store';
import DescriptionIcon from '@mui/icons-material/Description';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PrintIcon from '@mui/icons-material/Print';

type Props = {
  currentStep: number;
};

export default function GivePolicyToClientCard(props: Props) {
  const stepIndex = 3;
  const translations = useTranslations();
  const { currentStep } = props;
  const policy = useSelector((s) => s.policy.overprotectionPolicy);

  return (
    <Card sx={{ py: 3, px: 3, mt: 3 }}>
      <PaymentStepTitle
        index={stepIndex}
        title={translations.policy.travelCancellation.givePolicyToClient()}
        isActive={currentStep >= stepIndex}
        isStepCompleted={currentStep >= stepIndex}
      />
      {currentStep >= stepIndex && (
        <>
          <Divider sx={{ mt: 2, mb: 2 }} />
          {currentStep >= stepIndex && (
            <Alert color="info" icon={false}>
              <Stack direction="row" sx={{ flex: 1 }}>
                <MarkEmailReadIcon sx={{ mr: 1 }} />
                <Typography>
                  {translations.policy.travelCancellation.givePolicyToClientDescription1()}
                  <strong>{policy?.policyOwner?.email || ''}</strong>
                </Typography>
              </Stack>

              <Divider sx={{ mt: 2, flexGrow: 1 }} />

              <Stack direction="row" sx={{ mt: 2 }}>
                <PrintIcon sx={{ mr: 1 }} />
                <Typography>{translations.policy.travelCancellation.givePolicyToClientDescription2()}</Typography>
              </Stack>
            </Alert>
          )}

          <Stack direction="row" sx={{ mt: 3 }}>
            <a href={policy!.policyPdfUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
              <Button variant="contained" style={{ textTransform: 'none' }} size={'large'}>
                <DescriptionIcon sx={{ mr: 1 }} />
                {translations.policy.travelCancellation.openPolicy()}
              </Button>
            </a>
          </Stack>
        </>
      )}
    </Card>
  );
}
