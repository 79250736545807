import { useMemo } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { PolicyProcessStep } from 'types/models/not-completed-overprotection-policy-process';

export const useOverprotectionPolicyStepTitles = () => {
  const translations = useTranslations();

  const stepTranslations = useMemo(() => {
    const steps = translations.dashboard.overprotectionPolicySteps;

    return {
      price_calculation_started: steps.priceCalculationStarted(),
      price_calculated: steps.priceCalculated(),

      participants_data_saved: steps.participantsDataSaved(),

      payment_started: steps.paymentStarted(),
      payment_transaction_created: steps.paymentTransactionCreated(),
      payment_completed: steps.paymentCompleted(),
      payment_confirmed: steps.paymentConfirmed(),

      policy_generated: steps.policyGenerated(),
      policy_submitted: steps.policySubmitted(),
      policy_sent_to_stackholders: steps.policySubmitted(),

      reward_applyed: steps.rewardApplyed(),
    } as Record<PolicyProcessStep, string>;
  }, [translations]);

  return stepTranslations;
};
