import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, Alert, Button } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { useInviteTourAgencyEmployeeRequest } from 'redux/slices/baseApi';
import { getErrorMessage } from 'utils/errors';
import { useSuccessSnackbar } from 'hooks/ui/useSuccessSnackbar';
import { useValidationRules } from 'hooks/ui/useValidationRules';
import { RoleType } from 'types/account';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { EmailInput } from 'components/inputs/EmailInput';
import RoleTypeInput from 'components/inputs/RoleTypeInput';
import { LoadingButton } from '@mui/lab';

type InitialValues = {
  email: string;
  roleType: RoleType;
  afterSubmit?: string;
};

type Props = {
  onCancel: () => void;
  onSubmitSuccess: () => void;
};

export default function AddEmployeeForm(props: Props) {
  const { onCancel, onSubmitSuccess } = props;
  const [inviteEmployee] = useInviteTourAgencyEmployeeRequest();
  const showSuccessSnackbar = useSuccessSnackbar();
  const currentRole = useCurrentRole();
  const validationRules = useValidationRules();
  const translations = useTranslations();

  const AddEmployeeSchema = Yup.object().shape({
    email: validationRules.email(),
    roleType: validationRules.roleType(),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      roleType: 'tour-agency-manager',
    },
    validationSchema: AddEmployeeSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        const { email, roleType } = values;
        await inviteEmployee({
          email,
          roleType,
          roleId: currentRole.id,
          tourAgencyId: currentRole.tourAgency!.id,
        }).unwrap();

        showSuccessSnackbar(translations.userManagment.inviteSent());
        onSubmitSuccess();
      } catch (error: any) {
        console.error(error);
        setErrors({ afterSubmit: getErrorMessage(error) });
      }
    },
  });

  const { errors, isSubmitting, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={1}>
          {errors.afterSubmit && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {errors.afterSubmit}
            </Alert>
          )}
          <EmailInput size="medium" />
          <RoleTypeInput />
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={onCancel} disabled={isSubmitting}>
            {translations.common.cancel()}
          </Button>
          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
            {translations.common.invite()}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
