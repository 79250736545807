import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  white?: boolean;
}

export default function Logo({ sx, white }: Props) {
  return (
    <Box sx={{ width: 100, height: 100, ...sx }}>
      <img
        src={white ? '/static/brand/logo_white.svg' : '/static/brand/tour_agent_club_logo.svg'}
        alt="TourAgent Club Logo"
      />
    </Box>
  );
}
