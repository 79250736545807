import { useTranslations } from 'hooks/useTranslations';
import { usePageTitle } from 'hooks/ui/usePageTitle';
import Page from 'components/Page';
import ContactForm from 'components/support/forms/ContactForm';
import { Grid, Typography } from '@mui/material';

export default function SupportPage() {
  const translations = useTranslations();
  const pageTitle = usePageTitle(translations.support.title());

  return (
    <Page title={pageTitle}>
      <Grid container>
        <Grid item md={6} xl={5} xs={12}>
          <ContactForm />
        </Grid>

        <Grid item md={6} xl={3} display={{ xs: 'none', md: 'block' }} sx={{ pr: 5 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            {translations.support.description1()}
          </Typography>
          <Typography variant="body1">{translations.support.description2()}</Typography>
        </Grid>
      </Grid>
    </Page>
  );
}
