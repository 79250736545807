import { Card, Chip, Divider, Stack, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import Subtitle from 'components/text/Subtitle';
import { useTranslations } from 'hooks/useTranslations';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ReactNode } from 'react';
import './SelectTourAgentPaymentMethod.css';
import { PaymentMethod } from '../common/payment-method';
import { useCurrentRolePermissions } from 'hooks/user/useCurrentRolePermissions';
import { conditionalItem } from 'utils/conditionalItem';

type Props = {
  selectPaymentMethod(paymentMethod: PaymentMethod): void;
  sx?: SxProps<Theme>;
};

export default function SelectTourAgentPaymentMethod(props: Props) {
  const { sx, selectPaymentMethod } = props;
  const translations = useTranslations();
  const permissions = useCurrentRolePermissions();

  return (
    <Card sx={{ p: 3, ...sx }}>
      <Subtitle>{translations.account.selectPaymentMethod()}</Subtitle>

      <Stack spacing={2} sx={{ mb: 2 }}>
        <PaymentMethodCard
          title={translations.account.withdrawToCard()}
          icon={<CreditCardIcon />}
          type={permissions.isIndividual ? translations.common.ip().toLowerCase() : translations.common.selfEmployed()}
          typeColor={'primary'}
          time={<Time icon={<AccessTimeIcon sx={{ width: 17 }} />} title="до 2 дней" />}
          infoItems={[
            ...conditionalItem(!permissions.isIndividual, {
              title: 'Налог',
              value: '6%',
              description: '(уплачивается автоматически)',
            }),
            { title: translations.common.partnerCommission(), value: '3%, минимум 50 ₽' },
          ]}
          onSelect={() => selectPaymentMethod('jump-finance')}
        />

        <PaymentMethodCard
          title={translations.account.withdrawToMobilePhone()}
          icon={<SendToMobileIcon sx={{ width: 20 }} />}
          type={translations.common.physical()}
          typeColor={'info'}
          time={<Time icon={<AccessTimeIcon sx={{ width: 17 }} />} title="до 5 дней" />}
          infoItems={[
            {
              title: translations.common.partnerCommission(),
              value: '15%',
              description: '(налог + стоимость услуг партнера)',
            },
          ]}
          onSelect={() => selectPaymentMethod('mobile-phone')}
        />
      </Stack>
    </Card>
  );
}

type PaymentMethodCardProps = {
  title: string;
  type: string;
  typeColor: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  icon: ReactNode;
  time: ReactNode;
  infoItems: {
    title: string;
    value: string;
    description?: string;
  }[];
  onSelect?: () => void;
};

function PaymentMethodCard(props: PaymentMethodCardProps) {
  const { title, type, typeColor, icon, time, infoItems, onSelect } = props;

  return (
    <Stack sx={{ p: 2 }} className="payment-method-card" onClick={onSelect}>
      <Stack direction="row">
        {icon}
        <Typography variant="subtitle1" sx={{ ml: 1, fontSize: 17, flexGrow: 1, letterSpacing: 0.3 }}>
          {title}
        </Typography>
        <ArrowForwardIosIcon color="disabled" />
      </Stack>
      <Divider sx={{ my: 1.5 }} />

      <Stack>
        <Stack direction="row" sx={{ mt: 0.5, mb: 1.5, letterSpacing: 0.5, fontWeight: 'bold' }}>
          <Chip label={type} variant="outlined" color={typeColor} sx={{ mr: 1.5, height: 22 }} />
          {time}
        </Stack>
        {infoItems.map(({ title, value, description }) => (
          <Stack direction="row" key={title} sx={{ alignItems: 'center' }}>
            <Typography>
              <b>{title}:</b> {value}
            </Typography>
            {description && (
              <Typography color="text.secondary" sx={{ ml: 0.75, fontSize: 14 }}>
                {description}
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

function Time(props: { icon: ReactNode; title: string }) {
  const { icon, title } = props;

  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        py: 0.25,
        pl: 1,
        pr: 1.5,
        backgroundColor: 'rgba(66, 200, 245, 0.5)',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        height: 22,
      }}
    >
      {icon}
      <Typography variant="subtitle1" sx={{ color: '#333', fontSize: 12 }}>
        {title}
      </Typography>
    </Stack>
  );
}
