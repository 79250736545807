import { PATH_CASHBACK, PATH_DASHBOARD, PATH_POLICY, PATH_SUPPORT } from '../routes/paths';
// import SvgIconStyle from '../components/SvgIconStyle';
// import StarsIcon from '@mui/icons-material/Stars';
import NoTransferIcon from '@mui/icons-material/NoTransfer';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import PeopleIcon from '@mui/icons-material/People';
import { useCallback, useMemo } from 'react';
import { useCurrentRolePermissions } from './user/useCurrentRolePermissions';
import FlightIcon from '@mui/icons-material/Flight';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import { CURRENT_ENVIRONMENT } from 'environment';

// TODO(Maxim): Uncomment when services will be ready for launch

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
// );

// const ICONS = {
//   blog: getIcon('ic_blog'),
//   user: getIcon('ic_user'),
//   kanban: getIcon('ic_kanban'),
//   banking: getIcon('ic_banking'),
//   calendar: getIcon('ic_calendar'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   booking: getIcon('ic_booking'),
// };

type SidebarItem = {
  title: string;
  path: string;
  icon: JSX.Element;
};

type SidebarSection = {
  subheader: string;
  items: SidebarItem[];
};

type SidebarConfig = SidebarSection[];

export const useSidebarConfig = () => {
  const isProduction = CURRENT_ENVIRONMENT.name === 'production';
  const permissions = useCurrentRolePermissions();

  const conditionalItem = useCallback((canAccess: boolean, item: SidebarItem) => {
    return canAccess ? [item] : [];
  }, []);

  const conditionalSection = useCallback((canAccess: boolean, section: SidebarSection) => {
    return canAccess ? [section] : [];
  }, []);

  const config: SidebarConfig = useMemo(() => {
    return [
      {
        subheader: 'Обзор',
        items: [
          {
            title: permissions.isOwner ? 'Мои продажи' : 'Продажи',
            path: PATH_DASHBOARD.root,
            icon: <AssessmentIcon />,
          },
          ...conditionalItem(permissions.canManageTourAgencyEmployees, {
            title: 'Продажи турагентства',
            path: PATH_DASHBOARD.tourAgencySales,
            icon: <StackedLineChartIcon />,
          }),
          ...conditionalItem(permissions.canManageTourAgencyEmployees, {
            title: 'Сотрудники',
            path: PATH_DASHBOARD.employees,
            icon: <PeopleIcon />,
          }),
        ],
      },

      {
        subheader: 'Страхование',
        items: [
          {
            title: 'Тур. страховка',
            path: PATH_POLICY.insurance.sravniVzr,
            icon: <FlightIcon />,
          },
          {
            title: 'От невыезда',
            path: PATH_POLICY.insurance.travelCancellationInsurance,
            icon: <NoTransferIcon />,
          },
        ],
      },

      // {
      //   subheader: 'Отели',
      //   items: [
      //     { title: 'новости', path: PATH_HOTELS.hotels.news, icon: ICONS.blog },
      //     { title: 'акции', path: PATH_HOTELS.hotels.actions, icon: ICONS.calendar },
      //     { title: 'рейтинг', path: PATH_HOTELS.hotels.rating, icon: <StarsIcon /> },
      //   ],
      // },

      ...conditionalSection(!isProduction, {
        subheader: 'Сервисы',
        items: [{ title: 'Cashback', path: PATH_CASHBACK.root, icon: <LoyaltyOutlinedIcon /> }],
      }),

      {
        subheader: 'Поддержка',
        items: [
          { title: 'FAQ', path: PATH_SUPPORT.support.faq, icon: <ContactSupportIcon /> },
          { title: 'Связаться', path: PATH_SUPPORT.support.askQuestion, icon: <SupportAgentIcon /> },
        ],
      },
    ];
  }, [permissions, isProduction, conditionalSection, conditionalItem]);

  return config;
};
