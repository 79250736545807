import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslations } from 'hooks/useTranslations';
import { Typography } from '@mui/material';
import { FaqSection } from '../FaqSection';

type Props = {
  selectedSection: FaqSection;
  setSelectedSection: (section: FaqSection) => void;
};

export default function FaqSidebar(props: Props) {
  const { selectedSection, setSelectedSection } = props;
  const translations = useTranslations();

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        backgroundColor: 'rgba(145, 158, 171, 0.04)',
        padding: 1,
        mx: 1,
        border: '1px solid rgba(145, 158, 171, 0.12)',
        borderRadius: '4px',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <Group
        title={translations.docs.sections.tourAgentClub()}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        items={[
          { id: 'about-us', title: translations.docs.sections.aboutService() },
          { id: 'getting-started', title: translations.docs.sections.gettingStarted() },
        ]}
      />
      <Group
        title={translations.docs.sections.services()}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        items={[{ id: 'sravni-service', title: translations.docs.sections.sravni() }]}
      />
    </List>
  );
}

type GroupProps = {
  title: string;
  selectedSection: FaqSection;
  setSelectedSection: (section: FaqSection) => void;
  items: {
    id: FaqSection;
    title: string;
  }[];
};

function Group(props: GroupProps) {
  const { title, selectedSection, setSelectedSection, items } = props;
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItemButton onClick={handleClick} sx={{ borderRadius: '4px' }}>
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item) => (
            <ListItemButton
              sx={{ pl: 4, borderRadius: '4px' }}
              key={item.id}
              selected={item.id === selectedSection}
              onClick={() => setSelectedSection(item.id)}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </div>
  );
}
