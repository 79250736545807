import { Card, Divider } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useEffect } from 'react';
import { useGenerateOverprotectionPolicyPdfRequest } from 'redux/slices/baseApi';
import { updateOverprotectionPolicy } from 'redux/slices/policy';
import { useDispatch, useSelector } from 'redux/store';
import PaymentStepTitle from './PaymentStepTitle';
import ErrorAlert from 'components/alerts/ErrorAlert';

type Props = {
  currentStep: number;
  changeStep(step: number): void;
};

export default function CreatePolicyPdfCard(props: Props) {
  const translations = useTranslations();
  const { currentStep, changeStep } = props;
  const [generatePolicyPdf, { error }] = useGenerateOverprotectionPolicyPdfRequest();
  const policy = useSelector((s) => s.policy.overprotectionPolicy);
  const policyProcessId = useSelector((s) => s.policy.overprotectionPolicyProcessId);
  const dispatch = useDispatch();
  const stepIndex = 1;

  const generatePdf = useCallback(async () => {
    if (policy?.policyPdfUrl || !policyProcessId) return;

    const response = await generatePolicyPdf({ policyProcessId }).unwrap();
    dispatch(updateOverprotectionPolicy(response.policy));

    changeStep(stepIndex + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy, policyProcessId]);

  useEffect(() => {
    generatePdf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatePdf]);

  return (
    <Card sx={{ py: 3, px: 3 }}>
      <PaymentStepTitle
        index={stepIndex}
        title={
          currentStep <= stepIndex
            ? translations.policy.travelCancellation.generatingPolicyPdf()
            : translations.policy.travelCancellation.policyPdfGenerated()
        }
        isActive={currentStep >= stepIndex}
        showLoader={currentStep === stepIndex && !error}
        isStepCompleted={currentStep > stepIndex}
      />

      {error && (
        <>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <ErrorAlert error={error} sx={{ mt: 3 }} retry={generatePdf} />
        </>
      )}
    </Card>
  );
}
