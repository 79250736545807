import CashbackButton from './CashbackButton';
import { useTranslations } from 'hooks/useTranslations';
import { CashbackOffer } from 'types/models/cashback-offer';
import LinkIcon from '@mui/icons-material/Link';
import CashbackTouristLinkDialog from './CashbackTouristLinkDialog';

type CreateTouristLinkCashbackButtonType = {
  offer: CashbackOffer;
  onDialogOpen: () => void;
  onDialogClose: () => void;
};

const CreateTouristLinkCashbackButton: React.FC<CreateTouristLinkCashbackButtonType> = ({
  offer,
  onDialogOpen,
  onDialogClose,
}) => {
  const translations = useTranslations();

  return (
    <CashbackTouristLinkDialog offer={offer} onDialogOpen={onDialogOpen} onDialogClose={onDialogClose}>
      <CashbackButton
        startIcon={<LinkIcon fontSize="inherit" />}
        text={translations.common.touristLink()}
        border="1px solid #00AB55"
        colorText="#00AB55"
        colorButton="success"
      />
    </CashbackTouristLinkDialog>
  );
};

export default CreateTouristLinkCashbackButton;
