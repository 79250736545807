import { Stack } from '@mui/material';
import ErrorAlert from 'components/alerts/ErrorAlert';
import { useTourAgencyBalance } from 'hooks/tour-agency/useTourAgencyBalance';
import { useTourAgentBalance } from 'hooks/tour-agency/useTourAgentBalance';
import { useWithdrawalResultHandler } from 'hooks/ui/useWithdrawalRequestHandler';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useCallback } from 'react';
import { useWithdrawThroughMobilePhoneRequest } from 'redux/slices/baseApi';
import { PaymentMethod } from '../common/payment-method';
import WithdrawalButton from '../common/WithdrawalButton';
import WithdrawAmountAlert from '../common/WithdrawAmountAlert';

type Props = {};

export default function MobilePhoneWithdrawal(props: Props) {
  const role = useCurrentRole();

  if (role.tourAgency) {
    return <TourAgencyMobilePhoneWithdrawalView />;
  }

  return <TourAgentMobilePhoneWithdrawalView />;
}

type MobilePhoneWithdrawalViewProps = {
  canWithdraw: boolean;
  isBalanceLoading: boolean;
};

function TourAgentMobilePhoneWithdrawalView() {
  const { canPayout, isBalanceLoading } = useTourAgentBalance();
  return <MobilePhoneWithdrawalView canWithdraw={canPayout} isBalanceLoading={isBalanceLoading} />;
}

function TourAgencyMobilePhoneWithdrawalView() {
  const { canPayout, isBalanceLoading } = useTourAgencyBalance();
  return <MobilePhoneWithdrawalView canWithdraw={canPayout} isBalanceLoading={isBalanceLoading} />;
}

function MobilePhoneWithdrawalView(props: MobilePhoneWithdrawalViewProps) {
  const { canWithdraw, isBalanceLoading } = props;
  const role = useCurrentRole();
  const [withdraw, { isLoading, error }] = useWithdrawThroughMobilePhoneRequest();

  const handleWithdrawalResult = useWithdrawalResultHandler();
  const withdrawReward = useCallback(async () => {
    await handleWithdrawalResult(withdraw(role.id));
  }, [handleWithdrawalResult, role.id, withdraw]);

  const paymentMethod: PaymentMethod = 'mobile-phone';

  return (
    <Stack spacing={2}>
      <WithdrawAmountAlert paymentMethod={paymentMethod} />

      {error && <ErrorAlert error={error} />}

      <WithdrawalButton
        canWithdraw={canWithdraw}
        isLoading={isLoading || isBalanceLoading}
        withdraw={withdrawReward}
        paymentMethod={paymentMethod}
      />
    </Stack>
  );
}
