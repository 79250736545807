import { TableRow, TableCell, Typography } from '@mui/material';
import { Employee } from 'types/models/employee';
import { useRoleDescription } from 'hooks/user/useRoleDescription';

export const EmployeeItem = ({ item }: { item: Employee }) => {
  const { firstName, lastName, middleName, email, roleType } = item;

  const roleDescription = useRoleDescription(roleType);
  const name = `${lastName} ${firstName} ${middleName}`;

  return (
    <TableRow key={item.roleId}>
      <TableCell>
        <Typography variant="subtitle2"> {name}</Typography>
      </TableCell>
      <TableCell>{roleDescription}</TableCell>
      <TableCell>{email}</TableCell>
      {/* <TableCell>{<RemoveEmployeeButton employee={item} />}</TableCell> */}
    </TableRow>
  );
};
