import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { ProgressBarStyle } from 'components/LoadingScreen';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAnalytics } from 'hooks/analytics/useAnalytics';

export default function App() {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.routing.pathChanged();
  }, [analytics, location.pathname]);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              <ScrollToTop />
              <GoogleAnalytics />
              <Router />
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
