import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { useTranslations } from 'hooks/useTranslations';
import { useProfile } from 'hooks/user/useProfile';
import { Role } from 'types/account';
import { useRoleDescriptionTranslator } from 'hooks/user/useRoleDescription';
import { useDispatch } from 'redux/store';
import { selectRole } from 'redux/slices/profile';
import { Divider } from '@mui/material';

type RolesDialogProps = {
  open: boolean;
  onClose: (event: any, role?: Role) => void;
};

function RolesDialog(props: RolesDialogProps) {
  const { onClose, open } = props;
  const translations = useTranslations();
  const roleTranslator = useRoleDescriptionTranslator();
  const user = useProfile();

  const handleClose = (event: any) => {
    onClose(event);
  };

  const handleListItemClick = (event: any, role: Role) => {
    onClose(event, role);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{translations.userManagment.selectAccount()}</DialogTitle>
      <Divider sx={{ mt: 2, mb: 1 }} />
      <List sx={{ pt: 0 }}>
        {user.roles.map((role) => (
          <ListItem button onClick={(e) => handleListItemClick(e, role)} key={role.id}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={roleTranslator(role.type)} secondary={role.tourAgency?.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

type Props = {
  children: React.ReactNode;
};

export default function AccountSelectorDialog(props: Props) {
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (event: any, role?: Role) => {
    event?.preventDefault();
    setOpen(false);

    if (role) {
      dispatch(selectRole(role));
    }
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{children}</div>
      <RolesDialog open={open} onClose={handleClose} />
    </div>
  );
}
