import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
import { Box, Container, Link, Typography } from '@mui/material';
import Logo from '../../components/Logo';
import MainFooter from './MainFooter';
import MainNavbar from './MainNavbar';

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            pt: 0,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container maxWidth="lg">
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />
            </ScrollLink>

            <Typography variant="caption" component="p" sx={{ fontSize: 14 }}>
              © 2021 - {new Date().getFullYear()} ООО "Турагент.клуб"
              <br />
              Почта:{' '}
              <Link href="mailto:team@touragent.club" sx={{ textDecoration: 'none', color: 'primary.main' }}>
                team@touragent.club
              </Link>
              <br />
              Все права защищены
            </Typography>
          </Container>
        </Box>
      )}
    </>
  );
}
