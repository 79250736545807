import { Alert, Button } from '@mui/material';
import { CURRENT_ENVIRONMENT } from 'environment';
import { useFormikContext } from 'formik';
import { useTourAgencyInviteToken } from 'hooks/user/useTourAgencyInviteToken';
import { Fragment } from 'react';

export default function DemoAccountBanner() {
  const inviteToken = useTourAgencyInviteToken();
  const { setFieldValue } = useFormikContext();
  const isProduction = CURRENT_ENVIRONMENT.name === 'production';

  if (isProduction || inviteToken) {
    return <Fragment />;
  }

  return (
    <Alert severity="info">
      <b>Демо-аккаунт:</b> max.mrtnv@gmail.com / Qwerty123!
      <Button
        color="info"
        variant="outlined"
        onClick={() => {
          setFieldValue('email', 'max.mrtnv@gmail.com');
          setFieldValue('password', 'Qwerty123!');
        }}
        sx={{ height: 32, mt: 1 }}
      >
        Использовать
      </Button>
    </Alert>
  );
}
