import TextField from '@mui/material/TextField';
import { FormikProps } from 'utils/ui/formik';

type Props = {
  label: string;
  formikProps: FormikProps;
};

export function TextInput(props: Props) {
  const { label, formikProps } = props;
  const { hasError, helperText, fieldProps } = formikProps;

  return (
    <TextField
      {...fieldProps}
      value={formikProps.value || ''}
      fullWidth
      label={label}
      helperText={helperText}
      error={hasError}
    />
  );
}
