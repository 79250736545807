import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Tooltip, Typography } from '@mui/material';
import Label from '../../../Label';
import { rubPriceFormatter } from 'utils/money';
import { useTranslations } from 'hooks/useTranslations';
import { OverprotectionPolicyHistoryItem } from 'types/models/overprotection-policy-history-item';
import { DateFormat } from 'utils/ui/dateFormat';

export const TourAgencyInsuranceOrderItem = ({ item }: { item: OverprotectionPolicyHistoryItem }) => {
  const theme = useTheme();
  const translations = useTranslations();

  const now = new Date().getTime();
  const activationDate = item.activationDate.getTime();
  const isPolicyProcessCompleted = item.currentStep === 'reward_applyed';
  const isCreditedToAccount = isPolicyProcessCompleted && now > activationDate;

  const statusTableCell = (
    <TableCell>
      <Label
        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
        color={isCreditedToAccount ? 'primary' : 'info'}
      >
        {isCreditedToAccount
          ? translations.dashboard.creditedToAccount()
          : translations.dashboard.waitingToCreditToAccount()}
      </Label>
    </TableCell>
  );

  return (
    <TableRow key={item.id}>
      <TableCell>
        <Typography variant="subtitle2"> {item.ownerName}</Typography>
      </TableCell>
      <TableCell>{rubPriceFormatter.format(parseFloat(item.price))}</TableCell>

      {isCreditedToAccount ? (
        statusTableCell
      ) : (
        <Tooltip
          title={
            <Typography variant="body2" sx={{ m: 1 }}>
              {translations.dashboard.willBeCredited() + DateFormat.long(item.activationDate)}
            </Typography>
          }
        >
          {statusTableCell}
        </Tooltip>
      )}

      <TableCell>{rubPriceFormatter.format(item.rewardValue)}</TableCell>

      <TableCell>
        <Typography variant="subtitle2">{DateFormat.short(item.activationDate)}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2">{DateFormat.short(item.issueDate)}</Typography>
      </TableCell>
    </TableRow>
  );
};
