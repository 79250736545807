import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/system';
import { useTranslations } from 'hooks/useTranslations';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControl } from '@mui/material';
import ruLocale from 'date-fns/locale/ru';
import { FormikProps } from 'utils/ui/formik';
import moment from 'moment';

type Props = {
  sx?: SxProps<Theme>;
  formikProps: FormikProps;
};

export default function BirthDateInput(props: Props) {
  const translations = useTranslations();
  const {
    sx,
    formikProps: { value, hasError, helperText, setFieldValue },
  } = props;

  const minDate = moment().subtract(110, 'years').toDate();

  return (
    <FormControl fullWidth sx={sx}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <DatePicker
          label={translations.common.birthDate()}
          mask={'__.__.____'}
          onChange={(newValue) => setFieldValue(newValue)}
          minDate={minDate}
          maxDate={new Date()}
          inputFormat={'dd.MM.yyyy'}
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={translations.common.birthDate()}
              error={hasError}
              helperText={helperText}
            />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
