import { useState } from 'react';
import { Stack } from '@mui/material';
import WithdrawalBreadcrumbs from './components/common/WithdrawalBreadcrumbs';
import SelectTourAgentPaymentMethod from './components/tour-agent/SelectTourAgentPaymentMethod';
import MobilePhoneWithdrawal from './components/mobile-phone/MobilePhoneWithdrawal';
import TourAgentWithdrawalView from './components/tour-agent/TourAgentWithdrawalView';
import { PaymentMethod } from './components/common/payment-method';

type Props = {
  goToProfileEditing: () => void;
};

export default function TourAgentPayout(props: Props) {
  const { goToProfileEditing } = props;
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(null);

  return (
    <Stack spacing={3}>
      {paymentMethod && <WithdrawalBreadcrumbs paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />}

      {paymentMethod === null && <SelectTourAgentPaymentMethod selectPaymentMethod={setPaymentMethod} />}
      {paymentMethod === 'jump-finance' && <TourAgentWithdrawalView goToProfileEditing={goToProfileEditing} />}
      {paymentMethod === 'mobile-phone' && <MobilePhoneWithdrawal />}
    </Stack>
  );
}
