import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  sx?: SxProps<Theme>;
};

export default function CenterBox(props: Props) {
  const { children, sx } = props;

  return (
    <Box sx={{ minHeight: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', ...sx }}>
      {children}
    </Box>
  );
}
