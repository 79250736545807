import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Grid, Card, Stack, Typography, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import { useValidationRules } from 'hooks/ui/useValidationRules';
import { useTranslations } from 'hooks/useTranslations';
import { updateTourAgencyRequest, useTourAgencyQuery } from 'redux/slices/baseApi';
import { getErrorMessage } from 'utils/errors';
import { useSuccessSnackbar } from 'hooks/ui/useSuccessSnackbar';
import ErrorAlert from 'components/alerts/ErrorAlert';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { NumberIdentificatorInput } from 'components/inputs/NumberIdentificatorInput';
import { InputConfig } from 'config';
import { LegalAddressInput } from 'components/inputs/LegalAddressInput';

interface TourAgencyState {
  ogrn: string;
  kpp: string;
  legalAddress: string;
  bik: string;
  correspondentAccount: string;
  paymentAccount: string;
  afterSubmit?: string;
}

export default function TourAgencyData() {
  const isMountedRef = useIsMountedRef();
  const validationRules = useValidationRules();
  const role = useCurrentRole();
  const { data: tourAgency, isLoading: isLoadingTourAgency } = useTourAgencyQuery({
    roleId: role.id,
    tourAgencyId: role.tourAgency?.id || 0,
  });
  const translations = useTranslations();
  const [updateTourAgency] = updateTourAgencyRequest();
  const showSuccessSnackbar = useSuccessSnackbar();

  const UpdateTourAgencySchema = Yup.object().shape({
    ogrn: validationRules.ogrn(),
    kpp: validationRules.kpp(),
    bik: validationRules.bik(),
    correspondentAccount: validationRules.correspondentAccount(),
    paymentAccount: validationRules.paymentAccount(),
  });

  const formik = useFormik<TourAgencyState>({
    enableReinitialize: true,
    initialValues: {
      ogrn: tourAgency?.ogrn || '',
      kpp: tourAgency?.kpp || '',
      legalAddress: tourAgency?.legalAddress || '',
      bik: tourAgency?.bik || '',
      correspondentAccount: tourAgency?.correspondentAccount || '',
      paymentAccount: tourAgency?.paymentAccount || '',
    },

    validationSchema: UpdateTourAgencySchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateTourAgency({
          roleId: role.id,
          tourAgencyId: role.tourAgency?.id || 0,
          ogrn: values.ogrn || undefined,
          kpp: values.kpp || undefined,
          legalAddress: values.legalAddress || undefined,
          bik: values.bik || undefined,
          correspondentAccount: values.correspondentAccount || undefined,
          paymentAccount: values.paymentAccount || undefined,
        }).unwrap();

        if (isMountedRef.current) {
          showSuccessSnackbar(translations.account.tourAgencyUpdated());
          setSubmitting(false);
        }
      } catch (error: any) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: getErrorMessage(error) });
          setSubmitting(false);
        }
      }
    },
  });

  const { isSubmitting, errors, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack direction="row" sx={{ mb: 4, alignItems: 'center', display: 'flex' }}>
                <Typography variant="h5" sx={{ mr: 2 }}>
                  {translations.account.tourAgencyInfo()}
                </Typography>
                {isLoadingTourAgency && <CircularProgress size={20} />}
              </Stack>

              <Stack spacing={{ xs: 2, md: 3 }}>
                {errors.afterSubmit && <ErrorAlert error={errors.afterSubmit} />}

                <Stack spacing={2}>
                  <NumberIdentificatorInput
                    name="ogrn"
                    digitCount={InputConfig.ogrnLength}
                    label={translations.common.ogrn()}
                    size="medium"
                  />

                  <NumberIdentificatorInput
                    name="kpp"
                    digitCount={InputConfig.kppLength}
                    label={translations.common.kpp()}
                    size="medium"
                  />

                  <LegalAddressInput size="medium" />

                  <NumberIdentificatorInput
                    name="bik"
                    digitCount={InputConfig.bikLength}
                    label={translations.common.bik()}
                    size="medium"
                  />

                  <NumberIdentificatorInput
                    name="correspondentAccount"
                    digitCount={InputConfig.correspondentAccountLength}
                    label={translations.common.correspondentAccount()}
                    size="medium"
                  />

                  <NumberIdentificatorInput
                    name="paymentAccount"
                    digitCount={InputConfig.paymentAccountLength}
                    label={translations.common.paymentAccount()}
                    size="medium"
                  />
                </Stack>
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" disabled={isLoadingTourAgency} loading={isSubmitting}>
                  {translations.common.save()}
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
