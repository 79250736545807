import i18next from 'i18next';

export const getErrorCode = (error: any): string | undefined => {
  if (!error) {
    return undefined;
  }

  return error.data?.code?.toString() || 'UNKNOWN_ERROR';
};

export const getErrorMessage = (error: any) => {
  if (typeof error === 'string') {
    return error;
  }

  const errorMessage = error?.data?.message;

  if (errorMessage) {
    const errorCode = getErrorCode(error);
    const translationKey2 = `errorCodes.${errorCode}`;
    if (i18next.exists(translationKey2)) {
      const errorTranslation = i18next.t(translationKey2);
      return errorTranslation;
    }

    const translationKey = `errorCodes.${errorMessage}`;
    if (i18next.exists(translationKey)) {
      const errorTranslation = i18next.t(translationKey);
      return errorTranslation;
    }

    return errorMessage;
  }

  return i18next.t('common.requestError');
};
