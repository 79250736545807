import { useEffect, Fragment, useCallback } from 'react';
import { Card, Divider, Stack } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { useCreatePaymentTransactionRequest } from 'redux/slices/baseApi';
import { updateOverprotectionPaymentTransaction } from 'redux/slices/policy';
import { useDispatch, useSelector } from 'redux/store';
import PaymentStepTitle from './PaymentStepTitle';
import ErrorAlert from 'components/alerts/ErrorAlert';

type Props = {
  currentStep: number;
  changeStep(step: number): void;
};

export default function CreatePaymentTransactionCard(props: Props) {
  const translations = useTranslations();
  const { currentStep, changeStep } = props;
  const [createPaymentTransaction, { error }] = useCreatePaymentTransactionRequest();
  const paymentTransaction = useSelector((s) => s.policy.overprotectionPaymentTransaction);
  const policyProcessId = useSelector((s) => s.policy.overprotectionPolicyProcessId);
  const dispatch = useDispatch();
  const stepIndex = 1;

  const sendCreatePaymentTransactionRequest = useCallback(async () => {
    if (!policyProcessId) return;

    if (currentStep === stepIndex && paymentTransaction) {
      changeStep(stepIndex + 1);
      return;
    }

    if (paymentTransaction) return;

    const { transaction } = await createPaymentTransaction({ policyProcessId }).unwrap();
    dispatch(updateOverprotectionPaymentTransaction(transaction));
    changeStep(stepIndex + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTransaction, policyProcessId]);

  useEffect(() => {
    sendCreatePaymentTransactionRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTransaction]);

  return (
    <Card sx={{ py: 3, px: 3 }}>
      <Stack direction="row">
        <PaymentStepTitle
          index={stepIndex}
          title={
            currentStep <= stepIndex
              ? translations.policy.travelCancellation.paymentTransactionCreation()
              : translations.policy.travelCancellation.paymentTransactionCreated()
          }
          isActive={currentStep >= stepIndex}
          showLoader={currentStep === stepIndex && !error}
          isStepCompleted={currentStep > stepIndex}
        />
      </Stack>

      {error && (
        <Fragment>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <ErrorAlert error={error} retry={sendCreatePaymentTransactionRequest} />
        </Fragment>
      )}
    </Card>
  );
}
