import { Card, Table, TableRow, TableBody, TableCell, TableHead, CardHeader, TableContainer } from '@mui/material';
import Scrollbar from '../../../Scrollbar';
import { useTourAgencyOverprotectionPoliciesHistoryQuery } from 'redux/slices/baseApi';
import { useTranslations } from 'hooks/useTranslations';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { TourAgencyInsuranceOrderItem } from './TourAgencyInsuranceOrderItem';
import { useCurrentRole } from 'hooks/user/useCurrentRole';

export default function TourAgencyInsuranceOrderList() {
  const role = useCurrentRole();
  const { data = [] } = useTourAgencyOverprotectionPoliciesHistoryQuery({
    roleId: role.id,
    tourAgencyId: role.tourAgency?.id || 0,
  });
  const translations = useTranslations();

  return (
    <Card sx={{ pb: 3 }}>
      <CardHeader
        avatar={<AssignmentTurnedInOutlinedIcon />}
        title={translations.dashboard.overprotectionPoliciesHistory()}
        sx={{ mb: 3 }}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.common.client()}</TableCell>
                <TableCell>{translations.common.price()}</TableCell>
                <TableCell>{translations.common.status()}</TableCell>
                <TableCell>{translations.common.premium()}</TableCell>
                <TableCell>{translations.common.bonusActivationDate()}</TableCell>
                <TableCell>{translations.common.issuedDate()}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((policyData) => (
                <TourAgencyInsuranceOrderItem key={policyData.id} item={policyData} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
