import { Card, Table, TableRow, TableBody, TableCell, TableHead, CardHeader, TableContainer } from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { useTranslations } from 'hooks/useTranslations';
import PeopleIcon from '@mui/icons-material/People';
import { EmployeeItem } from './EmployeeItem';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useTourAgencyEmployeesQuery } from 'redux/slices/baseApi';
import { AddTourAgencyEmployeeButton } from './AddTourAgencyEmployeeButton';

export default function EmployeeList() {
  const role = useCurrentRole();
  const { data = [] } = useTourAgencyEmployeesQuery({
    tourAgencyId: role.tourAgency?.id || 0,
    roleId: role.id,
  });
  const translations = useTranslations();

  return (
    <Card sx={{ pb: 3 }}>
      <CardHeader
        avatar={<PeopleIcon />}
        title={translations.userManagment.title()}
        sx={{ mb: 3 }}
        action={<AddTourAgencyEmployeeButton />}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.userManagment.employee()}</TableCell>
                <TableCell>{translations.common.role()}</TableCell>
                <TableCell>{translations.common.email()}</TableCell>
                <TableCell>{translations.common.actions()}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((employee) => (
                <EmployeeItem key={employee.roleId} item={employee} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
