import { Card } from '@mui/material';
import Subtitle from 'components/text/Subtitle';
import { useProfile } from 'hooks/user/useProfile';
import { useTranslations } from 'hooks/useTranslations';
import { getFullUserName } from 'utils/data/user';
import DataItem from './common/DataItem';

type Props = {};

export default function ProfileVerificationView(props: Props) {
  const translations = useTranslations();
  const profile = useProfile();
  const strings = translations.common;

  return (
    <Card sx={{ p: 3 }}>
      <Subtitle>{translations.account.checkProfile()} </Subtitle>

      <DataItem title={strings.fio()} value={getFullUserName(profile)} />
      <DataItem title={strings.phone()} value={profile.phone} />
      <DataItem title={strings.inn()} value={profile.inn} />
    </Card>
  );
}
