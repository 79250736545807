import { useSelector } from 'redux/store';

const useAuth = () => {
  const profile = useSelector((s) => s.profile.profile);
  const isAuthenticated = profile != null;
  const displayName = `${profile?.firstName || ''} ${profile?.lastName || ''}`;

  return { profile, displayName, isAuthenticated };
};

export default useAuth;
