import moment from 'moment';

export namespace DateFormat {
  export const shortFormatString = 'DD.MM.yyyy';
  export const longFormatString = 'D MMMM YYYY';
  export const serverFormatString = 'YYYY-MM-DD';

  export const short = (date: Date) => moment(date).format(shortFormatString);
  export const long = (date: Date) => moment(date).format(longFormatString);
  export const server = (date: Date) => moment(date).format(serverFormatString);
}
