import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';
import TravelCancellationInsuranceStep1 from 'pages/insurance/TravelCancellationInsuranceStep1';
import TravelCancellationInsuranceStep2 from 'pages/insurance/TravelCancellationInsuranceStep2';
import TravelCancellationInsuranceStep3 from 'pages/insurance/TravelCancellationInsuranceStep3';
import TravelCancellationInsuranceStep4 from 'pages/insurance/TravelCancellationInsuranceStep4';
import DashboardPage from 'pages/dashboard/DashboardPage';
import ChangePassword from 'pages/authentication/ChangePassword';
import UserManagmentPage from 'pages/dashboard/UserManagmentPage';
import TourAgencyDashboardPage from 'pages/dashboard/TourAgencyDashboardPage';
import SravniVzrPage from 'pages/sravni/SravniVzrPage';
import { CURRENT_ENVIRONMENT } from 'environment';
import FaqPage from 'pages/faq/FaqPage';
import SupportPage from 'pages/support/SupportPage';
import CashbackPage from 'pages/cashback/CashbackPage';
import { PATH_PROFILE } from './paths';
import AccountPage from 'pages/profile/AccountPage';
import TravelCancelationComingSoon from 'pages/insurance/TravelCancelationComingSoon';

const isProduction = CURRENT_ENVIRONMENT.name === 'production';

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'change-password', element: <ChangePassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/" replace /> },
        { path: 'overview', element: <DashboardPage /> },
        { path: 'employees', element: <UserManagmentPage /> },
        { path: 'tour-agency/sales', element: <TourAgencyDashboardPage /> },
      ],
    },

    {
      path: 'insurance',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/insurance/travel-cancellation" replace /> },
        {
          path: 'travel-cancellation',
          element: isProduction ? <TravelCancelationComingSoon /> : <TravelCancellationInsuranceStep1 />,
        },
        {
          path: 'travel-cancellation-step-2',
          element: isProduction ? <TravelCancelationComingSoon /> : <TravelCancellationInsuranceStep2 />,
        },
        {
          path: 'travel-cancellation-step-3',
          element: isProduction ? <TravelCancelationComingSoon /> : <TravelCancellationInsuranceStep3 />,
        },
        {
          path: 'travel-cancellation-step-4',
          element: isProduction ? <TravelCancelationComingSoon /> : <TravelCancellationInsuranceStep4 />,
        },
        { path: 'vzr', element: <SravniVzrPage /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
          ],
        },
      ],
    },
    {
      path: 'hotels',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/hotels/news" replace /> },
        { path: 'news', element: <ComingSoonCompact /> },
        { path: 'actions', element: <ComingSoonCompact /> },
        { path: 'rating', element: <ComingSoonCompact /> },
      ],
    },

    {
      path: 'support',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/support/faq" replace /> },
        { path: 'faq', element: <FaqPage /> },
        { path: 'ask-question', element: <SupportPage /> },
      ],
    },

    {
      path: 'cashback',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/cashback/offers" replace /> },
        { path: '/cashback/offers', element: <CashbackPage /> },
      ],
    },

    {
      path: PATH_PROFILE.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_PROFILE.profile.account} replace /> },
        { path: PATH_PROFILE.profile.account, element: <AccountPage /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'auth/confirm-email', element: <ConfirmEmailPage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <LandingPage /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS
const ComingSoonCompact = Loadable(lazy(() => import('../pages/ComingSoonCompact')));

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const ConfirmEmailPage = Loadable(lazy(() => import('../pages/authentication/ConfirmEmailPage')));
// Dashboard
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
