import { Alert, Button, Card, Divider, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import PaymentStepTitle from './PaymentStepTitle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useSelector } from 'redux/store';

type Props = {
  currentStep: number;
  isLoadingTransaction: boolean;
  changeStep(step: number): void;
};

export default function GoToPaymentCard(props: Props) {
  const translations = useTranslations();
  const { currentStep, isLoadingTransaction, changeStep } = props;
  const paymentTransaction = useSelector((s) => s.policy.overprotectionPaymentTransaction);
  const stepIndex = 2;

  return (
    <Card sx={{ py: 3, px: 3, mt: 3 }}>
      <PaymentStepTitle
        index={stepIndex}
        title={translations.policy.travelCancellation.goToPayment()}
        isActive={currentStep >= stepIndex}
        showLoader={currentStep === stepIndex && isLoadingTransaction}
        isStepCompleted={currentStep > stepIndex}
      />
      {currentStep === stepIndex && !isLoadingTransaction && (
        <>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Alert color="info">
            <Typography>{translations.policy.travelCancellation.redirectToPayment()}</Typography>
            <Typography>{translations.policy.travelCancellation.continueAfterPaymentRedirect()}</Typography>
          </Alert>

          <a
            href={paymentTransaction?.paymentTransactionUrl}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 1 }}
              style={{ textTransform: 'none' }}
              size={'large'}
              onClick={() => {
                changeStep(stepIndex + 1);
              }}
            >
              <ExitToAppIcon sx={{ mr: 1 }} />
              {translations.policy.travelCancellation.goToPaymentPage()}
            </Button>
          </a>
        </>
      )}
    </Card>
  );
}
