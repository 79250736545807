import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useTourAgencyBalanceQuery } from 'redux/slices/baseApi';

export const useTourAgencyBalance = () => {
  const role = useCurrentRole();
  const { data: balance, isLoading: isBalanceLoading } = useTourAgencyBalanceQuery(
    {
      roleId: role.id,
      tourAgencyId: role.tourAgency?.id || 0,
    },
    { refetchOnMountOrArgChange: true },
  );

  const availableRewardValue = balance?.availableRewardValue || 0;
  const minimalPayoutValue = balance?.minimalPayoutValue || 100;
  const canPayout = availableRewardValue >= minimalPayoutValue;

  return { balance, isBalanceLoading, canPayout };
};
