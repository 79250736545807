import { useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { useTranslations } from 'hooks/useTranslations';
import AddIcon from '@mui/icons-material/Add';
import AddEmployeeForm from './add-employee/AddEmployeeForm';

export const AddTourAgencyEmployeeButton = () => {
  const translations = useTranslations();

  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <div>
      <LoadingButton variant="contained" style={{ textTransform: 'none' }} onClick={handleClickOpen}>
        <AddIcon sx={{ mr: 1 }} />
        {translations.userManagment.addEmployee()}
      </LoadingButton>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>{translations.userManagment.addEmployee()}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ my: 2 }}>{translations.userManagment.addEmployeeDescription()}</DialogContentText>
          <AddEmployeeForm onSubmitSuccess={handleClose} onCancel={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
