import { Chip, Tooltip, Typography } from '@mui/material';

type Props = {
  title: string;
  tooltipText?: string;
  isSelected?: boolean;
  color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  onClick?: () => void;
};

export const ChipItem = (props: Props) => {
  const { title, tooltipText, isSelected, color, onClick } = props;
  const selected = isSelected !== false;

  const chip = (
    <Chip
      variant={selected ? 'filled' : 'outlined'}
      label={title}
      sx={{
        ml: 2,
        px: 1,
        mt: 0.15,
        fontWeight: selected ? 'bold' : 'normal',
        letterSpacing: 0.2,
      }}
      size="small"
      color={color}
      onClick={onClick}
    />
  );

  if (!tooltipText) {
    return chip;
  }

  const tooltipTextView = (
    <Typography variant="body2" sx={{ p: 1 }}>
      {tooltipText}
    </Typography>
  );

  return <Tooltip title={tooltipTextView}>{chip}</Tooltip>;
};
