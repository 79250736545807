import { useMemo } from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslations } from 'hooks/useTranslations';
import { PaymentMethod } from './payment-method';

type Props = {
  paymentMethod: PaymentMethod;
  setPaymentMethod(paymentMethod: PaymentMethod): void;
};

export default function WithdrawalBreadcrumbs(props: Props) {
  const { paymentMethod, setPaymentMethod } = props;
  const translations = useTranslations();

  const selectedPaymentMethod = useMemo(() => {
    if (paymentMethod === 'jump-finance') return translations.account.withdrawToCard();
    if (paymentMethod === 'mobile-phone') return translations.account.withdrawToMobilePhone();

    return '';
  }, [paymentMethod, translations]);

  return (
    <Breadcrumbs
      key={paymentMethod}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{
        border: '1px solid #eee',
        py: 1.5,
        px: 2,
        borderRadius: 1,
      }}
    >
      <Typography
        color={paymentMethod ? 'primary.green' : 'text.primary'}
        sx={{ letterSpacing: 0.7 }}
        onClick={() => setPaymentMethod(null)}
      >
        {translations.account.payout()}
      </Typography>
      {paymentMethod && (
        <Typography color={paymentMethod ? 'text.primary' : 'text.secondary'} sx={{ letterSpacing: 0.7 }}>
          {selectedPaymentMethod}
        </Typography>
      )}
    </Breadcrumbs>
  );
}
