import { useEffect, useState } from 'react';
import { FormControl, Grid, Stack } from '@mui/material';
import CreatePolicyPdfCard from './components/CreatePolicyPdfCard';
import GivePolicyToClientCard from './components/GivePolicyToClientCard';
import PolicySubmissionCard from './components/PolicySubmissionCard';
import { useSelector } from 'redux/store';

export default function Covid19PolicyStep4() {
  const [currentStep, setCurrentStep] = useState(1);
  const policy = useSelector((s) => s.policy.overprotectionPolicy);

  useEffect(() => {
    const policyState = policy?.state;

    switch (policyState) {
      case 'pdfGenerated':
        setCurrentStep(2);
        return;

      case 'submitted':
        setCurrentStep(2);
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Stack direction="row" spacing={3}>
          <FormControl fullWidth>
            <CreatePolicyPdfCard currentStep={currentStep} changeStep={setCurrentStep} />
            <PolicySubmissionCard currentStep={currentStep} changeStep={setCurrentStep} />
            <GivePolicyToClientCard currentStep={currentStep} />
          </FormControl>
        </Stack>
      </Grid>
    </Grid>
  );
}
