import { useTranslations } from 'hooks/useTranslations';
import { ChipItem } from './ChipItem';

type Props = {
  isOwner: boolean;
  changeToOwner: () => void;
};

export const InsuranceOwnerChip = (props: Props) => {
  const { isOwner, changeToOwner } = props;
  const translations = useTranslations();
  const strings = translations.policy.travelCancellation;

  return (
    <ChipItem
      title={strings.policyOwner()}
      tooltipText={isOwner ? strings.policyOwnerTooltip() : strings.clickToMakePolicyOwner()}
      isSelected={isOwner}
      color="secondary"
      onClick={!isOwner ? changeToOwner : undefined}
    />
  );
};
