import { Alert, Card } from '@mui/material';
import CenterBox from 'components/containers/CenterBox';
import { useTranslations } from 'hooks/useTranslations';

type Props = {};

export default function ContractorCreationErrorCard(props: Props) {
  const translations = useTranslations();

  return (
    <Card sx={{ p: 3 }}>
      <CenterBox>
        <Alert color="error">{translations.account.contractorStatusError()}</Alert>
      </CenterBox>
    </Card>
  );
}
