import { useTranslations } from 'hooks/useTranslations';
import { useMemo } from 'react';
import { RoleType } from 'types/account';
import { useCurrentRolePermissions } from './useCurrentRolePermissions';

export type RoleOptions = {
  roleName: string;
  roleType: RoleType;
  rights: string[];
};

export const useRolesOptions = () => {
  const translations = useTranslations();
  const permissions = useCurrentRolePermissions();

  const roleOptions = useMemo(() => {
    const canUseServices = translations.userManagment.canUseServices();
    const canManageEmployees = translations.userManagment.canManageEmployees();
    const canNotManageEmployees = translations.userManagment.canNotManageEmployees();
    const canTransferMoney = translations.userManagment.canTransferMoney();
    const canNotTransferMoney = translations.userManagment.canNotTransferMoney();

    const options: RoleOptions[] = [
      {
        roleName: translations.roles.tourAgencyManager(),
        roleType: 'tour-agency-manager',
        rights: [canUseServices, canNotManageEmployees, canNotTransferMoney],
      },
      {
        roleName: translations.roles.tourAgencyAdmin(),
        roleType: 'tour-agency-admin',
        rights: [canUseServices, canManageEmployees, canNotTransferMoney],
      },
    ];

    if (permissions.canInviteTourAgencyOwner) {
      options.push({
        roleName: translations.roles.tourAgencyOwner(),
        roleType: 'tour-agency-owner',
        rights: [canUseServices, canManageEmployees, canTransferMoney],
      });
    }

    return options;
  }, [translations, permissions]);

  return roleOptions;
};
