class TokenStorage {
  getAccessToken(): string | null {
    const accessToken = window.localStorage.getItem('access_token');
    return accessToken;
  }

  getRefreshToken(): string | null {
    const refreshToken = window.localStorage.getItem('refresh_token');
    return refreshToken;
  }

  saveAccessToken(accessToken: string) {
    window.localStorage.setItem('access_token', accessToken);
  }

  saveRefreshToken(refreshToken: string) {
    window.localStorage.setItem('refresh_token', refreshToken);
  }

  clearTokens() {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('refresh_token');
  }
}

export const tokenStorage = new TokenStorage();
