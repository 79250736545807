import { Card, CircularProgress, Grid } from '@mui/material';
import InfoItem from 'components/insurance/step-2/components/InfoItem';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SecurityIcon from '@mui/icons-material/Security';
import { useTranslations } from 'hooks/useTranslations';
import { useOverprotectionPolicySumInsuredQuery } from 'redux/slices/baseApi';
import { rubPriceFormatter } from 'utils/money';

type Props = {
  participantCount: number;
  tourPrice: number;
};

export default function SumInsuredCard(props: Props) {
  const { participantCount, tourPrice } = props;
  const translations = useTranslations();
  const { data, isLoading, isFetching } = useOverprotectionPolicySumInsuredQuery({
    participantCount,
    tourPrice,
  });

  return (
    <Grid item xs={12} md={12}>
      <Card sx={{ pb: 3, pt: 1, px: 3 }}>
        <InfoItem
          title={translations.policy.travelCancellation.participantCount()}
          icon={<PeopleAltIcon />}
          message={participantCount.toString()}
        />
        <InfoItem
          title={translations.policy.travelCancellation.maxInsuranceSum()}
          icon={<SecurityIcon />}
          message={
            isLoading || isFetching ? <CircularProgress size={16} /> : rubPriceFormatter.format(data?.sumInsured || 0)
          }
        />
      </Card>
    </Grid>
  );
}
