import { useEffect, useState } from 'react';
import { FormControl, Grid, Stack } from '@mui/material';
import CreatePaymentTransactionCard from './components/CreatePaymentTransactionCard';
import GoToPaymentCard from './components/GoToPaymentCard';
import PaymentConfirmationCard from './components/PaymentConfirmationCard';
import { useDispatch, useSelector } from 'redux/store';
import { PATH_POLICY } from 'routes/paths';
import { useNavigate } from 'react-router';
import { usePaymentTransactionStatusQuery } from 'redux/slices/baseApi';
import { updateOverprotectionPaymentTransaction } from 'redux/slices/policy';

export default function Covid19PolicyStep3() {
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const [isTransactionCheckedOnStart, setIsTransactionCheckedOnStart] = useState(false);
  const dispatch = useDispatch();

  const paymentTransaction = useSelector((s) => s.policy.overprotectionPaymentTransaction);
  const transactionTrackingId = paymentTransaction?.transactionTrackingId || '';

  const successStatus = 'succeed';
  const isTransactionSucceed = paymentTransaction?.status === successStatus;

  const shouldCheckTransactionStatus =
    !!transactionTrackingId && //
    !isTransactionSucceed && //
    (currentStep === 3 || !isTransactionCheckedOnStart);

  const { data, isLoading } = usePaymentTransactionStatusQuery(transactionTrackingId, {
    pollingInterval: 10_000,
    skip: !shouldCheckTransactionStatus,
  });

  useEffect(() => {
    if (data) {
      dispatch(updateOverprotectionPaymentTransaction(data));
      setIsTransactionCheckedOnStart(true);
    }

    if (isTransactionSucceed) {
      navigate(PATH_POLICY.insurance.travelCancellationInsuranceStep4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isTransactionSucceed]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Stack direction="row" spacing={3}>
          <FormControl fullWidth>
            <CreatePaymentTransactionCard currentStep={currentStep} changeStep={setCurrentStep} />
            <GoToPaymentCard
              currentStep={currentStep}
              isLoadingTransaction={shouldCheckTransactionStatus && isLoading}
              changeStep={setCurrentStep}
            />
            <PaymentConfirmationCard
              currentStep={currentStep}
              isTransactionSucceed={isTransactionSucceed}
              changeStep={setCurrentStep}
            />
          </FormControl>
        </Stack>
      </Grid>
    </Grid>
  );
}
