import { useOverprotectionPolicyProcessRequest } from 'redux/slices/baseApi';
import { useCallback } from 'react';
import {
  NotCompletedOverprotectionPolicyProcess,
  PolicyProcessStep,
} from 'types/models/not-completed-overprotection-policy-process';
import { useErrorSnackbar } from 'hooks/ui/useErrorSnackbar';
import {
  updateOverprotectionPaymentTransaction,
  updateOverprotectionPolicy,
  updateOverprotectionPolicyPriceCalculation,
  updateOverprotectionPolicyProcessId,
} from 'redux/slices/policy';
import { useDispatch } from 'redux/store';
import { PATH_POLICY } from 'routes/paths';
import { useNavigate } from 'react-router';

export const useContinuePolicyProcess = (policyProcess: NotCompletedOverprotectionPolicyProcess) => {
  const [getPolicyProcessInfo] = useOverprotectionPolicyProcessRequest();
  const showErrorSnackbar = useErrorSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const continuePolicyProcess = useCallback(async () => {
    try {
      const policyProcessInfo = await getPolicyProcessInfo(policyProcess.id).unwrap();
      const { calculation, transaction, policy } = policyProcessInfo;

      dispatch(updateOverprotectionPolicyProcessId(policyProcess.id));
      dispatch(updateOverprotectionPolicyPriceCalculation(calculation || undefined));
      dispatch(updateOverprotectionPaymentTransaction(transaction || undefined));
      dispatch(updateOverprotectionPolicy(policy || undefined));

      const oneOfSteps = (steps: PolicyProcessStep[]) => {
        return steps.includes(policyProcess.currentStep);
      };

      if (oneOfSteps(['price_calculated'])) {
        navigate(PATH_POLICY.insurance.travelCancellationInsuranceStep2);
      } else if (
        oneOfSteps(['participants_data_saved', 'payment_started', 'payment_transaction_created', 'payment_completed'])
      ) {
        navigate(PATH_POLICY.insurance.travelCancellationInsuranceStep3);
      } else if (
        oneOfSteps(['payment_confirmed', 'policy_generated', 'policy_submitted', 'policy_sent_to_stackholders'])
      ) {
        navigate(PATH_POLICY.insurance.travelCancellationInsuranceStep4);
      }
    } catch (error) {
      showErrorSnackbar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return continuePolicyProcess;
};
