import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/system';

type Props = {
  icon: React.ReactNode;
  title: string;
  message: string | React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function InfoItem(props: Props) {
  return (
    <Stack direction="row" sx={{ mt: 2 }}>
      {props.icon}
      <Typography variant="subtitle2" sx={{ ml: 1 }}>
        {props.title + ':'}
      </Typography>
      <Typography variant="body1" sx={{ ml: 2 }}>
        {props.message}
      </Typography>
    </Stack>
  );
}
