import { Container, Grid } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { usePageTitle } from 'hooks/ui/usePageTitle';
import { useTranslations } from 'hooks/useTranslations';
import EmployeeList from 'components/_dashboard/user-managment/EmployeeList';
import InvitesList from 'components/_dashboard/user-managment/InvitesList';

export default function UserManagmentPage() {
  const { themeStretch } = useSettings();
  const translations = useTranslations();
  const pageTitle = usePageTitle(translations.userManagment.title());

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <InvitesList />
          </Grid>

          <Grid item xs={12} md={12}>
            <EmployeeList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
