import { Box, Stack, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

type Props = {
  title: string;
  value: string;
  fontSize?: 'small' | 'medium';
  sx?: SxProps<Theme>;
};

export default function CashbackOfferCardInfoItem(props: Props) {
  const { title, value, fontSize, sx } = props;

  const titleFontSize = fontSize === 'medium' ? 14 : 13;
  const valueFontSize = fontSize === 'medium' ? 15 : 14;

  return (
    <Stack direction="row" sx={sx}>
      <Typography sx={{ fontSize: titleFontSize }}>{title}</Typography>
      <Box
        style={{ flexGrow: 1, borderBottom: '1px dashed #ddd', marginBottom: 5, marginLeft: 10, marginRight: 10 }}
      ></Box>
      <Typography variant="subtitle1" sx={{ fontSize: valueFontSize }}>
        {value}
      </Typography>
    </Stack>
  );
}
