import { Card, CircularProgress, Typography } from '@mui/material';
import CenterBox from 'components/containers/CenterBox';
import { useTranslations } from 'hooks/useTranslations';

type Props = {
  type: 'self-employed-or-individual' | 'ltd';
};

export default function CheckingStatusCard(props: Props) {
  const { type } = props;
  const translations = useTranslations();

  return (
    <Card sx={{ p: 3 }}>
      <CenterBox>
        <CircularProgress sx={{ mr: 2 }} />
        <Typography>
          {type === 'ltd' ? translations.account.checkTourAgencyStatus() : translations.account.checkContractorStatus()}
        </Typography>
      </CenterBox>
    </Card>
  );
}
