import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Button, Stack } from '@mui/material';
import Label from '../../../Label';
import { deleteOverprotectionPolicyProcessRequest, useOverprotectionPolicyProcessRequest } from 'redux/slices/baseApi';
import { useTranslations } from 'hooks/useTranslations';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { AlertDialogButton } from 'components/alerts/AlertDialogButton';
import { NotCompletedOverprotectionPolicyProcess } from 'types/models/not-completed-overprotection-policy-process';
import { useOverprotectionPolicyStepTitles } from 'hooks/ui/useOverprotectionPolicyStepTitles';
import { useContinuePolicyProcess } from 'hooks/policy/useContinuePolicyProcess';
import { DateFormat } from 'utils/ui/dateFormat';

type Props = {
  policyProcess: NotCompletedOverprotectionPolicyProcess;
};

export const NotCompletedInsuranceItem = (props: Props) => {
  const { policyProcess } = props;
  const theme = useTheme();
  const [, { isLoading }] = useOverprotectionPolicyProcessRequest();
  const [deletePolicyProcess, { isLoading: isDeleting }] = deleteOverprotectionPolicyProcessRequest();
  const translations = useTranslations();
  const stepTranslations = useOverprotectionPolicyStepTitles();
  const continuePolicyProcess = useContinuePolicyProcess(policyProcess);

  return (
    <TableRow key={policyProcess.id}>
      <TableCell>
        <Typography variant="subtitle2"> {policyProcess.ownerName}</Typography>
      </TableCell>

      <TableCell>
        <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={'warning'}>
          {stepTranslations[policyProcess.currentStep]}
        </Label>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2">{DateFormat.short(policyProcess.issueDate)}</Typography>
      </TableCell>

      <TableCell>
        <Stack direction="row">
          <Button variant="contained" disabled={isLoading || isDeleting} onClick={continuePolicyProcess}>
            {translations.common.continue()}
          </Button>
          <AlertDialogButton
            action={'delete'}
            dialogTitle={translations.dashboard.deleteNotCompletedPolicy()}
            dialogDescription={translations.dashboard.deleteNotCompletedPolicyDescription()}
            onConfirm={() => deletePolicyProcess(policyProcess.id)}
            isDisabled={isLoading || isDeleting}
            sx={{ ml: 2 }}
          >
            <DeleteForeverOutlinedIcon />
          </AlertDialogButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
