import { TableRow, TableCell, Typography } from '@mui/material';
import { useRoleDescription } from 'hooks/user/useRoleDescription';
import { TourAgencyInvite } from 'types/models/tour-agency-invite';
import { DateFormat } from 'utils/ui/dateFormat';
import RemoveInviteButton from './components/RemoveInviteButton';

export const InviteItem = ({ item }: { item: TourAgencyInvite }) => {
  const { email, roleType, issueDate, expiresAt } = item;
  const roleDescription = useRoleDescription(roleType);

  return (
    <TableRow key={item.email}>
      <TableCell>
        <Typography variant="subtitle2"> {email}</Typography>
      </TableCell>
      <TableCell>{roleDescription}</TableCell>
      <TableCell>{DateFormat.short(issueDate)}</TableCell>
      <TableCell>{DateFormat.short(expiresAt)}</TableCell>
      <TableCell>
        <RemoveInviteButton invite={item} />
      </TableCell>
    </TableRow>
  );
};
