import { Button, Card, Typography } from '@mui/material';
import Subtitle from 'components/text/Subtitle';
import { useTranslations } from 'hooks/useTranslations';

type Props = {
  goToProfileEditing: () => void;
};

export default function FillTourAgentProfileView(props: Props) {
  const { goToProfileEditing } = props;
  const translations = useTranslations();

  return (
    <Card sx={{ p: 3, maxWidth: 600 }}>
      <Subtitle>{translations.account.fillProfile()}</Subtitle>

      <Typography variant="body1" sx={{ mt: 0, mb: 3 }}>
        {translations.account.fillProfileDescription()}
      </Typography>

      <Button size="medium" variant="contained" onClick={goToProfileEditing}>
        {translations.common.fill()}
      </Button>
    </Card>
  );
}
