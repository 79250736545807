import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslations } from 'hooks/useTranslations';
import { FormikProps } from 'utils/ui/formik';

type RiskSetOption = { name: string };

export const RiskSetOptions: RiskSetOption[] = [{ name: 'ОПП-ДР-1' }, { name: 'ОПП-ДР-2' }];

type Props = {
  formikProps: FormikProps;
};

export default function RiskSetSelect(props: Props) {
  const translations = useTranslations();
  const { id, defaultValue, fieldProps } = props.formikProps;

  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{translations.common.riskSet()}</InputLabel>
      <Select
        id={id}
        labelId={`${id}-label`}
        label={translations.common.riskSet()}
        defaultValue={defaultValue}
        {...fieldProps}
        fullWidth
        disabled={true}
      >
        {RiskSetOptions.map((option) => {
          return (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
