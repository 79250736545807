import { Alert, Container, Stack, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { useTranslations } from 'hooks/useTranslations';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useEffect, useState } from 'react';
import { CURRENT_ENVIRONMENT } from 'environment';
import { useDispatch } from 'redux/store';
import { hideVzrPolicyContactInfoNotification, hideVzrPolicyRewardInfoNotification } from 'redux/slices/profile';
import CloseIcon from '@mui/icons-material/Close';
import SravniNewPolicyButton from 'components/insurance/components/SravniNewPolicyButton';
import { SxProps, Theme } from '@mui/system';

export default function SravniPage() {
  const translations = useTranslations();
  const { themeStretch } = useSettings();
  const role = useCurrentRole();
  const dispatch = useDispatch();
  const [showContactInfoAlert, setShowContactInfoAlert] = useState(true); // useSelector((s) => s.profile.showVzrPolicyContactInfoNotification);
  const [showRewardInfoAlert, setShowRewardInfoAlert] = useState(true); // useSelector((s) => s.profile.showVzrPolicyRewardInfoNotification);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://sravni.ru/widgets/loader.js';
    script.async = true;

    script.setAttribute('data-product', 'vzr');
    script.setAttribute('data-inFrame', 'true');
    script.setAttribute('data-theme-palette', '{"elementsColor":"37, 208, 111","activeElementsColor":"67, 153, 255"}');
    script.setAttribute('data-aff_id', '1169');
    script.setAttribute('data-offer_id', '1068');
    script.setAttribute('data-aff_sub', '10');
    script.setAttribute('data-source', '510');
    script.setAttribute('data-aff_sub2', role.id.toString());
    script.setAttribute('data-aff_sub4', CURRENT_ENVIRONMENT.name);

    const tourAgencyId = role.tourAgency?.id;
    if (tourAgencyId) {
      script.setAttribute('data-aff_sub3', tourAgencyId.toString());
    }

    const adblockCheckerScript = document.createElement('script');
    adblockCheckerScript.src = 'https://storage.yandexcloud.net/sravni-affiliates-public/adblock/adblock_checker.js';
    adblockCheckerScript.async = true;

    script.onload = () => {
      const adblockCheckerScriptParent = document.getElementById('vzr-script-container');
      adblockCheckerScriptParent?.appendChild(adblockCheckerScript);
    };

    const scriptParent = document.getElementById('vzr-script-container');
    const scriptParentIframeChilds = scriptParent?.getElementsByTagName('iframe') || [];
    const alreadyHasSravniFrame = scriptParentIframeChilds.length > 0;

    if (!alreadyHasSravniFrame) {
      scriptParent?.appendChild(script);
    }

    return () => {
      if (script.parentNode === scriptParent) {
        scriptParent?.removeChild(script);
      }

      if (adblockCheckerScript.parentNode === scriptParent) {
        scriptParent?.removeChild(adblockCheckerScript);
      }
    };
  }, [role]);

  return (
    <Page title={translations.vzr.title()}>
      {showContactInfoAlert && (
        <NotificationAlert
          color="primary"
          sx={{ mb: 1 }}
          title={translations.vzr.contactInfoNotification()}
          action={() => {
            dispatch(hideVzrPolicyContactInfoNotification());
            setShowContactInfoAlert(false);
          }}
        />
      )}

      {showRewardInfoAlert && (
        <NotificationAlert
          color="info"
          sx={{ mb: 2 }}
          title={translations.vzr.rewardNotification()}
          action={() => {
            dispatch(hideVzrPolicyRewardInfoNotification());
            setShowRewardInfoAlert(false);
          }}
        />
      )}

      <Container maxWidth={themeStretch ? false : 'lg'} id="vzr-script-container"></Container>

      <SravniNewPolicyButton />
    </Page>
  );
}

function NotificationAlert(props: { color: any; title: string; action(): void; sx?: SxProps<Theme> }) {
  const { color, title, action, sx } = props;

  return (
    <Alert
      color={color}
      sx={{
        mb: 2,
        '& .MuiAlert-message': {
          width: '100%',
        },
        ...sx,
      }}
    >
      <Stack direction="row">
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <CloseIcon sx={{ ml: 2 }} onClick={action} />
      </Stack>
    </Alert>
  );
}
