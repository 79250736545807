import { Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  sx?: SxProps<Theme>;
};

export default function Text(props: Props) {
  const { children, sx } = props;

  return (
    <Typography variant="body1" sx={{ mb: 1, ...sx }}>
      {children}
    </Typography>
  );
}
