import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik, FieldArray } from 'formik';
import { Alert, Backdrop, Card, CircularProgress, Fab, FormControl, Grid, Stack, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { PATH_POLICY } from '../../../routes/paths';
import CountriesSelect from './components/CountrySelect';
import TravelDatesPicker from './components/TravelDatesPicker';
import TourPriceInput from './components/TourPriceInput';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useCalculateOverprotectionPolicyPriceRequest } from 'redux/slices/baseApi';
import { useTranslations } from 'hooks/useTranslations';
import BirthDateInput from './components/BirthDateInput';
import RiskSetSelect, { RiskSetOptions } from './components/RiskSetSelect';
import LifestyleActivitySelect from './components/LifestyleActivitySelect';
import { useFormikProps } from 'utils/ui/formik';
import { getErrorMessage } from 'utils/errors';
import { useDispatch } from 'redux/store';
import {
  updateOverprotectionPaymentTransaction,
  updateOverprotectionPolicy,
  updateOverprotectionPolicyPriceCalculation,
  updateOverprotectionPolicyProcessId,
} from 'redux/slices/policy';
import { OverprotectionPolicyPriceRequest } from 'redux/slices/baseApi/models/requests/overprotection-policy-price.request';
import { useEffect, useState } from 'react';
import SumInsuredCard from './components/SumInsuredCard';
import moment from 'moment';
import { useCurrentRoleId } from 'hooks/user/useCurrentRoleId';
import { DateFormat } from 'utils/ui/dateFormat';

type FormProps = {
  startTravelDate: Date | null;
  endTravelDate: Date | null;
  tourPrice: number;
  travelCountriesIds: number[];
  afterSubmit?: string;

  participants: {
    id: string;
    birthDate: Date | null;
    citizenshipTypeCode: number | null;
    riskSet: string;
    lifestyleActivityTypeCode: number | null;
  }[];
};

const initialValues: FormProps = {
  tourPrice: 0,
  travelCountriesIds: [],
  startTravelDate: null,
  endTravelDate: null,
  participants: [
    {
      id: nanoid(),
      birthDate: null,
      citizenshipTypeCode: null,
      riskSet: RiskSetOptions[0].name,
      lifestyleActivityTypeCode: null,
    },
  ],
};

export default function Covid19PolicyPriceForm() {
  const navigate = useNavigate();
  const [calculatePolicy] = useCalculateOverprotectionPolicyPriceRequest();
  const roleId = useCurrentRoleId();
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const translations = useTranslations();
  const dispatch = useDispatch();

  const requiredFieldMessage = translations.forms.requiredField();

  const PolicyPriceSchema = Yup.object().shape({
    startTravelDate: Yup.date().typeError(requiredFieldMessage).required(requiredFieldMessage),
    endTravelDate: Yup.date().typeError(requiredFieldMessage).required(requiredFieldMessage),
    travelCountriesIds: Yup.array(Yup.number().typeError(requiredFieldMessage))
      .typeError(requiredFieldMessage)
      .min(1, requiredFieldMessage)
      .required(requiredFieldMessage),
    tourPrice: Yup.number().moreThan(0, requiredFieldMessage).required(requiredFieldMessage),
    participants: Yup.array(
      Yup.object().shape({
        birthDate: Yup.date()
          .typeError(translations.forms.invalidBirthDate())
          .min(moment().add(-110, 'years').toDate(), translations.forms.invalidBirthDate())
          .max(new Date(), translations.forms.invalidBirthDate())
          .required(requiredFieldMessage),
        citizenshipTypeCode: Yup.number().typeError(requiredFieldMessage).required(requiredFieldMessage),
        riskSet: Yup.string().required(requiredFieldMessage),
        lifestyleActivityTypeCode: Yup.number().nullable().typeError(requiredFieldMessage),
      }),
    ),
  });

  const formik = useFormik<FormProps>({
    enableReinitialize: true,
    initialValues,
    validationSchema: PolicyPriceSchema,
    onSubmit: async (values, { setErrors }) => {
      setIsLoaderShown(true);

      try {
        const request: OverprotectionPolicyPriceRequest = {
          tourAgentRoleId: roleId,
          effectiveDate: DateFormat.server(values.startTravelDate!),
          terminationDate: DateFormat.server(values.endTravelDate!),
          tourPrice: values.tourPrice,
          travelCountriesIds: values.travelCountriesIds,
          participants: values.participants.map((p) => ({
            citizenshipCountryId: p.citizenshipTypeCode!,
            riskSet: p.riskSet,
            lifestyleActivityTypeCode: p.lifestyleActivityTypeCode!,
            birthDate: DateFormat.server(p.birthDate!),
          })),
        };

        const response = await calculatePolicy(request).unwrap();

        dispatch(updateOverprotectionPolicyPriceCalculation(response.calculation));
        dispatch(updateOverprotectionPolicyProcessId(response.policyProcessId));

        navigate(PATH_POLICY.insurance.travelCancellationInsuranceStep2);
      } catch (error: any) {
        console.error(error);
        setErrors({ afterSubmit: getErrorMessage(error) });
      } finally {
        setIsLoaderShown(false);
      }
    },
  });

  const { values, errors, handleSubmit } = formik;

  const getFormikProps = useFormikProps(formik);

  useEffect(() => {
    dispatch(updateOverprotectionPolicyPriceCalculation(undefined));
    dispatch(updateOverprotectionPolicy(undefined));
    dispatch(updateOverprotectionPolicyProcessId(undefined));
    dispatch(updateOverprotectionPaymentTransaction(undefined));
  }, [dispatch]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ py: 3, px: 3 }}>
              <Typography variant="h6">{translations.policy.travelCancellation.travelDataTitle()}</Typography>

              <TravelDatesPicker
                sx={{ mt: 4 }}
                startDateFormikProps={getFormikProps('startTravelDate')}
                endDateFormikProps={getFormikProps('endTravelDate')}
              />

              <CountriesSelect
                sx={{ mt: 3 }}
                multiple
                label={translations.policy.travelCancellation.visitingCountries()}
                placeholder={translations.forms.chooseCountries()}
                formikProps={getFormikProps('travelCountriesIds')}
              />

              <TourPriceInput sx={{ mt: 3, mb: 2 }} formikProps={getFormikProps('tourPrice')} />
            </Card>
          </Grid>

          <SumInsuredCard participantCount={values.participants.length} tourPrice={values.tourPrice} />

          <Grid item xs={12} md={12}>
            <FieldArray
              name="participants"
              render={(arrayHelpers) => (
                <div>
                  {values.participants.map((participant, index) => (
                    <div key={participant.id}>
                      <Card sx={{ p: 3, pb: 4, mb: 4 }}>
                        <Stack direction="row">
                          <FormControl fullWidth>
                            <Typography variant="h6">Участник {index + 1}</Typography>
                          </FormControl>
                          {values.participants.length > 1 && (
                            <RemoveCircleOutlineIcon onClick={() => arrayHelpers.remove(index)} />
                          )}
                        </Stack>

                        <Stack spacing={3} sx={{ mt: 4 }}>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2, md: 4 }}>
                            <BirthDateInput formikProps={getFormikProps(`participants.${index}.birthDate`)} />
                            <CountriesSelect
                              multiple={false}
                              label={translations.common.citizenship()}
                              placeholder={translations.common.citizenship()}
                              formikProps={getFormikProps(`participants.${index}.citizenshipTypeCode`)}
                            />
                          </Stack>

                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2, md: 4 }}>
                            <RiskSetSelect formikProps={getFormikProps(`participants.${index}.riskSet`)} />
                            <LifestyleActivitySelect
                              formikProps={getFormikProps(`participants.${index}.lifestyleActivityTypeCode`)}
                            />
                          </Stack>
                        </Stack>
                      </Card>
                    </div>
                  ))}

                  {typeof errors.participants === 'string' ? (
                    <Alert severity="error">{errors.participants || errors.afterSubmit}</Alert>
                  ) : null}

                  {typeof errors.afterSubmit === 'string' ? <Alert severity="error">{errors.afterSubmit}</Alert> : null}

                  <Grid sx={{ mt: 3, mx: 2, mb: 6 }}>
                    <Fab
                      variant="extended"
                      color="default"
                      aria-label="add"
                      disabled={values.participants.length >= 5}
                      onClick={() => arrayHelpers.push({ ...initialValues.participants[0], id: nanoid() })}
                    >
                      <ControlPointIcon sx={{ mr: 1 }} />
                      {translations.policy.travelCancellation.addParticipant()}
                    </Fab>
                    <Fab variant="extended" color="primary" aria-label="add" sx={{ ml: 3 }} type="submit">
                      <CalculateIcon sx={{ mr: 1 }} />
                      {translations.policy.travelCancellation.calculatePrice()}
                    </Fab>
                  </Grid>
                </div>
              )}
            />
          </Grid>
        </Grid>
      </Form>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoaderShown}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </FormikProvider>
  );
}
