import Title from '../components/Title';
import Text from '../components/Text';

type Props = {};

export default function Sravni(props: Props) {
  return (
    <div>
      <Title>Условия работы</Title>
      <Text sx={{ mb: 4 }}>
        Оформляя страховой полис для Ваших туристов через наш сервис у компании Сравни, Вы получаете 20% комиссии от
        стоимости страховок, которые поступают на доступный баланс в течении 30 минут после подтверждения факта оплаты
        от Сравни.
      </Text>

      <Title>Плюсы работать со Сравни</Title>
      <Text sx={{ mb: 4 }}>
        Сервис предлагает сравнить цены и наличие разных опций от 18 страховых компаний, с которыми не нужно заключать
        отдельные договоры. Вы получаете доступ сразу ко всем.
      </Text>

      <Title>Что делать если возник страховой случай или хотите уточнить условия?</Title>
      <Text sx={{ mb: 4 }}>
        Общую информацию, правила страхования, порядок выплат и что является страховыми случаями можно посмотреть,
        открыв раздел "Подробнее" -{'>'} "ПРАВИЛА СТРАХОВАНИЯ" у каждой компании. Вопросы по страховым полисам и
        страховым случаям Вы задаете напрямую в страховые компании. Их контакты вы можете найти в страховом полисе, в
        правилах страхования или в общем доступе.
      </Text>

      <Title>Что можно оформить в Сравни?</Title>
      <Text>
        <strong>1. Медицинская страховка (ВЗР) для туристов</strong>
        <br />
        Укажите интересующие параметры страхового полиса, сравните условия, выберите лучшее предложение и купите полис
        за пару минут. Все полисы подойдут для получения визы, однако Вам нужно узнать минимум по страховой сумме для
        страны путешествия и выбрать соответствующий страховой продукт. Обращайте внимание на покрытие лечения COVID-19,
        и что можно выбрать дополнительно галочками - сумму покрытия и включение в полис компенсации пребывания на
        карантине. Так же можно выбрать дополнительные опции нужные именно конкретному туристу (например: беременность,
        солнечные ожоги, аллергические реакции, экстремальные виды спорта и т.д.).
        <br />
        <br />
        <strong>2. Страхование отмены поездки</strong>
        <br />
        Через Сравни вы так же можете застраховать отмену поездки. Здесь очень важно читать условия страхования, в них
        может быть много ограничений, которые помешают Вашему клиенту получить страховую выплату.
        <br />
        <b>Важно:</b>
        <br /> Многие страховые компании покрывают только ФПР туроператора, не включая комиссии турагентов.
        <br />
        <b>Наша рекомендация:</b>
        <br /> Выбирайте продукт ERV. Он уже доказал свою эффективность на рынке.
      </Text>
    </div>
  );
}
