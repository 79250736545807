import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormikProps } from 'utils/ui/formik';
import { useCountriesQuery } from 'redux/slices/baseApi';
import { getCountryCode } from './CountryCodes';
import { CircularProgress, FormControl, Stack } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { Country } from 'types/models/country';

type Props = {
  label: string;
  multiple: boolean;
  disabled?: boolean;
  formikProps: FormikProps;
  placeholder: string;
  initialCountriesIds?: number[];
  sx?: SxProps<Theme>;
};

export default function CountriesSelect(props: Props) {
  const { formikProps, multiple, disabled, sx, label, placeholder } = props;
  const { data, error, isLoading } = useCountriesQuery({});
  const translations = useTranslations();

  const initialCountries: Country[] = (props.initialCountriesIds || [])
    .map((id) => data?.find((c) => c.id === id))
    .filter((c) => c)
    .map((c) => c!);

  const autocompleteProps = props.initialCountriesIds ? { value: initialCountries } : {};

  return (
    <FormControl fullWidth>
      <Stack direction="row">
        <Autocomplete
          {...autocompleteProps}
          multiple={multiple || false}
          disabled={disabled || isLoading}
          fullWidth
          id={formikProps.id}
          options={data || []}
          getOptionLabel={(option) => option.travelCountryRus}
          filterSelectedOptions
          onChange={(event: any, value: any) => {
            const newValue = multiple ? (value || []).map((c: any) => c.id) : value?.id || null;

            formikProps.setFieldValue(newValue);
          }}
          sx={sx}
          renderOption={(props, option) => {
            const countryCode = getCountryCode(option.travelCountryEn);

            return (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={countryCode && `https://flagcdn.com/w20/${countryCode}.png`}
                  srcSet={countryCode && `https://flagcdn.com/w40/${countryCode}.png 2x`}
                  alt=""
                />
                {option.travelCountryRus}
              </Box>
            );
          }}
          renderInput={(params) => (
            <form autoComplete={'countries'}>
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <TextField
                  {...params}
                  {...formikProps.fieldProps}
                  label={label}
                  placeholder={placeholder}
                  error={formikProps.hasError || !!error}
                  helperText={formikProps.helperText || (error && translations.common.requestError())}
                />

                {isLoading && <CircularProgress color="secondary" sx={{ mt: 0, ml: 2 }} />}
              </Stack>
            </form>
          )}
        />
      </Stack>
    </FormControl>
  );
}
