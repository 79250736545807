import Title from '../components/Title';
import Text from '../components/Text';
import { Link } from '@mui/material';

type Props = {};

export default function GettingStarted(props: Props) {
  return (
    <div>
      <Title>Что такое TourAgent Club?</Title>
      <Text sx={{ mb: 4 }}>
        TourAgent Club - это сервис для продвинутых турагентов, который позволяет предлагать больше нужных услуг
        туристам и зарабатывать вознаграждения за продажи, бронирования и т.д. Мы являемся агрегатором услуг наших
        партнеров, связывая их продукты с турменеджером.
      </Text>

      <Title>Нужно ли заключать бумажный договор с TourAgent Club?</Title>
      <Text>
        Нет, подписывать ничего не надо. Мы работаем по электронной публичной оферте, которую каждый партнёр принимает
        при регистрации в TourAgent Club. Это полностью отвечает действующему законодательству (
        <Link href="http://www.consultant.ru/document/cons_doc_LAW_5142/912ecd30f8655752c373ce4bde835b3832154b11/">
          статьи 438 ГК РФ
        </Link>
        ) и не требует от партнёра дополнительных действий, даже если он работает в качестве физлица, самозанятого, ИП
        или ООО.
      </Text>
    </div>
  );
}
