import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PolicyState } from '../../../types/states/policy-state';
import { OverprotectionPolicyCalculation } from '../../../types/models/overprotection-policy-calculation';
import { OverprotectionPolicy } from '../../../types/models/overprotection-policy';
import { OverprotectionPaymentTransaction } from '../../../types/models/overprotection-payment-transaction';

const initialState: PolicyState = {
  overprotectionPolicyProcessId: undefined,
  overprotectionCalculation: undefined,
  overprotectionPolicy: undefined,
  overprotectionPaymentTransaction: undefined,
};

const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    updateOverprotectionPolicyPriceCalculation(
      state,
      action: PayloadAction<OverprotectionPolicyCalculation | undefined>,
    ) {
      state.overprotectionCalculation = action.payload;
    },

    updateOverprotectionPolicy(state, action: PayloadAction<OverprotectionPolicy | undefined>) {
      state.overprotectionPolicy = action.payload;
    },

    updateOverprotectionPolicyProcessId(state, action: PayloadAction<number | undefined>) {
      state.overprotectionPolicyProcessId = action.payload;
    },

    updateOverprotectionPaymentTransaction(state, action: PayloadAction<OverprotectionPaymentTransaction | undefined>) {
      state.overprotectionPaymentTransaction = action.payload;
    },
  },
});

export const {
  updateOverprotectionPolicyPriceCalculation,
  updateOverprotectionPolicy,
  updateOverprotectionPolicyProcessId,
  updateOverprotectionPaymentTransaction,
} = policySlice.actions;

export default policySlice.reducer;
