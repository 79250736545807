import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslations } from 'hooks/useTranslations';
import { PaymentMethod } from './payment-method';
import WithdrawInfo from './WithdrawInfo';
import { LoadingButton } from '@mui/lab';
import { Divider, Stack } from '@mui/material';

type Props = {
  paymentMethod: PaymentMethod;
  children: React.ReactNode;
  isLoading: boolean;
  canWithdraw: boolean;
  withdraw: () => Promise<any>;
};

export default function ConfirmWithdrawDialog(props: Props) {
  const { paymentMethod, withdraw, canWithdraw, isLoading, children } = props;
  const [open, setOpen] = React.useState(false);
  const translations = useTranslations();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false);
    }
  };

  const confirm = async () => {
    await withdraw();
    setOpen(false);
  };

  return (
    <div>
      <Stack style={{ width: '100%' }} onClick={canWithdraw ? handleClickOpen : undefined}>
        {children}
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translations.account.withdrawalConfirmation()}</DialogTitle>
        <DialogContent sx={{ pb: 1 }}>
          <Stack sx={{ minWidth: 500 }}>
            <Divider sx={{ mt: 1.75, mb: 3 }} />
            <WithdrawInfo paymentMethod={paymentMethod} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            {translations.common.cancel()}
          </Button>
          <LoadingButton variant="contained" onClick={confirm} loading={isLoading}>
            {translations.common.confirm()}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
