import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useMemo } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { SxProps, Theme } from '@mui/system';

type ActionType = 'delete';
type AgreeButtonColor = 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

type Props = {
  children: React.ReactNode;
  action: ActionType;
  dialogTitle: string;
  dialogDescription: string;
  sx?: SxProps<Theme>;
  isDisabled?: boolean;
  onConfirm(): void;
};

export const AlertDialogButton: FC<Props> = (props: Props) => {
  const { children, action, dialogTitle, dialogDescription, sx, onConfirm } = props;
  const [open, setOpen] = React.useState(false);
  const translations = useTranslations();

  const buttonParams: { color: AgreeButtonColor; title: string } = useMemo(() => {
    switch (action) {
      case 'delete':
        return { title: translations.common.delete(), color: 'error' };
    }
  }, [action, translations]);

  const cancel = () => {
    setOpen(false);
  };

  const confirm = () => {
    setOpen(false);
    onConfirm();
  };

  return (
    <div>
      <Button
        variant="contained"
        color={buttonParams.color}
        sx={sx}
        onClick={() => setOpen(true)}
        disabled={props.isDisabled === true}
      >
        {children}
      </Button>

      <Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText id="alert-dialog-description">{dialogDescription}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="info">
            {translations.common.cancel()}
          </Button>
          <Button onClick={confirm} autoFocus variant="contained" color={buttonParams.color}>
            {buttonParams.title}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
