import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import MIconButton from 'components/@material-extend/MIconButton';
import { useSnackbar } from 'notistack';

export const useSuccessSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (message: string) =>
    enqueueSnackbar(message, {
      variant: 'success',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      ),
    });
};
