import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Link, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { PATH_PROFILE } from '../../routes/paths';
import MyAvatar from '../../components/MyAvatar';
import { useRoleDescription } from 'hooks/user/useRoleDescription';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import AccountSelectorDialog from './AccountSelectorDialog';
import { useTranslations } from 'hooks/useTranslations';
import { useServerProfile } from 'hooks/user/useServerProfile';

type Props = {
  isCollapse: boolean;
};

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

export default function SidebarAccountWidget(props: Props) {
  const { isCollapse } = props;
  const { profile } = useAuth();
  const role = useCurrentRole();
  const tourAgency = role.tourAgency?.name || '';
  const roleDescription = useRoleDescription();
  const roleCount = profile?.roles?.length || 0;
  const translations = useTranslations();

  useServerProfile();

  if (isCollapse)
    return (
      <AccountSelectorDialog>
        <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
      </AccountSelectorDialog>
    );

  return (
    <Link underline="none" component={RouterLink} to={PATH_PROFILE.profile.edit}>
      <AccountStyle>
        <MyAvatar />
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {profile?.firstName || ''} {profile?.lastName || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 11 }}>
            {roleDescription}
          </Typography>
          {tourAgency && (
            <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 10 }}>
              {tourAgency}
            </Typography>
          )}
          {roleCount > 1 && (
            <AccountSelectorDialog>
              <Button fullWidth size="small" variant="outlined" sx={{ mt: 1, fontSize: 11, textTransform: 'none' }}>
                {translations.common.changeAccount()}
              </Button>
            </AccountSelectorDialog>
          )}
        </Box>
      </AccountStyle>
    </Link>
  );
}
