import { Box, Stack, Paper, Typography } from '@mui/material';
import { UserCreditCard } from 'types/models/user-credit-card';

type Props = {
  card: UserCreditCard;
  selectedCardId: number | null;
  selectCard(cardId: number): void;
};

export default function UserCardView(props: Props) {
  const { card, selectedCardId, selectCard } = props;

  return (
    <Paper
      key={card.id}
      onClick={() => selectCard(card.id)}
      sx={{
        p: 3,
        maxWidth: '300px',
        position: 'relative',
        border: (theme) => {
          const isSelected = card.id === selectedCardId;
          const borderColor = isSelected ? theme.palette.primary.main : theme.palette.grey[50032];
          const borderWidth = isSelected ? 2 : 1;

          return `solid ${borderWidth}px ${borderColor}`;
        },
      }}
    >
      <Stack direction="row">
        <Box component="img" alt="icon" src={card.imageUrl} sx={{ mb: 1, width: 40, height: 40 }} />

        <Stack sx={{ ml: 3 }}>
          <Typography variant="subtitle2">{card.description}</Typography>
          <Typography variant="body1" sx={{ fontSize: 14 }}>
            {card.displayName}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}
