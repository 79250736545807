import { useMemo } from 'react';
import { useProfile } from 'hooks/user/useProfile';
import { useTranslations } from 'hooks/useTranslations';
import { TourAgency } from 'types/models/tour-agency';
import { conditionalItem } from 'utils/conditionalItem';

export const useTourAgencyMissingData = (tourAgency: TourAgency | undefined) => {
  const translations = useTranslations();
  const profile = useProfile();

  const missingData = useMemo(() => {
    if (!tourAgency) return [];

    const strings = translations.common;

    return [
      ...conditionalItem(!tourAgency.ogrn, strings.ogrn()),
      ...conditionalItem(!tourAgency.kpp, strings.kpp()),
      ...conditionalItem(!tourAgency.legalAddress, strings.legalAddress()),
      ...conditionalItem(!tourAgency.bik, strings.bik()),
      ...conditionalItem(!tourAgency.correspondentAccount, strings.correspondentAccount()),
      ...conditionalItem(!tourAgency.paymentAccount, strings.paymentAccount()),
      ...conditionalItem(!profile.phone, strings.contactPhone()),
    ];
  }, [tourAgency, profile, translations]);

  return missingData;
};
