import { TextField } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { useTranslations } from 'hooks/useTranslations';
import { useFormikInputProps } from 'utils/ui/formik';

type Props = {
  sx?: SxProps<Theme>;
  disabled?: boolean;
  size?: 'small' | 'medium';
};

export const EmailInput = (props: Props) => {
  const { sx, size, disabled } = props;
  const translations = useTranslations();
  const { fieldProps, hasError, helperText } = useFormikInputProps('email');

  return (
    <TextField
      {...fieldProps}
      fullWidth
      autoComplete="email"
      type="email"
      size={size || 'small'}
      label={translations.common.email()}
      error={hasError}
      helperText={helperText}
      disabled={disabled}
      sx={sx}
    />
  );
};
