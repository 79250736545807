import { FC, useMemo } from 'react';
import { Avatar, CircularProgress, Stack, styled, Typography, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
  index: number;
  title: string;
  isActive: boolean;
  showLoader?: boolean;
  isStepCompleted?: boolean;
};

const StepNumber = (props: { step: number; isActive: boolean }) => {
  const { step, isActive } = props;
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const ActiveStepNumber = useMemo(() => {
    return styled(Avatar)`
      color: white;
      font-weight: bold;
      background-color: ${PRIMARY_MAIN};
    `;
  }, [PRIMARY_MAIN]);

  const AvatarComponent = isActive ? ActiveStepNumber : Avatar;
  return <AvatarComponent sx={{ width: 30, height: 30, mr: 2, fontSize: 16 }}>{step}</AvatarComponent>;
};

const PaymentStepTitle: FC<Props> = (props) => {
  const { index, title, isActive, showLoader, isStepCompleted } = props;
  const textColor = !isActive ? 'text.secondary' : undefined;

  return (
    <Stack direction="row">
      <StepNumber step={index} isActive={isActive} />
      <Typography variant="h6" color={textColor} sx={{ mt: 0.18 }}>
        {title}
      </Typography>
      {showLoader && <CircularProgress color="info" size={28} sx={{ ml: 2 }} />}
      {isStepCompleted && <CheckIcon color="primary" sx={{ mt: 0.25, ml: 2 }} />}
    </Stack>
  );
};

export default PaymentStepTitle;
