import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import { getErrorMessage } from 'utils/errors';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslations } from 'hooks/useTranslations';
import { SxProps, Theme } from '@mui/system';

type Props = {
  error: any;
  sx?: SxProps<Theme>;
  retryText?: string;
  retry?: () => void;
};

export default function ErrorAlert(props: Props) {
  const { error, retryText, sx, retry } = props;
  const translations = useTranslations();

  return (
    <Alert icon={<ErrorOutlineIcon />} color="error" sx={sx}>
      <Stack>
        <Typography>{getErrorMessage(error)}</Typography>
        {retry && (
          <Box>
            <Button sx={{ mt: 2 }} variant="contained" onClick={retry}>
              {retryText || translations.common.retry()}
            </Button>
          </Box>
        )}
      </Stack>
    </Alert>
  );
}
