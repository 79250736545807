import { Divider, Typography } from '@mui/material';
import { Fragment, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function Title(props: Props) {
  const { children } = props;

  return (
    <Fragment>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {children}
      </Typography>
      <Divider sx={{ my: 2 }} />
    </Fragment>
  );
}
