import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/system';
import { useTranslations } from 'hooks/useTranslations';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { FormikProps } from 'utils/ui/formik';

type Props = {
  sx?: SxProps<Theme>;
  formikProps: FormikProps;
};

const inputPrefix = '₽  ';
const thousandSeparator = ',';

const NumberFormatCustom = React.forwardRef<NumberFormat, Props>(function NumberFormatCustom(props, ref) {
  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      thousandSeparator={thousandSeparator}
      isNumericString={false}
      decimalScale={2}
      prefix={inputPrefix}
      allowNegative={false}
      allowLeadingZeros={false}
    />
  );
});

const getPrice = (value: string) => {
  const price = Number(
    value //
      .replace(inputPrefix, '')
      .replace(new RegExp(`${thousandSeparator}`, 'g'), ''),
  );

  return price;
};

export default function TourPriceInput(props: Props) {
  const translations = useTranslations();
  const { sx, formikProps } = props;
  const [value, setValue] = useState(formikProps.defaultValue.toString());

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);

    const price = getPrice(newValue);
    formikProps.setFieldValue(price);
  };

  return (
    <Box sx={sx}>
      <TextField
        fullWidth
        label={translations.policy.travelCancellation.tourPrice()}
        {...formikProps.fieldProps}
        value={value}
        onChange={onChange}
        onFocus={(e) => {
          if (getPrice(e.target.value) === 0) {
            setValue('');
          }
        }}
        onBlur={(e) => {
          setValue(getPrice(e.target.value));
        }}
        error={formikProps.hasError}
        helperText={formikProps.helperText}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
      />
    </Box>
  );
}
