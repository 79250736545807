import { Container, Grid } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { usePageTitle } from 'hooks/ui/usePageTitle';
import { useTranslations } from 'hooks/useTranslations';
import TourAgencyInsuranceOrderList from 'components/_dashboard/tour-agency/overprotection-policy/TourAgencyInsuranceOrderList';
import TourAgencyTotalBalanceCounter from 'components/_dashboard/tour-agency/balance/TourAgencyTotalBalanceCounter';
import TourAgencyAvailableBalanceCounter from 'components/_dashboard/tour-agency/balance/TourAgencyAvailableBalanceCounter';
import SravniPolicyOrderList from 'components/_dashboard/sravni-vzr-policy/SravniPolicyOrderList';

export default function TourAgencyDashboardPage() {
  const { themeStretch } = useSettings();
  const translations = useTranslations();
  const pageTitle = usePageTitle(translations.tourAgencyDashboard.title());

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TourAgencyTotalBalanceCounter />
          </Grid>

          <Grid item xs={12} md={6}>
            <TourAgencyAvailableBalanceCounter />
          </Grid>

          <Grid item xs={12} md={12} sx={{ mt: 1 }}>
            <SravniPolicyOrderList mode={'tour-agency'} />
          </Grid>

          <Grid item xs={12} md={12}>
            <TourAgencyInsuranceOrderList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
