import { Card } from '@mui/material';
import Subtitle from 'components/text/Subtitle';
import { useProfile } from 'hooks/user/useProfile';
import { useTranslations } from 'hooks/useTranslations';
import { TourAgency } from 'types/models/tour-agency';
import DataItem from '../common/DataItem';

type Props = {
  tourAgency: TourAgency;
};

export default function TourAgencyVerificationView(props: Props) {
  const { tourAgency } = props;
  const translations = useTranslations();
  const profile = useProfile();
  const strings = translations.common;

  return (
    <Card sx={{ p: 3 }}>
      <Subtitle>{translations.account.checkTourAgencyData()} </Subtitle>

      <DataItem title={strings.ogrn()} value={tourAgency.ogrn} />
      <DataItem title={strings.kpp()} value={tourAgency.kpp} />
      <DataItem title={strings.legalAddress()} value={tourAgency.legalAddress} />
      <DataItem title={strings.bik()} value={tourAgency.bik} />
      <DataItem title={strings.correspondentAccount()} value={tourAgency.correspondentAccount} />
      <DataItem title={strings.paymentAccount()} value={tourAgency.paymentAccount} />
      <DataItem title={strings.contactPhone()} value={profile.phone} />
    </Card>
  );
}
