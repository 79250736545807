import { TextField } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { useFormikInputProps } from 'utils/ui/formik';

type Props = {
  name: string;
  digitCount: number;
  label: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  size?: 'small' | 'medium';
};

export const NumberIdentificatorInput = (props: Props) => {
  const { name, digitCount, label, sx, size, disabled } = props;
  const { fieldProps, setFieldValue, hasError, helperText } = useFormikInputProps(name);

  const [identificator, setIdentificator] = useState(fieldProps.value);

  const handleChange = useCallback(
    (e: any) => {
      const inputValue: string = e.target.value;
      const onlyNumbers = inputValue.replace(/[^0-9]/g, '').slice(0, digitCount);

      setIdentificator(onlyNumbers);
      setFieldValue(onlyNumbers);
    },
    [digitCount, setIdentificator, setFieldValue],
  );

  useEffect(() => {
    if (!identificator && fieldProps.value !== identificator) {
      setIdentificator(fieldProps.value);
    }
  }, [identificator, fieldProps.value]);

  return (
    <TextField
      {...fieldProps}
      sx={sx}
      size={size || 'small'}
      disabled={disabled}
      label={label}
      variant="outlined"
      fullWidth
      value={identificator}
      onChange={handleChange}
      error={hasError}
      helperText={helperText}
    />
  );
};
