import React from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslations } from 'hooks/useTranslations';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

type Props = {};

export default function SravniNewPolicyButton(props: Props) {
  const translations = useTranslations();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ position: 'fixed', right: 0, bottom: 0, height: 80, width: '100%' }}>
      <Box
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          height: 80,
          left: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
        }}
      ></Box>
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        sx={{ textTransform: 'none', position: 'fixed', right: 16, bottom: 16, height: 48 }}
        onClick={handleClickOpen}
      >
        <AddIcon />
        {translations.common.newPolicy()}
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translations.common.newPolicy()}</DialogTitle>
        <DialogContent sx={{ mb: 0, pb: 0 }}>
          <Alert color="warning" sx={{ mt: 2 }} icon={<PriorityHighIcon />}>
            <b>{translations.common.warning()}:</b>
            <br />
            {translations.vzr.progressWillBeReset()}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translations.common.cancel()}</Button>
          <Button
            onClick={() => {
              handleClose();
              window.location.reload();
            }}
            variant="outlined"
          >
            {translations.common.create()}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
