import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Tooltip, Typography, Button, Stack, CircularProgress } from '@mui/material';
import Label from '../../Label';
import { rubPriceFormatter } from 'utils/money';
import { useTranslations } from 'hooks/useTranslations';
import { DateFormat } from 'utils/ui/dateFormat';
import { SravniVzrPolicy } from 'types/models/sravni-policy';
import { useMemo } from 'react';
import { useCloudStorageLink } from 'hooks/utils/useCloudStorageLink';

type DisplayMode = 'tour-agent' | 'tour-agency';

type Props = {
  item: SravniVzrPolicy;
  mode: DisplayMode;
};

export const SravniPolicyOrderItem = ({ item, mode }: Props) => {
  const theme = useTheme();
  const translations = useTranslations();
  const isCreditedToAccount = true;

  const statusTableCell = (
    <TableCell>
      <Label
        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
        color={isCreditedToAccount ? 'primary' : 'info'}
      >
        {isCreditedToAccount
          ? translations.dashboard.creditedToAccount()
          : translations.dashboard.waitingToCreditToAccount()}
      </Label>
    </TableCell>
  );

  const managerName = useMemo(() => {
    const getFirstLetter = (name?: string) => {
      const letter = (name || '').slice(0, 1).toUpperCase();
      return letter ? `${letter}.` : '';
    };

    const initials = getFirstLetter(item.managerFirstName) + getFirstLetter(item.managerMiddleName);

    return `${item.managerLastName || ''} ${initials}`;
  }, [item]);

  return (
    <TableRow key={item.conversionId}>
      {mode === 'tour-agency' && (
        <TableCell>
          <Typography variant="subtitle2">{managerName}</Typography>
        </TableCell>
      )}
      <TableCell>
        <Typography variant="subtitle2">{item.destination || ''}</Typography>
      </TableCell>
      <TableCell>{rubPriceFormatter.format(item.price)}</TableCell>

      {isCreditedToAccount ? (
        statusTableCell
      ) : (
        <Tooltip
          title={
            <Typography variant="body2" sx={{ m: 1 }}>
              {translations.dashboard.willBeCredited() + DateFormat.long(item.createdAt)}
            </Typography>
          }
        >
          {statusTableCell}
        </Tooltip>
      )}

      <TableCell>{rubPriceFormatter.format(item.rewardValue)}</TableCell>

      <TableCell>
        <Typography variant="subtitle2">{item.createdAt ? DateFormat.short(item.createdAt) : ''}</Typography>
      </TableCell>

      <TableCell>
        <Stack direction="row" spacing={1}>
          <DocButton
            link={item.receiptUrl}
            title={translations.common.check()}
            noDocText={translations.vzr.checkIsNotAvailable()}
          />
          <CloudStorageLink
            link={item.policyFileUrl}
            title={translations.common.policy()}
            noDocText={translations.vzr.policyIsNotAvailable()}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const DocButton = (props: { link: string; title: string; noDocText: string }) => {
  const { link, title, noDocText } = props;

  const button = (
    <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <Button variant="outlined" color="info" size="small" disabled={!link}>
        {title}
      </Button>
    </a>
  );

  if (!link) {
    const checkNotAvailableTooltipText = (
      <Typography variant="body2" sx={{ m: 1 }}>
        {noDocText}
      </Typography>
    );

    return <Tooltip title={checkNotAvailableTooltipText}>{button}</Tooltip>;
  }

  return button;
};

const CloudStorageLink = (props: { link: string; title: string; noDocText: string }) => {
  const { link, title, noDocText } = props;
  const { openLink, isLoading } = useCloudStorageLink();

  const button = (
    <Button variant="outlined" color="info" size="small" onClick={() => openLink(link)} disabled={isLoading}>
      {isLoading ? <CircularProgress size={24} /> : title}
    </Button>
  );

  if (!link) {
    const checkNotAvailableTooltipText = (
      <Typography variant="body2" sx={{ m: 1 }}>
        {noDocText}
      </Typography>
    );

    return <Tooltip title={checkNotAvailableTooltipText}>{button}</Tooltip>;
  }

  return button;
};
