import { Stack } from '@mui/material';
import { useTourAgencyQuery, useWithdrawLtdRewardsRequest } from 'redux/slices/baseApi';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import CheckingStatusCard from './components/CheckingContractorStatusCard';
import FillTourAgencyDataView from './components/tour-agency/FillTourAgencyDataView';
import { useTourAgencyMissingData } from 'hooks/tour-agency/useTourAgencyMissingData';
import TourAgencyVerificationView from './components/tour-agency/TourAgencyVerificationView';
import WithdrawAmountAlert from './components/common/WithdrawAmountAlert';
import { useTourAgencyBalance } from 'hooks/tour-agency/useTourAgencyBalance';
import WithdrawalButton from './components/common/WithdrawalButton';
import { PaymentMethod } from './components/common/payment-method';
import { useCallback } from 'react';
import { useWithdrawalResultHandler } from 'hooks/ui/useWithdrawalRequestHandler';

type TourAgencyPayoutProps = {
  goToProfileEditing: () => void;
  goToTourAgencyDataEditing: () => void;
};

export default function TourAgencyPayout(props: TourAgencyPayoutProps) {
  const { goToProfileEditing, goToTourAgencyDataEditing } = props;

  const role = useCurrentRole();
  const { data: tourAgency, isLoading: isLoadingTourAgency } = useTourAgencyQuery({
    roleId: role.id,
    tourAgencyId: role.tourAgency?.id || 0,
  });

  const missingData = useTourAgencyMissingData(tourAgency);
  const { isBalanceLoading, canPayout } = useTourAgencyBalance();
  const [withdraw, { isLoading }] = useWithdrawLtdRewardsRequest();

  const handleWithdrawalResult = useWithdrawalResultHandler();
  const withdrawReward = useCallback(async () => {
    if (!tourAgency) return;

    await handleWithdrawalResult(withdraw({ tourAgencyId: tourAgency.id, roleId: role.id }));
  }, [tourAgency, handleWithdrawalResult, withdraw, role.id]);

  if (!tourAgency || isLoadingTourAgency) {
    return <CheckingStatusCard type={'ltd'} />;
  }

  if (missingData.length > 0) {
    return (
      <FillTourAgencyDataView
        tourAgency={tourAgency}
        goToTourAgencyDataEditing={goToTourAgencyDataEditing}
        goToProfileEditing={goToProfileEditing}
      />
    );
  }

  const paymentMethod: PaymentMethod = 'ltd-payment';

  return (
    <Stack>
      <TourAgencyVerificationView tourAgency={tourAgency} />
      <WithdrawAmountAlert paymentMethod={paymentMethod} sx={{ mt: 3 }} />

      <WithdrawalButton
        canWithdraw={!isLoadingTourAgency && canPayout}
        isLoading={isLoading || isBalanceLoading}
        withdraw={withdrawReward}
        paymentMethod={paymentMethod}
      />
    </Stack>
  );
}
