import { Card, Stack, Typography } from '@mui/material';
import { useCurrentRoleId } from 'hooks/user/useCurrentRoleId';
import { useTranslations } from 'hooks/useTranslations';
import { useTourAgentBalanceQuery } from 'redux/slices/baseApi';
import { rubBalanceFormatter } from 'utils/money';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './AvailableBalanceCounter.css';
import { PATH_PROFILE } from 'routes/paths';
import ClickableArea from 'components/containers/ClickableArea';

export default function AvailableBalanceCounter() {
  const roleId = useCurrentRoleId();
  const { data } = useTourAgentBalanceQuery(roleId, {
    refetchOnMountOrArgChange: true,
  });
  const translations = useTranslations();

  return (
    <Card>
      <ClickableArea
        link={PATH_PROFILE.profile.payout}
        sx={{ width: '100%', display: 'flex', alignItems: 'center', color: 'text.primary' }}
      >
        <Stack direction="row" sx={{ alignItems: 'center', p: 3, width: '100%' }}>
          <Stack sx={{ display: 'flex', flexGrow: 1 }}>
            <Typography variant="subtitle2">{translations.dashboard.availableBalance()}</Typography>
            <Typography variant="h3">{rubBalanceFormatter.format(data?.availableRewardValue || 0)}</Typography>
          </Stack>
          <NavigateNextIcon
            className="available-balance-arrow"
            sx={{ width: 32, height: 32, color: 'text.secondary' }}
          />
        </Stack>
      </ClickableArea>
    </Card>
  );
}
