import { Container } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { useTranslations } from 'hooks/useTranslations';
import CovidPolicyStep2Form from 'components/insurance/step-2/CovidPolicyStep2Form';
import { TravelCancellationPolicyStepper } from 'components/insurance/components/TravelCancellationPolicyStepper';

export default function TravelCancellationInsuranceStep2() {
  const translations = useTranslations();
  const { themeStretch } = useSettings();

  return (
    <Page title={translations.policy.travelCancellation.title()}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <TravelCancellationPolicyStepper heading={translations.policy.travelCancellation.title()} currentStep={1} />

        <CovidPolicyStep2Form />
      </Container>
    </Page>
  );
}
