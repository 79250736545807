import { Icon } from '@iconify/react';
import { useState, useEffect, useMemo, ReactNode, ReactElement } from 'react';
// import roundVpnKey from '@iconify/icons-ic/round-vpn-key';
import roundReceipt from '@iconify/icons-ic/round-receipt';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import { Container, Tab, Box, Tabs } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { useTranslations } from 'hooks/useTranslations';
import { usePageTitle } from 'hooks/ui/usePageTitle';
import { useQueryParam } from 'hooks/utils/useQueryParam';
import { PATH_PROFILE } from 'routes/paths';
import AccountGeneral from 'components/_dashboard/user/account/AccountGeneral';
import { conditionalItem } from 'utils/conditionalItem';
import { useCurrentRolePermissions } from 'hooks/user/useCurrentRolePermissions';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import HistoryIcon from '@mui/icons-material/History';
import TourAgencyData from 'components/_dashboard/user/account/TourAgencyData';
import TourAgencyPayout from 'components/_dashboard/user/account/payout/TourAgencyPayout';
import TourAgentPayout from 'components/_dashboard/user/account/payout/TourAgentPayout';
import WithdrawalHistory from 'components/_dashboard/user/account/WithdrawalHistory';

type TabId = 'general' | 'tour-agency' | 'payout' | 'withdrawal-history';

type TabItem = {
  value: TabId;
  link: string;
  title: string;
  icon: ReactElement;
  component: ReactNode;
};

export default function AccountPage() {
  const { themeStretch } = useSettings();
  const translations = useTranslations();
  const pageTitle = usePageTitle(translations.account.title());
  const initialTabParam = useQueryParam('t');
  const permissions = useCurrentRolePermissions();

  const [currentTab, setCurrentTab] = useState('general');

  const TABS = useMemo(() => {
    const tabs: TabItem[] = [
      {
        value: 'general',
        link: PATH_PROFILE.profile.edit,
        title: translations.account.profile(),
        icon: <Icon icon={roundAccountBox} width={20} height={20} />,
        component: <AccountGeneral />,
      },
      ...conditionalItem<TabItem>(permissions.isLtdOwner, {
        value: 'tour-agency',
        link: PATH_PROFILE.profile.tourAgency,
        title: translations.account.tourAgencyInfo(),
        icon: <CorporateFareIcon width={20} height={20} />,
        component: <TourAgencyData />,
      }),
      ...conditionalItem<TabItem>(permissions.canPayoutThroughJumpFinance, {
        value: 'payout',
        link: PATH_PROFILE.profile.payout,
        title: translations.account.payout(),
        icon: <Icon icon={roundReceipt} width={20} height={20} />,
        component: <TourAgentPayout goToProfileEditing={() => setCurrentTab('general')} />,

        // <TourAgentPayout goToProfileEditing={() => setCurrentTab('general')} />,
      }),
      ...conditionalItem<TabItem>(permissions.isLtdOwner, {
        value: 'payout',
        link: PATH_PROFILE.profile.payout,
        title: translations.account.payout(),
        icon: <Icon icon={roundReceipt} width={20} height={20} />,
        component: (
          <TourAgencyPayout
            goToProfileEditing={() => setCurrentTab('general')}
            goToTourAgencyDataEditing={() => setCurrentTab('tour-agency')}
          />
        ),
      }),

      ...conditionalItem<TabItem>(permissions.canPayout, {
        value: 'withdrawal-history',
        link: PATH_PROFILE.profile.withdrawalHistory,
        title: translations.account.withdrawalHistoryTab(),
        icon: <HistoryIcon width={20} height={20} />,
        component: <WithdrawalHistory />,
      }),

      // {
      //   value: 'change_password',
      //   icon: <Icon icon={roundVpnKey} width={20} height={20} />,
      //   component: <AccountChangePassword />,
      // },
    ];

    return tabs;
  }, [translations, permissions]);

  useEffect(() => {
    console.log('initialTabParam', initialTabParam);

    const isTabParamValid = TABS.some((t) => t.value === initialTabParam);
    if (!isTabParamValid) {
      setCurrentTab('general');
      return;
    }

    setCurrentTab(initialTabParam);
  }, [TABS, initialTabParam, setCurrentTab]);

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              disableRipple
              component={RouterLink}
              label={tab.title}
              icon={tab.icon}
              to={tab.link}
              value={tab.value}
              sx={{ textTransform: 'none' }}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
