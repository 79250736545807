import { useMemo } from 'react';
import { Alert, FormControl, FormHelperText, MenuItem, Select, Typography } from '@mui/material';
import { useRolesOptions } from 'hooks/user/useRolesOptions';
import { SxProps, Theme } from '@mui/system';
import { useFormikInputProps } from 'utils/ui/formik';

type Props = {
  sx?: SxProps<Theme>;
};

export default function RoleTypeInput(props: Props) {
  const { sx } = props;
  const rolesOptions = useRolesOptions();
  const { fieldProps, value, hasError, helperText } = useFormikInputProps('roleType');

  const currentRoleOption = useMemo(() => {
    return rolesOptions.find((r) => r.roleType === value);
  }, [rolesOptions, value]);

  return (
    <FormControl fullWidth sx={sx}>
      <Select {...fieldProps} labelId="role-label" id="role-type-select">
        {rolesOptions.map(({ roleName, roleType }) => (
          <MenuItem key={roleType} value={roleType}>
            {roleName}
          </MenuItem>
        ))}
      </Select>

      {hasError && helperText && <FormHelperText color="error">{helperText}</FormHelperText>}

      <Alert color="info" icon={false} sx={{ mt: 2 }}>
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
          {currentRoleOption?.roleName}:
        </Typography>
        {currentRoleOption?.rights.map((right) => (
          <Typography key={right}>{right}</Typography>
        ))}
      </Alert>
    </FormControl>
  );
}
