import moment from 'moment';
import { useMemo } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import useLocales from 'hooks/useLocales';

export const useFromDateToDateString = (date1?: Date, date2?: Date) => {
  const {
    currentLang: { value },
  } = useLocales();
  const translations = useTranslations();

  const string = useMemo(() => {
    if (!date1 || !date2) return '';

    const day1 = date1.getDate();
    const day2 = date2.getDate();

    const month1 = date1.getMonth();
    const month2 = date2.getMonth();

    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();

    const months = [
      'января',
      'февраля',
      'марта',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ];

    const getMonth = (date: Date) =>
      value === 'ru' ? months[date.getMonth()] : moment(date).locale(value).format('MMMM').toLowerCase();

    const month1Word = getMonth(date1);
    const month2Word = getMonth(date2);

    const from = translations.common.from();
    const to = translations.common.to();
    const yearWord = translations.common.year();

    const isSameYear = year1 === year2;
    const isSameMonthOfTheYear = isSameYear && month1 === month2;

    if (isSameMonthOfTheYear) {
      return `${from} ${day1} ${to} ${day2} ${month1Word} ${year1} ${yearWord}`;
    }

    if (isSameYear) {
      return `${from} ${day1} ${month1Word} ${to} ${day2} ${month2Word} ${year1} ${yearWord}`;
    }

    return `${from} ${day1} ${month1Word} ${year1} ${to} ${day2} ${month2Word} ${year2} ${yearWord}`;
  }, [date1, date2, translations, value]);

  return string;
};
