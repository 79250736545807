import { Container } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import { useTranslations } from 'hooks/useTranslations';
import CovidPolicyStep4 from 'components/insurance/step-4/CovidPolicyStep4';
import { TravelCancellationPolicyStepper } from 'components/insurance/components/TravelCancellationPolicyStepper';

export default function TravelCancellationInsuranceStep4() {
  const translations = useTranslations();
  const { themeStretch } = useSettings();

  return (
    <Page title={translations.policy.travelCancellation.title()}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <TravelCancellationPolicyStepper heading={translations.policy.travelCancellation.title()} currentStep={3} />

        <CovidPolicyStep4 />
      </Container>
    </Page>
  );
}
