import { Box, SxProps, Theme } from '@mui/system';
import { Link } from 'react-router-dom';
import './ClickableArea.css';

type Props = {
  children: React.ReactNode;
  link: string;
  sx?: SxProps<Theme>;
};

export default function ClickableArea(props: Props) {
  const { children, link, sx } = props;

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Box className="clickable-area" sx={sx}>
        {children}
      </Box>
    </Link>
  );
}
