import { Theme } from '@mui/material/styles';
import { Card, Stack, CircularProgress, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import Subtitle from 'components/text/Subtitle';
import { useTranslations } from 'hooks/useTranslations';
import ErrorAlert from 'components/alerts/ErrorAlert';
import UserCardView from './UserCardView';
import CenterBox from 'components/containers/CenterBox';
import { useUserRequisitesQuery } from 'redux/slices/baseApi';
import { useEffect } from 'react';
import { useCurrentRole } from 'hooks/user/useCurrentRole';

type AccountBillingPaymentMethodProps = {
  sx?: SxProps<Theme>;

  selectedCardId: number | null;
  selectCard(cardId: number): void;
};

export default function PayoutRequisitesView(props: AccountBillingPaymentMethodProps) {
  const { selectedCardId, selectCard, sx } = props;
  const role = useCurrentRole();
  const { data: userCards = [], isLoading, error } = useUserRequisitesQuery(role.id);
  const translations = useTranslations();

  useEffect(() => {
    if (userCards.length > 0) {
      selectCard(userCards[0].id);
    }
  }, [userCards, selectCard]);

  return (
    <Card sx={{ p: 3, ...sx }}>
      <Subtitle>{translations.account.selectRequisites()}</Subtitle>

      {error && <ErrorAlert error={error} />}
      {isLoading && (
        <CenterBox sx={{ minHeight: 150 }}>
          <CircularProgress />
        </CenterBox>
      )}

      {userCards.length === 0 ? (
        <CenterBox>
          <Stack sx={{ textAlign: 'center', maxWidth: 400 }}>
            <Typography>
              <b>{translations.account.noUserRequisites()}</b>
            </Typography>
            <Typography>{translations.account.addUserRequisites()}</Typography>
          </Stack>
        </CenterBox>
      ) : (
        <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
          {userCards.map((card) => (
            <UserCardView key={card.id} card={card} selectedCardId={selectedCardId} selectCard={selectCard} />
          ))}
        </Stack>
      )}
    </Card>
  );
}
