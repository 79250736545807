import { useSelector } from 'redux/store';

export const useCurrentRole = () => {
  const currentRole = useSelector((s) => s.profile.currentRole);

  if (!currentRole) {
    throw new Error('User not authorized');
  }

  return currentRole;
};
