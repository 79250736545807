import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Divider,
} from '@mui/material';
import Scrollbar from '../../../Scrollbar';
import { useNotCompletedOverprotectionPoliciesQuery } from 'redux/slices/baseApi';
import { Fragment } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { useCurrentRoleId } from 'hooks/user/useCurrentRoleId';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { NotCompletedInsuranceItem } from './NotCompletedInsuranceOrderItem';

export default function NotCompletedInsuranceOrderList() {
  const roleId = useCurrentRoleId();
  const { data = [] } = useNotCompletedOverprotectionPoliciesQuery(roleId);
  const translations = useTranslations();

  if (data.length === 0) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <Divider sx={{ mt: 2, mb: 4 }} />
      <Card sx={{ pb: 3 }}>
        <CardHeader
          avatar={<PendingActionsIcon />}
          title={translations.dashboard.notCompletedPolicies()}
          sx={{ mb: 3 }}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{translations.common.client()}</TableCell>
                  <TableCell>{translations.common.status()}</TableCell>
                  <TableCell>{translations.common.issueDate()}</TableCell>
                  <TableCell>{translations.common.actions()}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((policyData) => (
                  <NotCompletedInsuranceItem key={policyData.id} policyProcess={policyData} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <Divider sx={{ mt: 5, mb: 1.5 }} />
    </Fragment>
  );
}
