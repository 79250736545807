import ReactMarkdown from 'react-markdown';
import './Markdown.css';

type Props = {
  content: string;
};

export default function Markdown(props: Props) {
  const { content } = props;
  return <ReactMarkdown className="markdown">{content}</ReactMarkdown>;
}
