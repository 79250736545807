/* eslint-disable */

/// THIS IS GENERATED CODE.
/// DO NOT EDIT THIS FILE MANUALLY.

import { useMemo } from 'react';
import { TFunction, TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

const getTranslations = (translate: TFunction) => {
  const translator = (key: string) => {
    return (options?: TOptions | string) => {
      return translate(key, options);
    };
  };

  return {
    product: {
      name: translator('product.name')
    },
    demo: {
      title: translator('demo.title')
    },
    common: {
      firstName: translator('common.firstName'),
      lastName: translator('common.lastName'),
      patronymic: translator('common.patronymic'),
      mobilePhone: translator('common.mobilePhone'),
      email: translator('common.email'),
      password: translator('common.password'),
      inn: translator('common.inn'),
      repeatPassword: translator('common.repeatPassword'),
      register: translator('common.register'),
      from: translator('common.from'),
      to: translator('common.to'),
      and: translator('common.and'),
      year: translator('common.year'),
      signIn: translator('common.signIn'),
      signUp: translator('common.signUp'),
      requestError: translator('common.requestError'),
      birthDate: translator('common.birthDate'),
      citizenship: translator('common.citizenship'),
      riskSet: translator('common.riskSet'),
      lifestyleActivity: translator('common.lifestyleActivity'),
      inDevelopment: translator('common.inDevelopment'),
      tourAgent: translator('common.tourAgent'),
      tourAgency: translator('common.tourAgency'),
      gender: translator('common.gender'),
      male: translator('common.male'),
      female: translator('common.female'),
      continue: translator('common.continue'),
      saveAndContinue: translator('common.saveAndContinue'),
      retry: translator('common.retry'),
      balance: translator('common.balance'),
      available: translator('common.available'),
      logout: translator('common.logout'),
      subscribe: translator('common.subscribe'),
      goToMain: translator('common.goToMain'),
      client: translator('common.client'),
      price: translator('common.price'),
      status: translator('common.status'),
      issueDate: translator('common.issueDate'),
      issuedDate: translator('common.issuedDate'),
      dateOfOrdering: translator('common.dateOfOrdering'),
      premium: translator('common.premium'),
      bonusActivationDate: translator('common.bonusActivationDate'),
      back: translator('common.back'),
      actions: translator('common.actions'),
      cancel: translator('common.cancel'),
      delete: translator('common.delete'),
      operationError: translator('common.operationError'),
      search: translator('common.search'),
      select: translator('common.select'),
      role: translator('common.role'),
      created: translator('common.created'),
      expires: translator('common.expires'),
      invite: translator('common.invite'),
      changeAccount: translator('common.changeAccount'),
      docs: translator('common.docs'),
      check: translator('common.check'),
      policy: translator('common.policy'),
      manager: translator('common.manager'),
      overview: translator('common.overview'),
      newPolicy: translator('common.newPolicy'),
      create: translator('common.create'),
      warning: translator('common.warning'),
      send: translator('common.send'),
      subject: translator('common.subject'),
      toHomePage: translator('common.toHomePage'),
      openInNewTab: translator('common.openInNewTab'),
      save: translator('common.save'),
      fill: translator('common.fill'),
      fio: translator('common.fio'),
      phone: translator('common.phone'),
      ogrn: translator('common.ogrn'),
      kpp: translator('common.kpp'),
      bik: translator('common.bik'),
      correspondentAccount: translator('common.correspondentAccount'),
      paymentAccount: translator('common.paymentAccount'),
      legalAddress: translator('common.legalAddress'),
      contactPhone: translator('common.contactPhone'),
      date: translator('common.date'),
      sum: translator('common.sum'),
      processing: translator('common.processing'),
      completed: translator('common.completed'),
      rejected: translator('common.rejected'),
      download: translator('common.download'),
      selfEmployed: translator('common.selfEmployed'),
      physical: translator('common.physical'),
      tax: translator('common.tax'),
      partnerCommission: translator('common.partnerCommission'),
      ip: translator('common.ip'),
      confirm: translator('common.confirm'),
      bookManually: translator('common.bookManually'),
      touristLink: translator('common.touristLink'),
      insertTouristOption: translator('common.insertTouristOption'),
      cashbackLinkHere: translator('common.cashbackLinkHere'),
      cashbackLinkError: translator('common.cashbackLinkError'),
      findOptions: translator('common.findOptions'),
      makeCashbackLink: translator('common.makeCashbackLink'),
      copyCashbackLink: translator('common.copyCashbackLink'),
      chooseForTourist: translator('common.chooseForTourist'),
      sendCahsbackLink: translator('common.sendCahsbackLink'),
      afterSendingCashback: translator('common.afterSendingCashback')
    },
    roles: {
      tourAgent: translator('roles.tourAgent'),
      tourAgencyAdmin: translator('roles.tourAgencyAdmin'),
      tourAgencyOwner: translator('roles.tourAgencyOwner'),
      tourAgencyManager: translator('roles.tourAgencyManager')
    },
    errorCodes: {
      weak_password: translator('errorCodes.weak_password'),
      FAILED_TO_GENERATE_OVERPROTECTION_POLICY: translator('errorCodes.FAILED_TO_GENERATE_OVERPROTECTION_POLICY'),
      FAILED_TO_CREATE_PAYMENT_TRANSACTION: translator('errorCodes.FAILED_TO_CREATE_PAYMENT_TRANSACTION'),
      FAILED_TO_SUBMIT_OVERPROTECTION_POLICY: translator('errorCodes.FAILED_TO_SUBMIT_OVERPROTECTION_POLICY'),
      NO_USER_WITH_THIS_EMAIL: translator('errorCodes.NO_USER_WITH_THIS_EMAIL'),
      RESET_PASSWORDS_SHOULD_MATCH: translator('errorCodes.RESET_PASSWORDS_SHOULD_MATCH'),
      INCORRECT_RESET_PASSWORD_LINK: translator('errorCodes.INCORRECT_RESET_PASSWORD_LINK'),
      NO_USER: translator('errorCodes.NO_USER'),
      CONFIRMATION_LINK_EXPIRED: translator('errorCodes.CONFIRMATION_LINK_EXPIRED'),
      INCORRECT_CONFIRMATION_TOKEN: translator('errorCodes.INCORRECT_CONFIRMATION_TOKEN'),
      TOUR_AGENCY_ALREADY_EXISTS: translator('errorCodes.TOUR_AGENCY_ALREADY_EXISTS'),
      INCORRECT_INVITE_LINK_TOKEN: translator('errorCodes.INCORRECT_INVITE_LINK_TOKEN'),
      ACCEPT_TOUR_MANAGER_INVITE_ERROR: translator('errorCodes.ACCEPT_TOUR_MANAGER_INVITE_ERROR'),
      INVITE_LINK_EXPIRED: translator('errorCodes.INVITE_LINK_EXPIRED'),
      FAILED_TO_CREATE_CONTRACTOR: translator('errorCodes.FAILED_TO_CREATE_CONTRACTOR')
    },
    forms: {
      minLength: translator('forms.minLength'),
      maxLength: translator('forms.maxLength'),
      chooseCountries: translator('forms.chooseCountries'),
      chooseDates: translator('forms.chooseDates'),
      requiredField: translator('forms.requiredField'),
      incorrectEmail: translator('forms.incorrectEmail'),
      russianNameRequirements: translator('forms.russianNameRequirements'),
      foreignNamesRequirements: translator('forms.foreignNamesRequirements'),
      invalidPhone: translator('forms.invalidPhone'),
      invalidBirthDate: translator('forms.invalidBirthDate'),
      invalidInn: translator('forms.invalidInn'),
      incorrectValue: translator('forms.incorrectValue'),
      invalidOgrn: translator('forms.invalidOgrn'),
      invalidKpp: translator('forms.invalidKpp'),
      invalidBik: translator('forms.invalidBik'),
      invalidСorrespondentAccount: translator('forms.invalidСorrespondentAccount'),
      invalidPaymentAccount: translator('forms.invalidPaymentAccount')
    },
    signIn: {
      welcome: translator('signIn.welcome'),
      title: translator('signIn.title'),
      description: translator('signIn.description'),
      successMessage: translator('signIn.successMessage'),
      noAccount: translator('signIn.noAccount'),
      forgetPassword: translator('signIn.forgetPassword'),
      rememberMe: translator('signIn.rememberMe')
    },
    signUp: {
      title: translator('signUp.title'),
      description: translator('signUp.description'),
      expandYourPossibilities: translator('signUp.expandYourPossibilities'),
      createAccount: translator('signUp.createAccount'),
      successMessage: translator('signUp.successMessage'),
      hasAccount: translator('signUp.hasAccount'),
      byRegisteringYouAgree: translator('signUp.byRegisteringYouAgree'),
      termsOfService: translator('signUp.termsOfService'),
      privacyPolicy: translator('signUp.privacyPolicy'),
      selectCompany: translator('signUp.selectCompany'),
      adminAccount: translator('signUp.adminAccount'),
      searchCompany: translator('signUp.searchCompany'),
      noCompaniesInSearchResult: translator('signUp.noCompaniesInSearchResult'),
      createTourAgencyOwnerAccount: translator('signUp.createTourAgencyOwnerAccount'),
      tourAgencyTabTitle: translator('signUp.tourAgencyTabTitle')
    },
    resetPassword: {
      title: translator('resetPassword.title'),
      description: translator('resetPassword.description'),
      reset: translator('resetPassword.reset'),
      requestSent: translator('resetPassword.requestSent'),
      checkYourMail: translator('resetPassword.checkYourMail')
    },
    changePassword: {
      title: translator('changePassword.title'),
      description: translator('changePassword.description'),
      change: translator('changePassword.change'),
      passwordChanged: translator('changePassword.passwordChanged')
    },
    policy: {
      travelCancellation: {
        title: translator('policy.travelCancellation.title'),
        travelStart: translator('policy.travelCancellation.travelStart'),
        travelEnd: translator('policy.travelCancellation.travelEnd'),
        calculatePolicy: translator('policy.travelCancellation.calculatePolicy'),
        policyData: translator('policy.travelCancellation.policyData'),
        payment: translator('policy.travelCancellation.payment'),
        visitingCountries: translator('policy.travelCancellation.visitingCountries'),
        tourPrice: translator('policy.travelCancellation.tourPrice'),
        travelDataTitle: translator('policy.travelCancellation.travelDataTitle'),
        policyDataTitle: translator('policy.travelCancellation.policyDataTitle'),
        addParticipant: translator('policy.travelCancellation.addParticipant'),
        calculatePrice: translator('policy.travelCancellation.calculatePrice'),
        generatePdf: translator('policy.travelCancellation.generatePdf'),
        generatingPdf: translator('policy.travelCancellation.generatingPdf'),
        paymentTransactionCreation: translator('policy.travelCancellation.paymentTransactionCreation'),
        paymentTransactionCreated: translator('policy.travelCancellation.paymentTransactionCreated'),
        goToPayment: translator('policy.travelCancellation.goToPayment'),
        redirectToPayment: translator('policy.travelCancellation.redirectToPayment'),
        continueAfterPaymentRedirect: translator('policy.travelCancellation.continueAfterPaymentRedirect'),
        goToPaymentPage: translator('policy.travelCancellation.goToPaymentPage'),
        createNewPaymentTransaction: translator('policy.travelCancellation.createNewPaymentTransaction'),
        paymentConfirmation: translator('policy.travelCancellation.paymentConfirmation'),
        paymentConfirmationDescription: translator('policy.travelCancellation.paymentConfirmationDescription'),
        paymentError: translator('policy.travelCancellation.paymentError'),
        policyGiving: translator('policy.travelCancellation.policyGiving'),
        generatingPolicyPdf: translator('policy.travelCancellation.generatingPolicyPdf'),
        policyPdfGenerated: translator('policy.travelCancellation.policyPdfGenerated'),
        givePolicyToClient: translator('policy.travelCancellation.givePolicyToClient'),
        givePolicyToClientDescription1: translator('policy.travelCancellation.givePolicyToClientDescription1'),
        givePolicyToClientDescription2: translator('policy.travelCancellation.givePolicyToClientDescription2'),
        openPolicy: translator('policy.travelCancellation.openPolicy'),
        print: translator('policy.travelCancellation.print'),
        sendToEmail: translator('policy.travelCancellation.sendToEmail'),
        participantCount: translator('policy.travelCancellation.participantCount'),
        maxInsuranceSum: translator('policy.travelCancellation.maxInsuranceSum'),
        submitPolicyToRss: translator('policy.travelCancellation.submitPolicyToRss'),
        policySubmittedToRss: translator('policy.travelCancellation.policySubmittedToRss'),
        clickToMakePolicyOwner: translator('policy.travelCancellation.clickToMakePolicyOwner'),
        policyOwnerTooltip: translator('policy.travelCancellation.policyOwnerTooltip'),
        policyOwner: translator('policy.travelCancellation.policyOwner'),
        policyParticipant: translator('policy.travelCancellation.policyParticipant')
      }
    },
    comingSoon: {
      title: translator('comingSoon.title'),
      description: translator('comingSoon.description')
    },
    confirmEmail: {
      title: translator('confirmEmail.title'),
      description: translator('confirmEmail.description'),
      activateAccount: translator('confirmEmail.activateAccount'),
      activateAccountInProgress: translator('confirmEmail.activateAccountInProgress'),
      sendingActivationEmail: translator('confirmEmail.sendingActivationEmail')
    },
    page404: {
      title: translator('page404.title'),
      description: translator('page404.description')
    },
    dashboard: {
      availableBalance: translator('dashboard.availableBalance'),
      totalBalance: translator('dashboard.totalBalance'),
      notCompletedPolicies: translator('dashboard.notCompletedPolicies'),
      overprotectionPoliciesHistory: translator('dashboard.overprotectionPoliciesHistory'),
      overprotectionPoliciesSoldCount: translator('dashboard.overprotectionPoliciesSoldCount'),
      creditedToAccount: translator('dashboard.creditedToAccount'),
      waitingToCreditToAccount: translator('dashboard.waitingToCreditToAccount'),
      willBeCredited: translator('dashboard.willBeCredited'),
      deleteNotCompletedPolicy: translator('dashboard.deleteNotCompletedPolicy'),
      deleteNotCompletedPolicyDescription: translator('dashboard.deleteNotCompletedPolicyDescription'),
      overprotectionPolicySteps: {
        priceCalculationStarted: translator('dashboard.overprotectionPolicySteps.priceCalculationStarted'),
        priceCalculated: translator('dashboard.overprotectionPolicySteps.priceCalculated'),
        participantsDataSaved: translator('dashboard.overprotectionPolicySteps.participantsDataSaved'),
        paymentStarted: translator('dashboard.overprotectionPolicySteps.paymentStarted'),
        paymentTransactionCreated: translator('dashboard.overprotectionPolicySteps.paymentTransactionCreated'),
        paymentCompleted: translator('dashboard.overprotectionPolicySteps.paymentCompleted'),
        paymentConfirmed: translator('dashboard.overprotectionPolicySteps.paymentConfirmed'),
        policyGenerated: translator('dashboard.overprotectionPolicySteps.policyGenerated'),
        policySubmitted: translator('dashboard.overprotectionPolicySteps.policySubmitted'),
        rewardApplyed: translator('dashboard.overprotectionPolicySteps.rewardApplyed')
      }
    },
    tourAgencyDashboard: {
      title: translator('tourAgencyDashboard.title')
    },
    confirmEmailDialog: {
      title: translator('confirmEmailDialog.title'),
      emailSent: translator('confirmEmailDialog.emailSent'),
      goToLink: translator('confirmEmailDialog.goToLink'),
      sentEmailAgain: translator('confirmEmailDialog.sentEmailAgain'),
      activationEmailSent: translator('confirmEmailDialog.activationEmailSent')
    },
    userManagment: {
      title: translator('userManagment.title'),
      employee: translator('userManagment.employee'),
      invites: translator('userManagment.invites'),
      addEmployee: translator('userManagment.addEmployee'),
      addEmployeeDescription: translator('userManagment.addEmployeeDescription'),
      canUseServices: translator('userManagment.canUseServices'),
      canManageEmployees: translator('userManagment.canManageEmployees'),
      canNotManageEmployees: translator('userManagment.canNotManageEmployees'),
      canTransferMoney: translator('userManagment.canTransferMoney'),
      canNotTransferMoney: translator('userManagment.canNotTransferMoney'),
      inviteSent: translator('userManagment.inviteSent'),
      accountActivation: translator('userManagment.accountActivation'),
      signInToConfirmInvite: translator('userManagment.signInToConfirmInvite'),
      signUnToConfirmInvite: translator('userManagment.signUnToConfirmInvite'),
      deleteEmployeeInvite: translator('userManagment.deleteEmployeeInvite'),
      confirmEmployeeInviteDeletion: translator('userManagment.confirmEmployeeInviteDeletion'),
      selectAccount: translator('userManagment.selectAccount')
    },
    vzr: {
      title: translator('vzr.title'),
      contactInfoNotification: translator('vzr.contactInfoNotification'),
      rewardNotification: translator('vzr.rewardNotification'),
      salesHistory: translator('vzr.salesHistory'),
      destination: translator('vzr.destination'),
      checkIsNotAvailable: translator('vzr.checkIsNotAvailable'),
      policyIsNotAvailable: translator('vzr.policyIsNotAvailable'),
      progressWillBeReset: translator('vzr.progressWillBeReset')
    },
    docs: {
      title: translator('docs.title'),
      sections: {
        tourAgentClub: translator('docs.sections.tourAgentClub'),
        aboutService: translator('docs.sections.aboutService'),
        gettingStarted: translator('docs.sections.gettingStarted'),
        services: translator('docs.sections.services'),
        sravni: translator('docs.sections.sravni')
      }
    },
    support: {
      title: translator('support.title'),
      description1: translator('support.description1'),
      description2: translator('support.description2'),
      messageHint: translator('support.messageHint'),
      senderHint: translator('support.senderHint'),
      messageSuccessfullySent: translator('support.messageSuccessfullySent')
    },
    cashback: {
      title: translator('cashback.title'),
      linkCopied: translator('cashback.linkCopied'),
      deeplinkLinkError: translator('cashback.deeplinkLinkError')
    },
    account: {
      title: translator('account.title'),
      profile: translator('account.profile'),
      payout: translator('account.payout'),
      profileUpdateSucceed: translator('account.profileUpdateSucceed'),
      profileEditing: translator('account.profileEditing'),
      tourAgencyInfo: translator('account.tourAgencyInfo'),
      fillProfile: translator('account.fillProfile'),
      fillProfileDescription: translator('account.fillProfileDescription'),
      checkProfile: translator('account.checkProfile'),
      checkTourAgencyData: translator('account.checkTourAgencyData'),
      selectRequisites: translator('account.selectRequisites'),
      doPayout: translator('account.doPayout'),
      checkContractorStatus: translator('account.checkContractorStatus'),
      checkTourAgencyStatus: translator('account.checkTourAgencyStatus'),
      contractorStatusError: translator('account.contractorStatusError'),
      noUserRequisites: translator('account.noUserRequisites'),
      addUserRequisites: translator('account.addUserRequisites'),
      tourAgencyUpdated: translator('account.tourAgencyUpdated'),
      fillTourAgencyData: translator('account.fillTourAgencyData'),
      missingData: translator('account.missingData'),
      withdrawalHistory: translator('account.withdrawalHistory'),
      rewardsDistribution: translator('account.rewardsDistribution'),
      minimalWithdrawAmount: translator('account.minimalWithdrawAmount'),
      withdrawAmount: translator('account.withdrawAmount'),
      selectPaymentMethod: translator('account.selectPaymentMethod'),
      withdrawToCard: translator('account.withdrawToCard'),
      withdrawToMobilePhone: translator('account.withdrawToMobilePhone'),
      paymentMethod: translator('account.paymentMethod'),
      withdrawalHistoryTab: translator('account.withdrawalHistoryTab'),
      withdrawalConfirmation: translator('account.withdrawalConfirmation'),
      withdrawReceivedValue: translator('account.withdrawReceivedValue'),
      withdrawalRequestCreated: translator('account.withdrawalRequestCreated'),
      withdrawalRequestError: translator('account.withdrawalRequestError')
    }
  };
};

export const useTranslations = () => {
  const { t: translate } = useTranslation();
  const translations = useMemo(() => getTranslations(translate), [translate]);
  return translations;
};
