import { Card, Table, TableRow, TableBody, TableCell, TableHead, CardHeader, TableContainer } from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { useTranslations } from 'hooks/useTranslations';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useTourAgencyInvitesQuery } from 'redux/slices/baseApi';
import { InviteItem } from './InviteItem';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Fragment } from 'react';

export default function InvitesList() {
  const role = useCurrentRole();
  const { data = [] } = useTourAgencyInvitesQuery({
    tourAgencyId: role.tourAgency?.id || 0,
    roleId: role.id,
  });
  const translations = useTranslations();

  if (data.length === 0) {
    return <Fragment />;
  }

  return (
    <Card sx={{ pb: 3 }}>
      <CardHeader avatar={<GroupAddIcon />} title={translations.userManagment.invites()} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations.common.email()}</TableCell>
                <TableCell>{translations.common.role()}</TableCell>
                <TableCell>{translations.common.created()}</TableCell>
                <TableCell>{translations.common.expires()}</TableCell>
                <TableCell>{translations.common.actions()}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((invite) => (
                <InviteItem key={invite.email} item={invite} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
