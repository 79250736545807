import { useState, Fragment } from 'react';
import { useConfirmTestPaymentRequest } from 'redux/slices/baseApi';
import { CURRENT_ENVIRONMENT } from 'environment';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'redux/store';

export const ConfirmTestPaymentButton = () => {
  const [confirmTestPayment, { isLoading }] = useConfirmTestPaymentRequest();
  const [isEnabled, setIsEnabled] = useState(true);
  const policyProcessId = useSelector((s) => s.policy.overprotectionPolicyProcessId!);

  if (CURRENT_ENVIRONMENT.name === 'production') {
    return <Fragment />;
  }

  const confirmPayment = async () => {
    setIsEnabled(false);
    confirmTestPayment({ policyProcessId });
  };

  return (
    <LoadingButton
      variant="contained"
      loading={isLoading}
      disabled={!isEnabled}
      onClick={confirmPayment}
      sx={{ mt: 3, ml: 2 }}
    >
      Подтвердить тестовый платеж
    </LoadingButton>
  );
};
