import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography } from '@mui/material';
import { PATH_AUTH } from '../../routes/paths';
import AuthLayout from '../../layouts/AuthLayout';
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import { useTranslations } from 'hooks/useTranslations';
import { useTourAgencyInviteToken } from 'hooks/user/useTourAgencyInviteToken';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const translations = useTranslations();
  const tourAgencyInviteToken = useTourAgencyInviteToken();

  const title = tourAgencyInviteToken //
    ? translations.userManagment.accountActivation()
    : translations.signIn.title();
  const description = tourAgencyInviteToken
    ? translations.userManagment.signInToConfirmInvite()
    : translations.signIn.description();

  return (
    <RootStyle title={`${translations.common.signIn()} | ${translations.product.name()}`}>
      {!tourAgencyInviteToken && (
        <AuthLayout>
          {translations.signIn.noAccount()} &nbsp;
          <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
            {translations.common.signUp()}
          </Link>
        </AuthLayout>
      )}

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h4" sx={{ px: 5, mt: 10, mb: 5 }}>
            {translations.signIn.welcome()}
            <br />
            {translations.product.name()}
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {title}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{description}</Typography>
            </Box>
          </Stack>

          <LoginForm />

          {!tourAgencyInviteToken && (
            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {translations.signIn.noAccount()}&nbsp;
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  {translations.common.signUp()}
                </Link>
              </Typography>
            </MHidden>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
