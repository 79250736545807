import { useMemo } from 'react';
import { useCurrentRole } from './useCurrentRole';

export const useCurrentRolePermissions = () => {
  const role = useCurrentRole();

  const permissions = useMemo(() => {
    const isAdmin = role.type === 'tour-agency-admin';
    const isOwner = role.type === 'tour-agency-owner';
    const isSelfEmployed = role.type === 'self-employed';
    const isIndividual = isOwner && role.tourAgency?.legalForm === 'individual';
    const isLtdOwner = isOwner && role.tourAgency?.legalForm === 'ltd';
    const canPayout = isOwner || isSelfEmployed;
    const canPayoutThroughJumpFinance = isSelfEmployed || isIndividual;

    return {
      isAdmin,
      isOwner,
      isLtdOwner,
      isIndividual,
      isSelfEmployed,
      canViewTourAgencySales: isOwner,
      canManageTourAgencyEmployees: isAdmin || isOwner,
      canInviteTourAgencyOwner: isOwner,
      canPayoutThroughJumpFinance,
      canPayout,
    };
  }, [role]);

  return permissions;
};
