import { Box, Card, Typography } from '@mui/material';
import { useCurrentRoleId } from 'hooks/user/useCurrentRoleId';
import { useTranslations } from 'hooks/useTranslations';
import { useTourAgentBalanceQuery } from 'redux/slices/baseApi';
import { rubBalanceFormatter } from 'utils/money';

export default function TotalBalanceCounter() {
  const roleId = useCurrentRoleId();
  const { data } = useTourAgentBalanceQuery(roleId, {
    refetchOnMountOrArgChange: true,
  });
  const translations = useTranslations();

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{translations.dashboard.totalBalance()}</Typography>
        <Typography variant="h3">{rubBalanceFormatter.format(data?.totalRewardValue || 0)}</Typography>
      </Box>
    </Card>
  );
}
