import { Stack, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { Fragment } from 'react';
import { AccountBalance } from 'types/models/account-balance';
import { PaymentMethod } from './payment-method';
import DataItem from './DataItem';
import { useCurrentRolePermissions } from 'hooks/user/useCurrentRolePermissions';
import { formatPrice } from 'utils/ui/priceFormat';
import { SxProps, Theme } from '@mui/system';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useTourAgentBalance } from 'hooks/tour-agency/useTourAgentBalance';
import { useTourAgencyBalance } from 'hooks/tour-agency/useTourAgencyBalance';

type Props = {
  paymentMethod: PaymentMethod;
};

export default function WithdrawInfo(props: Props) {
  const role = useCurrentRole();

  if (role.tourAgency) {
    return <TourAgencyWithdrawInfoView {...props} />;
  }

  return <TourAgentWithdrawInfoView {...props} />;
}

function TourAgentWithdrawInfoView(props: Props) {
  const { paymentMethod } = props;
  const { balance, canPayout, isBalanceLoading } = useTourAgentBalance();

  return (
    <WithdrawInfoView
      balance={balance}
      canPayout={canPayout}
      isLoading={isBalanceLoading}
      paymentMethod={paymentMethod}
    />
  );
}

function TourAgencyWithdrawInfoView(props: Props) {
  const { paymentMethod } = props;
  const { balance, canPayout, isBalanceLoading } = useTourAgencyBalance();

  return (
    <WithdrawInfoView
      balance={balance}
      canPayout={canPayout}
      isLoading={isBalanceLoading}
      paymentMethod={paymentMethod}
    />
  );
}

type WithdrawInfoViewProps = {
  isLoading: boolean;
  balance: AccountBalance | undefined;
  canPayout: boolean;
  paymentMethod: PaymentMethod;
  sx?: SxProps<Theme>;
};

function WithdrawInfoView(props: WithdrawInfoViewProps) {
  const { balance, canPayout, isLoading, paymentMethod } = props;

  if (isLoading || !balance) {
    return <Fragment />;
  }

  return (
    <Stack>
      <WithdrawInfoViewContent canPayout={canPayout} balance={balance} paymentMethod={paymentMethod} />
    </Stack>
  );
}

function WithdrawInfoViewContent(props: { canPayout: boolean; balance: AccountBalance; paymentMethod: PaymentMethod }) {
  const { canPayout, balance, paymentMethod } = props;
  const { availableRewardValue: amount, minimalPayoutValue } = balance;
  const translations = useTranslations();
  const permissions = useCurrentRolePermissions();

  if (!canPayout) {
    const message = translations.account.minimalWithdrawAmount() + minimalPayoutValue + ' ₽';
    return <Typography>{message}</Typography>;
  }

  if (paymentMethod === 'ltd-payment') {
    return <InfoItem title={translations.account.withdrawAmount()} price={amount} />;
  }

  if (paymentMethod === 'jump-finance') {
    const tax = amount * 0.06;
    const commission = Math.max(amount * 0.03, 50);
    const receivedAmount = amount - tax - commission;

    return (
      <Stack spacing={0.25}>
        <InfoItem title={translations.account.withdrawAmount()} price={amount} />
        {permissions.isSelfEmployed && <InfoItem title={translations.common.tax()} price={tax} />}
        <InfoItem title={translations.common.partnerCommission()} price={commission} />
        <InfoItem title={translations.account.withdrawReceivedValue()} price={receivedAmount} />
      </Stack>
    );
  }

  if (paymentMethod === 'mobile-phone') {
    const commission = amount * 0.15;
    const receivedAmount = amount - commission;

    return (
      <Stack spacing={0.25}>
        <InfoItem title={translations.account.withdrawAmount()} price={amount} />
        <InfoItem title={translations.common.partnerCommission()} price={commission} />
        <InfoItem title={translations.account.withdrawReceivedValue()} price={receivedAmount} />
      </Stack>
    );
  }

  return <Fragment />;
}

function InfoItem(props: { title: string; value?: string; price?: number }) {
  const { title, value, price } = props;
  const displayValue = price !== undefined ? formatPrice(price) : value;

  return <DataItem title={title} value={displayValue} sx={{ my: 0 }} />;
}
