import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Grid, Card, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import { useProfile } from 'hooks/user/useProfile';
import { useValidationRules } from 'hooks/ui/useValidationRules';
import { FirstNameInput } from 'components/inputs/FirstNameInput';
import { LastNameInput } from 'components/inputs/LastNameInput';
import { MiddleNameInput } from 'components/inputs/MiddleNameInput';
import { EmailInput } from 'components/inputs/EmailInput';
import { PhoneInput } from 'components/inputs/PhoneInput';
import { useTranslations } from 'hooks/useTranslations';
import { useUpdateProfileRequest } from 'redux/slices/baseApi';
import { getErrorMessage } from 'utils/errors';
import { useSuccessSnackbar } from 'hooks/ui/useSuccessSnackbar';
import ErrorAlert from 'components/alerts/ErrorAlert';
import { InnInput } from 'components/inputs/InnInput';
import { useCurrentRolePermissions } from 'hooks/user/useCurrentRolePermissions';

interface ProfileState {
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
  inn: string;
  email: string;
  afterSubmit?: string;
}

export default function AccountGeneral() {
  const isMountedRef = useIsMountedRef();
  const validationRules = useValidationRules();
  const profile = useProfile();
  const translations = useTranslations();
  const [updateProfile] = useUpdateProfileRequest();
  const showSuccessSnackbar = useSuccessSnackbar();
  const permissions = useCurrentRolePermissions();

  const UpdateUserSchema = Yup.object().shape({
    firstName: validationRules.firstName(),
    lastName: validationRules.lastName(),
    middleName: validationRules.middleName(),
    phone: validationRules.phone({ required: false }),
    inn: validationRules.inn(),
  });

  const formik = useFormik<ProfileState>({
    enableReinitialize: true,
    initialValues: {
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      middleName: profile.middleName || '',
      email: profile.email || '',
      phone: profile.phone || '',
      inn: profile.inn || '',
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await updateProfile({
          userId: profile.id,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          phone: values.phone,
          inn: values.inn,
        }).unwrap();

        if (isMountedRef.current) {
          showSuccessSnackbar(translations.account.profileUpdateSucceed());
          setSubmitting(false);
        }
      } catch (error: any) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: getErrorMessage(error) });
          setSubmitting(false);
        }
      }
    },
  });

  const { isSubmitting, errors, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Typography variant="h5" sx={{ mb: 4 }}>
                {translations.account.profileEditing()}
              </Typography>

              <Stack spacing={{ xs: 2, md: 3 }}>
                {errors.afterSubmit && <ErrorAlert error={errors.afterSubmit} />}

                <Stack direction={{ xs: 'column', md: 'column' }} spacing={2} sx={{ mb: 1 }}>
                  <FirstNameInput size="medium" />
                  <LastNameInput size="medium" />
                  <MiddleNameInput size="medium" />

                  {permissions.canPayoutThroughJumpFinance && <InnInput size="medium" sx={{ mt: 2 }} />}
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <PhoneInput size="medium" />
                  <EmailInput size="medium" disabled />
                </Stack>
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  {translations.common.save()}
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
