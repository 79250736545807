import { createApi } from '@reduxjs/toolkit/query/react';
import { OverprotectionPolicyPriceRequest } from './models/requests/overprotection-policy-price.request';
import { baseQueryWithReauth, AUTH_PATHS } from './base-query';
import { Country } from '../../../types/models/country';
import { OverprotectionPolicyData } from './models/data/overprotection-policy-data';
import { SumInsuredResponse } from './models/responses/sum-insured.response';
import { AccountBalance } from 'types/models/account-balance';
import { OverprotectionPolicyHistoryItem } from 'types/models/overprotection-policy-history-item';
import { OverprotectionPoliciesSoldResponse } from './models/responses/overprotection-policies-sold.response';
import { ForgotPasswordRequest } from './models/requests/forgot-password.request';
import { ChangePasswordRequest } from './models/requests/change-password.request';
import { SumInsuredRequest } from './models/requests/sum-insured.request';
import { SignUpRequest } from './models/requests/sign-up.request';
import { SignInRequest } from './models/requests/sign-in.request';
import { PolicyProcessData } from './models/data/policy-process-data';
import { OverprotectionPaymentTransaction } from 'types/models/overprotection-payment-transaction';
import { NotCompletedOverprotectionPolicyProcess } from 'types/models/not-completed-overprotection-policy-process';
import { OverprotectionPolicyProcessInfo } from 'types/models/overprotection-policy-process-info';
import { ConfirmEmailRequest } from 'types/models/confirm-email.request';
import { CompanyInfo } from 'types/models/company-info';
import { TourAgencySignUpRequest } from './models/requests/touragency-sign-up.request';
import { Employee } from 'types/models/employee';
import { TourAgencyEmployeeRequest } from './models/requests/tour-agency-employee.request';
import { TourAgencyInvite } from 'types/models/tour-agency-invite';
import { TourAgencyInvitesRequest } from './models/requests/tour-agency-invites.request';
import moment from 'moment';
import { InviteTourAgencyEmployee as InviteTourManagerRequest } from './models/requests/invite-tour-agency-employee.request';
import { DeleteTourAgencyInviteRequest } from './models/requests/delete-tour-agency-invite.request';
import { DeleteTourAgencyEmployeeRequest } from './models/requests/delete-tour-agency-employee.request';
import { TourAgencyRequestDto } from './models/requests/tour-agency.request';
import { TourAgentSalesRequest } from './models/requests/tour-agent-sales.request';
import { SravniVzrPoliciesSalesHistoryResponse } from './models/responses/sravni-vzr-policies-sales-history.response';
import { User } from 'types/account';
import { AskQuestionRequest } from './models/requests/ask-question.request';
import { CashbackOffer } from 'types/models/cashback-offer';
import { CashbackStatistics } from 'types/models/cashback-statistics';
import { GetCashbackDeeplinkRequest } from './models/requests/get-cashback-deeplink.request';
import { GetStatisticsRequest } from './models/requests/get-cashback-statistics.request';
import { UpdateProfileRequest } from './models/requests/update-profile.request';
import { UserCreditCard } from 'types/models/user-credit-card';
import { WithdrawRewardRequest } from './models/requests/withdraw-reward.request';
import { ContractorStatus } from './models/responses/contractor-status';
import { TourAgency } from 'types/models/tour-agency';
import { UpdateTourAgencyRequest } from './models/requests/update-tour-agency.request';
import { WithdrawalHistoryResponse } from './models/responses/withdrawal-history.response';
import { PaginatedTourAgentRequest } from './models/requests/paginated-tour-agent.request';
import { PaginatedTourAgencyRequest } from './models/requests/paginated-tour-agency.request';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Overprotection Policy',
    'Overprotection Policies Updated',
    'Tour Agency Employees',
    'Tour Agency Invites',
    'Tour Agency',
    'Tour Agent Balance',
    'Tour Agency Balance',
  ],
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (body: SignInRequest) => ({
        url: AUTH_PATHS.signIn,
        method: 'POST',
        body,
      }),
    }),

    tourAgentSignUp: builder.mutation({
      query: (body: SignUpRequest) => ({
        url: AUTH_PATHS.tourAgentSignUp,
        method: 'POST',
        body,
      }),
    }),

    tourAgencySignUp: builder.mutation({
      query: (body: TourAgencySignUpRequest) => ({
        url: AUTH_PATHS.tourAgencySignUp,
        method: 'POST',
        body,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (body: ForgotPasswordRequest) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        body,
      }),
    }),

    profile: builder.query<User, number>({
      query: (id) => ({
        url: `users?user-id=${id}`,
        method: 'GET',
      }),
    }),

    changePassword: builder.mutation({
      query: (body: ChangePasswordRequest) => ({
        url: 'auth/change-password',
        method: 'PATCH',
        body,
      }),
    }),

    calculateOverprotectionPolicyPrice: builder.mutation({
      query: (body: OverprotectionPolicyPriceRequest) => ({
        url: 'overprotection-policy/calculate-price',
        method: 'POST',
        body,
      }),
    }),

    overprotectionPolicySumInsured: builder.query<SumInsuredResponse, SumInsuredRequest>({
      query: ({ participantCount, tourPrice }) => {
        const queryParams = `participantCount=${participantCount}&tourPrice=${tourPrice}`;

        return {
          url: `overprotection-policy/sum-insured?${queryParams}`,
          method: 'GET',
        };
      },
    }),

    saveOverprotectionPolicyData: builder.mutation({
      query: (body: OverprotectionPolicyData) => ({
        url: 'overprotection-policy/save-policy-data',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Overprotection Policies Updated'],
    }),

    createPaymentTransaction: builder.mutation({
      query: (body: PolicyProcessData) => ({
        url: 'overprotection-policy/payment',
        method: 'POST',
        body,
      }),
      transformResponse: (response: any) => {
        delete response.messageBody;
        return response;
      },
      invalidatesTags: ['Overprotection Policies Updated'],
    }),

    paymentTransactionStatus: builder.query<OverprotectionPaymentTransaction, string>({
      query: (transactionTrackingId: string) => ({
        url: `overprotection-policy/check-payment-transaction?key=${transactionTrackingId}`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        delete response.messageBody;
        return response;
      },
    }),

    generateOverprotectionPolicyPdf: builder.mutation({
      query: (body: PolicyProcessData) => ({
        url: 'overprotection-policy/generate-pdf',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Overprotection Policies Updated'],
    }),

    submitOverprotectionPolicy: builder.mutation({
      query: (body: PolicyProcessData) => ({
        url: 'overprotection-policy/submit',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Overprotection Policy', 'Overprotection Policies Updated'],
    }),

    countries: builder.query<Country[], any>({
      query: () => ({
        url: 'catalog/countries',
        method: 'GET',
      }),
    }),

    tourAgentBalance: builder.query<AccountBalance, number>({
      query: (tourAgentId) => ({
        url: `reward/tour-agent?tour-agent-role-id=${tourAgentId}`,
        method: 'GET',
      }),
      providesTags: ['Overprotection Policy', 'Tour Agent Balance'],
    }),

    overprotectionPoliciesHistory: builder.query<OverprotectionPolicyHistoryItem[], number>({
      query: (tourAgentId) => ({
        url: `overprotection-policy/history?tour-agent-role-id=${tourAgentId}`,
        method: 'GET',
      }),
      providesTags: ['Overprotection Policy'],
      transformResponse: (data: any) =>
        (data || []).map((item: any) => ({
          ...item,
          issueDate: new Date(item.issueDate),
          activationDate: new Date(item.activationDate),
        })),
    }),

    notCompletedOverprotectionPolicies: builder.query<NotCompletedOverprotectionPolicyProcess[], number>({
      query: (tourAgentId) => ({
        url: `overprotection-policy/not-completed?tour-agent-role-id=${tourAgentId}`,
        method: 'GET',
      }),
      providesTags: ['Overprotection Policies Updated'],
      transformResponse: (data: any) =>
        (data || []).map((item: any) => ({
          ...item,
          issueDate: new Date(item.issueDate),
        })),
    }),

    overprotectionPoliciesSoldCount: builder.query<OverprotectionPoliciesSoldResponse, number>({
      query: (tourAgentId) => ({
        url: `overprotection-policy/sold-count?tour-agent-role-id=${tourAgentId}`,
        method: 'GET',
      }),
      providesTags: ['Overprotection Policy'],
    }),

    deleteOverprotectionPolicyProcess: builder.mutation({
      query: (policyProcessId: number) => ({
        url: `overprotection-policy-process/delete?id=${policyProcessId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Overprotection Policies Updated'],
    }),

    overprotectionPolicyProcess: builder.mutation<OverprotectionPolicyProcessInfo, number>({
      query: (policyProcessId) => ({
        url: `overprotection-policy-process/info?id=${policyProcessId}`,
        method: 'GET',
      }),
    }),

    confirmEmail: builder.mutation<void, ConfirmEmailRequest>({
      query: (body) => ({
        url: `auth/confirm-email`,
        method: 'POST',
        body,
      }),
    }),

    resendConfirmationEmail: builder.mutation<void, void>({
      query: () => ({
        url: `auth/resend-confirmation-link`,
        method: 'POST',
      }),
    }),

    findTourAgency: builder.mutation<CompanyInfo[], string>({
      query: (inn) => ({
        url: `tour-agency/find?inn=${inn}`,
        method: 'GET',
      }),
    }),

    confirmTestPayment: builder.mutation({
      query: (body: PolicyProcessData) => ({
        url: 'overprotection-policy/confirm-test-payment',
        method: 'POST',
        body,
      }),
    }),

    tourAgencyEmployees: builder.query<Employee[], TourAgencyEmployeeRequest>({
      query: ({ tourAgencyId, roleId }) => ({
        url: `tour-agency/employees?tour-agency-id=${tourAgencyId}&role-id=${roleId}`,
        method: 'GET',
      }),
      providesTags: ['Tour Agency Employees'],
    }),

    deleteTourAgencyEmployee: builder.mutation({
      query: (body: DeleteTourAgencyEmployeeRequest) => ({
        url: 'tour-agency/employee',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Tour Agency Employees'],
    }),

    tourAgencyInvites: builder.query<TourAgencyInvite[], TourAgencyInvitesRequest>({
      query: ({ tourAgencyId, roleId }) => ({
        url: `tour-agency/invites?tour-agency-id=${tourAgencyId}&role-id=${roleId}`,
        method: 'GET',
      }),
      transformResponse: (data: any) =>
        (data || []).map((item: any) => ({
          ...item,
          issueDate: moment(item.issueDate).toDate(),
          expiresAt: moment(item.expiresAt).toDate(),
        })),
      providesTags: ['Tour Agency Invites'],
    }),

    inviteTourAgencyEmployee: builder.mutation({
      query: (body: InviteTourManagerRequest) => ({
        url: 'tour-agency/invite-employee',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tour Agency Invites'],
    }),

    deleteTourAgencyInvite: builder.mutation({
      query: (body: DeleteTourAgencyInviteRequest) => ({
        url: 'tour-agency/invite',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Tour Agency Invites'],
    }),

    tourAgencyOverprotectionPoliciesHistory: builder.query<OverprotectionPolicyHistoryItem[], TourAgencyRequestDto>({
      query: ({ tourAgencyId, roleId }) => ({
        url: `tour-agency/sales/history?role-id=${roleId}&tour-agency-id=${tourAgencyId}`,
        method: 'GET',
      }),
      providesTags: ['Overprotection Policy'],
      transformResponse: (data: any) =>
        (data || []).map((item: any) => ({
          ...item,
          issueDate: new Date(item.issueDate),
          activationDate: new Date(item.activationDate),
        })),
    }),

    tourAgencyOverprotectionPoliciesSoldCount: builder.query<OverprotectionPoliciesSoldResponse, TourAgencyRequestDto>({
      query: ({ tourAgencyId, roleId }) => ({
        url: `tour-agency/sales/sold-count?role-id=${roleId}&tour-agency-id=${tourAgencyId}`,
        method: 'GET',
      }),
      providesTags: ['Overprotection Policy'],
    }),

    tourAgencyBalance: builder.query<AccountBalance, TourAgencyRequestDto>({
      query: ({ tourAgencyId, roleId }) => ({
        url: `reward/tour-agency?role-id=${roleId}&tour-agency-id=${tourAgencyId}`,
        method: 'GET',
      }),
      providesTags: ['Overprotection Policy', 'Tour Agency Balance'],
    }),

    tourAgentSravniSalesHistory: builder.query<SravniVzrPoliciesSalesHistoryResponse, TourAgentSalesRequest>({
      query: ({ tourAgentRoleId }) => ({
        url: `sravni/sales-history/tour-agent?tour-agent-role-id=${tourAgentRoleId}`,
        method: 'GET',
      }),
    }),

    tourAgencySravniSalesHistory: builder.query<SravniVzrPoliciesSalesHistoryResponse, TourAgencyRequestDto>({
      query: ({ roleId, tourAgencyId }) => ({
        url: `sravni/sales-history/tour-agency?role-id=${roleId}&tour-agency-id=${tourAgencyId}`,
        method: 'GET',
      }),
    }),

    askQuestion: builder.mutation({
      query: (body: AskQuestionRequest) => ({
        url: 'support/ask-question',
        method: 'POST',
        body,
      }),
    }),

    cashbackOffers: builder.query<CashbackOffer[], void>({
      query: () => ({
        url: `cashback/offers`,
        method: 'GET',
      }),
    }),

    cashbackStatistics: builder.query<CashbackStatistics, GetStatisticsRequest>({
      query: ({ tourAgentRoleId, tourAgencyId, dateStart, dateEnd }) => ({
        url:
          `cashback/statistics?dateStart=${dateStart}&dateEnd=${dateEnd}&tourAgentRoleId=${tourAgentRoleId}` +
          tourAgencyId
            ? `&tourAgencyId=${tourAgencyId}`
            : '',
        method: 'GET',
      }),
    }),

    cashbackDeeplink: builder.mutation<{ deeplink: string }, GetCashbackDeeplinkRequest>({
      query: ({ tourAgentRoleId, tourAgencyId, offerId }) => ({
        url: `cashback/deeplink?tourAgentRoleId=${tourAgentRoleId}&offerId=${offerId}${
          tourAgencyId ? `&tourAgencyId=${tourAgencyId}` : ''
        }`,
        method: 'GET',
      }),
    }),

    updateProfile: builder.mutation<User, UpdateProfileRequest>({
      query: (body) => ({
        url: 'users',
        method: 'PATCH',
        body,
      }),
    }),

    userRequisites: builder.query<UserCreditCard[], number>({
      query: (roleId) => ({
        url: `withdrawal/jump-finance/user-requisites?tour-agent-role-id=${roleId}`,
        method: 'GET',
      }),
    }),

    withdrawTourAgentRewards: builder.mutation<void, WithdrawRewardRequest>({
      query: (body) => ({
        url: 'withdrawal/jump-finance/withdraw-reward',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tour Agent Balance'],
    }),

    contractorStatus: builder.query<ContractorStatus, number>({
      query: (roleId) => ({
        url: `withdrawal/jump-finance/contractor-status?tour-agent-role-id=${roleId}`,
        method: 'GET',
      }),
    }),

    tourAgency: builder.query<TourAgency, TourAgencyRequestDto>({
      query: ({ roleId, tourAgencyId }) => ({
        url: `tour-agency?role-id=${roleId}&tour-agency-id=${tourAgencyId}`,
        method: 'GET',
      }),
      providesTags: ['Tour Agency'],
    }),

    updateTourAgency: builder.mutation<TourAgency, UpdateTourAgencyRequest>({
      query: (body) => ({
        url: `tour-agency`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Tour Agency'],
    }),

    withdrawLtdRewards: builder.mutation<void, TourAgencyRequestDto>({
      query: (body) => ({
        url: 'withdrawal/ltd/withdraw-reward',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tour Agency Balance'],
    }),

    tourAgentWithdrawalHistory: builder.query<WithdrawalHistoryResponse, PaginatedTourAgentRequest>({
      query: ({ tourAgentRoleId, limit, offset }) => ({
        url: `withdrawal/history/tour-agent?tour-agent-role-id=${tourAgentRoleId}&limit=${limit}&offset=${offset}`,
        method: 'GET',
      }),
      providesTags: ['Tour Agent Balance'],
    }),

    tourAgencyWithdrawalHistory: builder.query<WithdrawalHistoryResponse, PaginatedTourAgencyRequest>({
      query: ({ tourAgencyId, roleId, limit, offset }) => ({
        url: `withdrawal/history/tour-agency?tour-agency-id=${tourAgencyId}&role-id=${roleId}&limit=${limit}&offset=${offset}`,
        method: 'GET',
      }),
      providesTags: ['Tour Agency Balance'],
    }),

    withdrawThroughMobilePhone: builder.mutation<void, number>({
      query: (tourAgentRoleId) => ({
        url: `withdrawal/mobile-phone/withdraw-reward?tour-agent-role-id=${tourAgentRoleId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Tour Agent Balance', 'Tour Agency Balance'],
    }),

    openCloudStorageFile: builder.mutation<{ signedUrl: string }, string>({
      query: (url) => ({
        url: `cloud-storage/signed-url?origin-url=${encodeURIComponent(url)}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCalculateOverprotectionPolicyPriceMutation: useCalculateOverprotectionPolicyPriceRequest,
  useOverprotectionPolicySumInsuredQuery,
  useSaveOverprotectionPolicyDataMutation: useSaveOverprotectionPolicyDataRequest,
  useCreatePaymentTransactionMutation: useCreatePaymentTransactionRequest,
  useConfirmTestPaymentMutation: useConfirmTestPaymentRequest,
  usePaymentTransactionStatusQuery,
  useGenerateOverprotectionPolicyPdfMutation: useGenerateOverprotectionPolicyPdfRequest,
  useSubmitOverprotectionPolicyMutation: useSubmitOverprotectionPolicyRequest,
  useOverprotectionPolicyProcessMutation: useOverprotectionPolicyProcessRequest,
  useDeleteOverprotectionPolicyProcessMutation: deleteOverprotectionPolicyProcessRequest,

  useConfirmEmailMutation: useConfirmEmailRequest,
  useResendConfirmationEmailMutation: useResendConfirmationEmailRequest,

  useSignInMutation: useSignInRequest,
  useTourAgentSignUpMutation: useTourAgentSignUpRequest,
  useTourAgencySignUpMutation: useTourAgencySignUpRequest,
  useForgotPasswordMutation: useForgotPasswordRequest,
  useChangePasswordMutation: useChangePasswordRequest,

  useProfileQuery,

  useCountriesQuery,
  useTourAgentBalanceQuery,
  useOverprotectionPoliciesHistoryQuery,
  useNotCompletedOverprotectionPoliciesQuery,
  useOverprotectionPoliciesSoldCountQuery,

  useFindTourAgencyMutation: useFindTourAgencyRequest,
  useTourAgencyEmployeesQuery,
  useTourAgencyInvitesQuery,
  useInviteTourAgencyEmployeeMutation: useInviteTourAgencyEmployeeRequest,
  useDeleteTourAgencyEmployeeMutation: useDeleteTourAgencyEmployeeRequest,
  useDeleteTourAgencyInviteMutation: useDeleteTourAgencyInviteRequest,

  useTourAgencyBalanceQuery,
  useTourAgencyOverprotectionPoliciesHistoryQuery,
  useTourAgencyOverprotectionPoliciesSoldCountQuery,

  useTourAgentSravniSalesHistoryQuery,
  useTourAgencySravniSalesHistoryQuery,

  useAskQuestionMutation: useAskQuestionRequest,

  useCashbackOffersQuery,
  useCashbackStatisticsQuery,
  useCashbackDeeplinkMutation: useCashbackDeeplinkRequest,

  useUpdateProfileMutation: useUpdateProfileRequest,
  useUserRequisitesQuery,
  useWithdrawTourAgentRewardsMutation: useWithdrawTourAgentRewardsRequest,
  useContractorStatusQuery,
  useTourAgentWithdrawalHistoryQuery,

  useWithdrawThroughMobilePhoneMutation: useWithdrawThroughMobilePhoneRequest,

  useTourAgencyQuery,
  useUpdateTourAgencyMutation: updateTourAgencyRequest,
  useWithdrawLtdRewardsMutation: useWithdrawLtdRewardsRequest,
  useTourAgencyWithdrawalHistoryQuery,

  useOpenCloudStorageFileMutation: useOpenCloudStorageFileRequest,
} = baseApi;
