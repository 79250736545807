import { Box, Card, Typography } from '@mui/material';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useTranslations } from 'hooks/useTranslations';
import { useTourAgencyBalanceQuery } from 'redux/slices/baseApi';
import { rubBalanceFormatter } from 'utils/money';

export default function AvailableBalanceCounter() {
  const role = useCurrentRole();
  const { data } = useTourAgencyBalanceQuery(
    {
      roleId: role.id,
      tourAgencyId: role.tourAgency?.id || 0,
    },
    { refetchOnMountOrArgChange: true },
  );
  const translations = useTranslations();

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{translations.dashboard.availableBalance()}</Typography>
        <Typography variant="h3">{rubBalanceFormatter.format(data?.availableRewardValue || 0)}</Typography>
      </Box>
    </Card>
  );
}
