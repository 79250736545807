function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_INSURANCE = '/insurance';
const ROOTS_HOTELS = '/hotels';
const ROOTS_PROFILE = '/profile';
const ROOTS_SUPPORT = '/support';
const ROOTS_CASHBACK = '/cashback';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  registerTourAgent: path(ROOTS_AUTH, '/register?type=tour-agent'),
  registerTourAgency: path(ROOTS_AUTH, '/register?type=tour-agency'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: '/dashboard/overview',
  tourAgencySales: '/dashboard/tour-agency/sales',
  employees: '/dashboard/employees',
};

export const PATH_POLICY = {
  root: ROOTS_INSURANCE,
  insurance: {
    travelCancellationInsurance: path(ROOTS_INSURANCE, '/travel-cancellation'),
    travelCancellationInsuranceStep2: path(ROOTS_INSURANCE, '/travel-cancellation-step-2'),
    travelCancellationInsuranceStep3: path(ROOTS_INSURANCE, '/travel-cancellation-step-3'),
    travelCancellationInsuranceStep4: path(ROOTS_INSURANCE, '/travel-cancellation-step-4'),

    sravniVzr: path(ROOTS_INSURANCE, '/vzr'),

    osago: path(ROOTS_INSURANCE, '/osago'),
    kasko: path(ROOTS_INSURANCE, '/kasko'),
  },
};

export const PATH_PROFILE = {
  root: ROOTS_PROFILE,
  profile: {
    account: path(ROOTS_PROFILE, '/account'),
    edit: path(ROOTS_PROFILE, `/account?t=general`),
    tourAgency: path(ROOTS_PROFILE, `/account?t=tour-agency`),
    payout: path(ROOTS_PROFILE, '/account?t=payout'),
    withdrawalHistory: path(ROOTS_PROFILE, '/account?t=withdrawal-history'),
    settings: path(ROOTS_PROFILE, '/settings'),
  },
};

export const PATH_HOTELS = {
  root: ROOTS_HOTELS,
  hotels: {
    news: path(ROOTS_HOTELS, '/news'),
    actions: path(ROOTS_HOTELS, '/actions'),
    rating: path(ROOTS_HOTELS, '/rating'),
  },
};

export const PATH_SUPPORT = {
  root: ROOTS_SUPPORT,
  support: {
    faq: path(ROOTS_SUPPORT, '/faq'),
    askQuestion: path(ROOTS_SUPPORT, '/ask-question'),
  },
};

export const PATH_CASHBACK = {
  root: ROOTS_CASHBACK,
  cashback: {
    main: path(ROOTS_CASHBACK, '/offers'),
  },
};

export const INITIAL_ROUTE = PATH_DASHBOARD.root;

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
