export type RoleType = 'self-employed' | 'tour-agency-manager' | 'tour-agency-admin' | 'tour-agency-owner';
export const RoleTypeValues = ['self-employed', 'tour-agency-manager', 'tour-agency-admin', 'tour-agency-owner'];

export type Role = {
  id: number;
  type: RoleType;
  tourAgency?: {
    id: number;
    name: string;
    legalForm: 'individual' | 'ltd';
  };
};

export type User = {
  id: number;
  displayName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phone: string;
  inn?: string;
  password: string;
  role: string | null;
  photoURL: File | any;
  roles: Role[];
  isEmailConfirmed: boolean;
};
