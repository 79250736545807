import { ReactNode, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useTranslations } from 'hooks/useTranslations';
import { formatPrice } from 'utils/ui/priceFormat';
import { conditionalItem } from 'utils/conditionalItem';
import { useTourAgencyWithdrawalHistoryQuery, useTourAgentWithdrawalHistoryQuery } from 'redux/slices/baseApi';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import CenterBox from 'components/containers/CenterBox';
import { Box, Button, Card, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { WithdrawalHistoryItem } from 'types/models/withdrawal-history-item';
import { useCurrentRolePermissions } from 'hooks/user/useCurrentRolePermissions';
import Subtitle from 'components/text/Subtitle';
import moment from 'moment';
import Label from 'components/Label';
import ErrorAlert from 'components/alerts/ErrorAlert';

interface Column {
  id: 'createdAt' | 'amount' | 'status' | 'rewards';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => ReactNode;
}

type AccountType = 'tour-agent' | 'tour-agency';

const useColumns = (accountType: AccountType) => {
  const translations = useTranslations();
  const strings = translations.common;

  const columns: Column[] = useMemo(() => {
    return [
      {
        id: 'createdAt',
        label: strings.date(),
        format: (value: string) => moment(value).format('D MMMM yyyy - HH:mm'),
      },

      {
        id: 'status',
        label: strings.status(),
        minWidth: 100,
        format: (value: string) => {
          if (value === 'processing') return <StatusLabel title={strings.processing()} color="warning" />;
          if (value === 'completed') return <StatusLabel title={strings.completed()} color="primary" />;
          if (value === 'rejected') return <StatusLabel title={strings.rejected()} color="error" />;

          return '';
        },
      },
      {
        id: 'amount',
        label: strings.sum(),
        minWidth: 100,
        format: (value: number) => formatPrice(value),
      },

      ...conditionalItem<Column>(accountType === 'tour-agency', {
        id: 'rewards',
        label: translations.account.rewardsDistribution(),
      }),
    ];
  }, [strings, translations, accountType]);

  return columns;
};

const DownloadButton = ({ link, type }: { link: string; type: string }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
      <Button variant="outlined" style={{ textTransform: 'none' }} size={'small'}>
        {type}
      </Button>
    </a>
  );
};

const ROWS_PER_PAGE = 5;

const TourAgentWithdrawalHistory = () => {
  const role = useCurrentRole();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);

  const { data, isLoading, error } = useTourAgentWithdrawalHistoryQuery({
    tourAgentRoleId: role.id,
    limit: rowsPerPage,
    offset: page * rowsPerPage,
  });

  return (
    <WithdrawalHistoryView
      accountType="tour-agent"
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      isLoading={isLoading}
      error={error}
      historyItems={data?.historyItems || []}
      totalItemsCount={data?.meta?.total || 0}
    />
  );
};

const TourAgencyWithdrawalHistory = () => {
  const role = useCurrentRole();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [page, setPage] = useState(0);

  const { data, isLoading, error } = useTourAgencyWithdrawalHistoryQuery({
    tourAgencyId: role.tourAgency?.id || 0,
    roleId: role.id,
    limit: rowsPerPage,
    offset: page * rowsPerPage,
  });

  return (
    <WithdrawalHistoryView
      accountType="tour-agency"
      page={page}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      setPage={setPage}
      isLoading={isLoading}
      error={error}
      historyItems={data?.historyItems || []}
      totalItemsCount={data?.meta?.total || 0}
    />
  );
};

function WithdrawalHistoryView(props: {
  accountType: AccountType;
  page: number;
  setPage: (newPage: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (newCount: number) => void;
  isLoading: boolean;
  error: any;
  historyItems: WithdrawalHistoryItem[];
  totalItemsCount: number;
}) {
  const { accountType, page, isLoading, error, historyItems, totalItemsCount, setPage } = props;
  const { rowsPerPage, setRowsPerPage } = props;
  const columns = useColumns(accountType);
  const translations = useTranslations();

  return (
    <Card sx={{ p: 1, mt: 3 }}>
      <Subtitle sx={{ px: 2, pt: 2, pb: 0 }}>{translations.account.withdrawalHistory()}</Subtitle>

      {error && <ErrorAlert error={error} />}

      {historyItems.length === 0 ? (
        <Stack sx={{ textAlign: 'left', px: 2, mb: 2 }}>
          <Typography>Вы еще не выводили вознаграждения.</Typography>
          <Typography>Все операции вывода средств будут отображены здесь.</Typography>
        </Stack>
      ) : (
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ minHeight: 200 }}>
              {historyItems.map((item: any) => {
                return (
                  <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {columns.map((column) => {
                      if (column.id === 'rewards') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <DownloadButton link={item.csvFileUrl} type="CSV" />
                              <DownloadButton link={item.xlsxFileUrl} type="XLSX" />
                            </Stack>
                          </TableCell>
                        );
                      }

                      const value = item[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {isLoading && (
            <CenterBox sx={{ width: '100%' }}>
              <CircularProgress />
            </CenterBox>
          )}
          <Box sx={{ px: 1 }}>
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 20]}
              onRowsPerPageChange={(e) => {
                setPage(0);
                setRowsPerPage(parseInt(e.target.value, 10));
              }}
              count={totalItemsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, newPage) => setPage(newPage)}
            />
          </Box>
        </TableContainer>
      )}
    </Card>
  );
}

export default function WithdrawalHistory() {
  const permissions = useCurrentRolePermissions();

  if (permissions.isOwner) {
    return <TourAgencyWithdrawalHistory />;
  }

  return <TourAgentWithdrawalHistory />;
}

function StatusLabel(props: { title: string; color: 'primary' | 'info' | 'warning' | 'error' }) {
  const { title, color } = props;
  const theme = useTheme();

  return (
    <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={color}>
      {title}
    </Label>
  );
}
