import { Box, Grid } from '@mui/material';
import Page from 'components/Page';
import { usePageTitle } from 'hooks/ui/usePageTitle';
import { useTranslations } from 'hooks/useTranslations';
import { useMemo, useState } from 'react';
import FaqSidebar from './components/FaqSidebar';
import { FaqSection } from './FaqSection';
import AboutService from './pages/AboutService';
import GettingStarted from './pages/GettingStarted';
import Sravni from './pages/Sravni';

type Props = {};

export default function FaqPage(props: Props) {
  const translations = useTranslations();
  const pageTitle = usePageTitle(translations.docs.title());
  const [selectedSection, setSelectedSection] = useState<FaqSection>('about-us');

  const sections = useMemo(() => {
    return {
      'about-us': () => <AboutService />,
      'getting-started': () => <GettingStarted />,
      'sravni-service': () => <Sravni />,
    };
  }, []);

  const sectionContent = useMemo(() => {
    return sections[selectedSection]();
  }, [sections, selectedSection]);

  return (
    <Page title={pageTitle}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} sm={12}>
          <FaqSidebar selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Box sx={{ p: 3, pt: 0 }}>{sectionContent}</Box>
        </Grid>
      </Grid>
    </Page>
  );
}
