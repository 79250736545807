import { Grid } from '@mui/material';
import CashbackOfferCard from 'components/cashback/CashbackOfferCard';
import Page from 'components/Page';
import { usePageTitle } from 'hooks/ui/usePageTitle';
import { useTranslations } from 'hooks/useTranslations';
import { useCashbackOffersQuery } from 'redux/slices/baseApi';

type Props = {};

export default function CashbackPage(props: Props) {
  const translations = useTranslations();
  const pageTitle = usePageTitle(translations.cashback.title());
  const { data: offers = [] } = useCashbackOffersQuery();

  return (
    <Page title={pageTitle}>
      <Grid container spacing={3} sx={{ px: 2 }}>
        {offers.map((offer) => (
          <CashbackOfferCard key={offer.id} offer={offer} />
        ))}
      </Grid>
    </Page>
  );
}
