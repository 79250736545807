import { useTranslations } from 'hooks/useTranslations';
import { RoleType } from 'types/account';
import { useCurrentRole } from './useCurrentRole';

export const useRoleDescription = (roleType: RoleType | undefined = undefined) => {
  const role = useCurrentRole();
  const type = roleType || role.type;

  const translator = useRoleDescriptionTranslator();
  const roleDescription = translator(type);

  return roleDescription;
};

export const useRoleDescriptionTranslator = () => {
  const translations = useTranslations();

  return (roleType: RoleType) => {
    switch (roleType) {
      case 'self-employed':
        return translations.roles.tourAgent();

      case 'tour-agency-manager':
        return translations.roles.tourAgencyManager();

      case 'tour-agency-admin':
        return translations.roles.tourAgencyAdmin();

      case 'tour-agency-owner':
        return translations.roles.tourAgencyOwner();
    }
  };
};
