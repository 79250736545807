import { useSelector } from 'redux/store';

export const useCurrentRoleId = () => {
  const currentRoleId = useSelector((s) => s.profile.currentRole?.id);

  if (!currentRoleId) {
    throw new Error('User not authorized');
  }

  return currentRoleId;
};
