import { Card, Table, TableRow, TableBody, TableCell, TableHead, CardHeader, TableContainer } from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { useTourAgentSravniSalesHistoryQuery, useTourAgencySravniSalesHistoryQuery } from 'redux/slices/baseApi';
import { useTranslations } from 'hooks/useTranslations';
import { SravniPolicyOrderItem } from './SravniPolicyOrderItem';
import FlightIcon from '@mui/icons-material/Flight';
import { SravniVzrPolicy } from 'types/models/sravni-policy';
import { useCurrentRole } from 'hooks/user/useCurrentRole';

type DisplayMode = 'tour-agent' | 'tour-agency';

type Props = {
  mode: DisplayMode;
};

export default function SravniPolicyOrderList({ mode }: Props) {
  if (mode === 'tour-agency') {
    return <TourAgencyPolicyList />;
  }

  return <TourAgentPolicyList />;
}

function TourAgentPolicyList() {
  const role = useCurrentRole();
  const { data = [] } = useTourAgentSravniSalesHistoryQuery({
    tourAgentRoleId: role.id,
  });

  return <PolicyList mode="tour-agent" policies={data} />;
}

function TourAgencyPolicyList() {
  const role = useCurrentRole();
  const { data = [] } = useTourAgencySravniSalesHistoryQuery({
    roleId: role.id,
    tourAgencyId: role.tourAgency?.id || 0,
  });

  return <PolicyList mode="tour-agency" policies={data} />;
}

function PolicyList(props: { policies: SravniVzrPolicy[]; mode: DisplayMode }) {
  const { policies, mode } = props;
  const translations = useTranslations();

  return (
    <Card sx={{ pb: 3 }}>
      <CardHeader avatar={<FlightIcon />} title={translations.vzr.salesHistory()} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                {mode === 'tour-agency' && <TableCell>{translations.common.manager()}</TableCell>}
                <TableCell>{translations.vzr.destination()}</TableCell>
                <TableCell>{translations.common.price()}</TableCell>
                <TableCell>{translations.common.status()}</TableCell>
                <TableCell>{translations.common.premium()}</TableCell>
                <TableCell>{translations.common.issuedDate()}</TableCell>
                <TableCell>{translations.common.docs()}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {policies.map((policy) => (
                <SravniPolicyOrderItem key={policy.conversionId} item={policy} mode={mode} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
