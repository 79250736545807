import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useTourAgentBalanceQuery } from 'redux/slices/baseApi';

export const useTourAgentBalance = () => {
  const role = useCurrentRole();
  const { data: balance, isLoading: isBalanceLoading } = useTourAgentBalanceQuery(role.id, {
    refetchOnMountOrArgChange: true,
  });

  const availableRewardValue = balance?.availableRewardValue || 0;
  const minimalPayoutValue = balance?.minimalPayoutValue || 100;
  const canPayout = availableRewardValue >= minimalPayoutValue;

  return { balance, isBalanceLoading, canPayout };
};
