import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Alert, DialogContent, Link } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { PATH_DASHBOARD } from 'routes/paths';

export default function MessageSentDialog() {
  const [open, setOpen] = useState(false);
  const translations = useTranslations();
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    navigate(PATH_DASHBOARD.root);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ pb: 0 }}>
          <Alert>{translations.support.messageSuccessfullySent()}</Alert>
        </DialogContent>
        <DialogActions>
          <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.root}>
            <Button onClick={handleClose} variant="outlined" sx={{ textTransform: 'none' }}>
              {translations.common.toHomePage()}
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
}
