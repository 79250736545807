import { CURRENT_ENVIRONMENT } from 'environment';
import { useCallback } from 'react';

const YANDEX_COUNTER_ID = 87583242;

export const useYandexMetrics = () => {
  const yandexMetrics = useCallback((type: string, value?: string) => {
    if (CURRENT_ENVIRONMENT.name !== 'production') {
      return;
    }

    const ym = (window as any).ym;
    ym(YANDEX_COUNTER_ID, type, value);
  }, []);

  return yandexMetrics;
};
