import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { useCallback } from 'react';
import { useDeleteTourAgencyInviteRequest } from 'redux/slices/baseApi';
import { TourAgencyInvite } from 'types/models/tour-agency-invite';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslations } from 'hooks/useTranslations';

type Props = {
  invite: TourAgencyInvite;
};

export default function RemoveInviteButton(props: Props) {
  const { inviteId } = props.invite;
  const currentRole = useCurrentRole();

  const [open, setOpen] = React.useState(false);
  const translations = useTranslations();

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [sendDeleteInviteRequest, { isLoading }] = useDeleteTourAgencyInviteRequest();
  const deleteInvite = useCallback(() => {
    sendDeleteInviteRequest({
      roleId: currentRole.id,
      inviteId,
      tourAgencyId: currentRole.tourAgency?.id || 0,
    });
    handleClose();
  }, [currentRole, inviteId, sendDeleteInviteRequest]);

  return (
    <div>
      <LoadingButton variant="outlined" color="error" loading={isLoading} onClick={handleClickOpen}>
        <DeleteOutlineIcon />
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translations.userManagment.deleteEmployeeInvite()}</DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <DialogContentText id="alert-dialog-description">
            {translations.userManagment.confirmEmployeeInviteDeletion()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" variant="outlined" onClick={handleClose}>
            {translations.common.cancel()}
          </Button>
          <LoadingButton variant="contained" color="error" onClick={deleteInvite} loading={isLoading}>
            {translations.common.delete()}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
