import { CountryCodes } from 'assets/data/countryCodes';

export function getCountryCode(travelCountryEn: string): string {
  const countryCode = CountryCodes[travelCountryEn];

  if (countryCode) {
    return countryCode.toLowerCase();
  }

  return '';
}
