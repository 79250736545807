import * as Yup from 'yup';
import { Stack } from '@mui/material';
import { useProfile } from 'hooks/user/useProfile';
import { useTranslations } from 'hooks/useTranslations';
import { useValidationRules } from 'hooks/ui/useValidationRules';
import { Form, FormikProvider, useFormik } from 'formik';
import { SenderNameField } from '../fields/SenderNameField';
import { EmailInput } from 'components/inputs/EmailInput';
import { SubjectField } from '../fields/SubjectField';
import { SupportMessageField } from '../fields/SupportMessageField';
import { useAskQuestionRequest } from 'redux/slices/baseApi';
import { LoadingButton } from '@mui/lab';
import { getErrorMessage } from 'utils/errors';
import ErrorAlert from 'components/alerts/ErrorAlert';
import MessageSentDialog from '../dialogs/MessageSentDialog';
import { useState } from 'react';

type Values = {
  senderName: string;
  email: string;
  subject: string;
  message: string;
  attachments: { name: string; fileContentBase64: string }[];

  afterSubmit?: string;
};

export default function ContactForm() {
  const translations = useTranslations();
  const profile = useProfile();
  const validationRules = useValidationRules();
  const [askQuestion, { isLoading }] = useAskQuestionRequest();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const SupportFormSchema = Yup.object().shape({
    senderName: validationRules.firstName(),
    email: validationRules.email(),
    subject: validationRules.messageSubject(),
    message: validationRules.messageText(),
    attachments: Yup.array().optional(),
  });

  const formik = useFormik<Values>({
    initialValues: {
      senderName: (profile.lastName || '') + ' ' + (profile.firstName || ''),
      email: profile.email || '',
      subject: '',
      message: '',
      attachments: [],
    },
    validationSchema: SupportFormSchema,
    onSubmit: async (values, { setErrors }) => {
      try {
        await askQuestion({
          name: values.senderName,
          email: values.email,
          subject: values.subject,
          text: values.message,
          attachments: values.attachments,
        }).unwrap();

        setShowSuccessMessage(true);
      } catch (error) {
        console.error(error);
        setErrors({ afterSubmit: getErrorMessage(error) });
      }
    },
  });

  const { errors, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {showSuccessMessage && <MessageSentDialog />}
        <Stack spacing={5} sx={{ px: 5 }}>
          {errors.afterSubmit && <ErrorAlert error={errors.afterSubmit} />}

          <Stack spacing={3}>
            <SenderNameField />
            <EmailInput size={'small'} />
            <SubjectField />
            <SupportMessageField />
          </Stack>

          <LoadingButton size="large" variant="contained" type="submit" loading={isLoading}>
            {translations.common.send()}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
