import { useCallback } from 'react';
import { FieldInputProps, FormikContextType, getIn, useFormikContext } from 'formik';

export type FormikProps = {
  id: string;
  defaultValue: any;
  value: any;
  hasError: boolean;
  helperText: React.ReactNode;
  fieldProps: FieldInputProps<any>;
  setFieldValue(value: any): void;
};

export const useFormikProps = <FormProps>(formik: FormikContextType<FormProps>) => {
  return useCallback(
    (id: string): FormikProps => {
      return {
        id: id,
        value: getIn(formik.values, id),
        defaultValue: getIn(formik.initialValues, id),
        hasError: Boolean(getIn(formik.touched, id) && getIn(formik.errors, id)),
        helperText: getIn(formik.touched, id) && getIn(formik.errors, id),
        fieldProps: formik.getFieldProps(id),
        setFieldValue: (value) => formik.setFieldValue(id, value),
      };
    },
    [formik],
  );
};

export const useFormikInputProps = <FormProps>(id: string) => {
  const formik = useFormikContext<FormProps>();
  const getFormikProps = useFormikProps<FormProps>(formik);
  const formikProps = getFormikProps(id);
  return formikProps;
};
