import { Stack, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { rubBalanceFormatter } from 'utils/money';
import { useTourAgentBalanceQuery } from 'redux/slices/baseApi';
import { useTranslations } from 'hooks/useTranslations';
import { useCurrentRoleId } from 'hooks/user/useCurrentRoleId';
import ClickableArea from 'components/containers/ClickableArea';
import { PATH_PROFILE } from 'routes/paths';

export default function AccountBalanceInfo() {
  const translations = useTranslations();
  const roleId = useCurrentRoleId();
  const { data: balance } = useTourAgentBalanceQuery(roleId, {
    skip: roleId === 0,
  });

  return (
    <Stack direction="row" sx={{ ml: 3 }} spacing={1}>
      <BalanceItem icon={AccountBalanceIcon} title={translations.common.balance()} value={balance?.totalRewardValue} />

      <ClickableArea link={PATH_PROFILE.profile.payout} sx={{ borderRadius: 10 }}>
        <BalanceItem
          icon={CreditCardIcon}
          title={translations.common.available()}
          value={balance?.availableRewardValue}
        />
      </ClickableArea>
    </Stack>
  );
}

function BalanceItem(props: { icon: React.ComponentType<any>; value?: number; title: string }) {
  const { icon: Icon, value, title } = props;
  const color = '#404040';

  return (
    <Stack direction="row" sx={{ px: 2, py: 1 }}>
      <Icon width={20} height={20} style={{ fill: color }} />
      <Typography variant="subtitle1" color={color} sx={{ ml: 1 }} display={{ xs: 'none', sm: 'block' }}>
        {title}:
      </Typography>
      <Typography variant="body1" color={color} sx={{ ml: 1 }}>
        {rubBalanceFormatter.format(value || 0)}
      </Typography>
    </Stack>
  );
}
