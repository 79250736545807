import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useProfile } from 'hooks/user/useProfile';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback, useState } from 'react';
import { useProfileQuery, useResendConfirmationEmailRequest } from 'redux/slices/baseApi';
import { updateProfile } from 'redux/slices/profile';
import { useDispatch } from 'redux/store';
import { getErrorCode } from 'utils/errors';

export const ConfirmEmailDialog = () => {
  const translations = useTranslations();
  const [isConfirmationEmailResend, setIsConfirmationEmailResend] = useState(false);
  const [resendConfirmationEmail, { isLoading }] = useResendConfirmationEmailRequest();
  const profile = useProfile();
  const dispatch = useDispatch();

  useProfileQuery(profile.id, {
    pollingInterval: 5_000,
  });

  const resendConfirmation = useCallback(async () => {
    try {
      await resendConfirmationEmail().unwrap();
      setIsConfirmationEmailResend(true);
    } catch (error) {
      const errorCode = getErrorCode(error);
      console.log(errorCode);

      if (errorCode === 'EMAIL_ALREADY_CONFIRMED') {
        dispatch(updateProfile({ ...profile, isEmailConfirmed: true }));
      }
    }
  }, [profile, dispatch, resendConfirmationEmail]);

  return (
    <Dialog fullWidth onClose={() => {}} open={true} maxWidth="sm" sx={{ backdropFilter: 'blur(5px)' }}>
      <img
        src="/static/illustrations/confirm-email.png"
        alt="Airplane in the sky"
        style={{ maxHeight: 350, objectFit: 'cover' }}
      />
      <DialogTitle>{translations.confirmEmailDialog.title()}</DialogTitle>
      <DialogContent>
        <Typography sx={{ mt: 2 }}>{translations.confirmEmailDialog.emailSent()}</Typography>
        <Typography>
          {translations.confirmEmailDialog.goToLink()} <strong>{translations.product.name()}!</strong>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          style={{ textTransform: 'none' }}
          onClick={resendConfirmation}
          disabled={isLoading || isConfirmationEmailResend}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : isConfirmationEmailResend ? (
            translations.confirmEmailDialog.activationEmailSent()
          ) : (
            translations.confirmEmailDialog.sentEmailAgain()
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
