import { Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

type Props = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function Subtitle({ children, sx }: Props) {
  return (
    <Typography variant="overline" sx={{ mb: 3, display: 'block', color: 'text.secondary', ...sx }}>
      {children}
    </Typography>
  );
}
