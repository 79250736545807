import { Alert } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { useCurrentRole } from 'hooks/user/useCurrentRole';
import { Fragment } from 'react';
import { AccountBalance } from 'types/models/account-balance';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useTourAgencyBalance } from 'hooks/tour-agency/useTourAgencyBalance';
import { useTourAgentBalance } from 'hooks/tour-agency/useTourAgentBalance';
import { PaymentMethod } from './payment-method';
import WithdrawInfo from './WithdrawInfo';

type Props = {
  paymentMethod: PaymentMethod;
  sx?: SxProps<Theme>;
};

export default function WithdrawAmountAlert(props: Props) {
  const role = useCurrentRole();

  if (role.tourAgency) {
    return <TourAgencyWithdrawAmountAlert {...props} />;
  }

  return <TourAgentWithdrawAmountAlert {...props} />;
}

function TourAgentWithdrawAmountAlert(props: Props) {
  const { sx, paymentMethod } = props;
  const { balance, canPayout, isBalanceLoading } = useTourAgentBalance();

  return (
    <WithdrawAmountAlertView
      balance={balance}
      canPayout={canPayout}
      isLoading={isBalanceLoading}
      paymentMethod={paymentMethod}
      sx={sx}
    />
  );
}

function TourAgencyWithdrawAmountAlert(props: Props) {
  const { sx, paymentMethod } = props;
  const { balance, canPayout, isBalanceLoading } = useTourAgencyBalance();

  return (
    <WithdrawAmountAlertView
      balance={balance}
      canPayout={canPayout}
      isLoading={isBalanceLoading}
      paymentMethod={paymentMethod}
      sx={sx}
    />
  );
}

type WithdrawAmountProps = {
  isLoading: boolean;
  balance: AccountBalance | undefined;
  canPayout: boolean;
  paymentMethod: PaymentMethod;
  sx?: SxProps<Theme>;
};

function WithdrawAmountAlertView(props: WithdrawAmountProps) {
  const { balance, canPayout, isLoading, paymentMethod, sx } = props;

  if (isLoading || !balance) {
    return <Fragment />;
  }

  return (
    <Alert icon={canPayout ? null : <PriorityHighIcon />} color={canPayout ? 'success' : 'warning'} sx={sx}>
      <WithdrawInfo paymentMethod={paymentMethod} />
    </Alert>
  );
}
