import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';
import { PATH_AUTH } from '../../routes/paths';
import Page from '../../components/Page';
import { useTranslations } from 'hooks/useTranslations';
import ChangePasswordForm from 'components/authentication/change-password/ChangePasswordForm';

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export default function ChangePassword() {
  const location = useLocation();
  const translations = useTranslations();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');

  return (
    <RootStyle title={`${translations.changePassword.title()} | ${translations.product.name()}`}>
      <LogoOnlyLayout />

      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          <Typography variant="h3" paragraph>
            {translations.changePassword.title()}
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 5 }}>{translations.changePassword.description()}</Typography>

          <ChangePasswordForm token={token || ''} />

          <Button fullWidth size="large" component={RouterLink} to={PATH_AUTH.login} sx={{ mt: 1 }}>
            {translations.common.back()}
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
