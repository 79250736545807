import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Role } from 'types/account';
import { DialogContent, Divider, Stack } from '@mui/material';
import { CashbackOffer } from 'types/models/cashback-offer';
import CashbackOfferCardInfoItem from './CashbackOfferCardInfoItem';
import Markdown from 'components/text/Markdown';

type RolesDialogProps = {
  open: boolean;
  offer: CashbackOffer;
  onClose: (role?: Role) => void;
};

function OfferDialog(props: RolesDialogProps) {
  const { offer, onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{offer.name}</DialogTitle>
      <Divider sx={{ mt: 2 }} />

      <DialogContent>
        <Stack direction="row" spacing={3} sx={{ mb: 3 }}>
          <img
            src={offer.imageUrl}
            alt={offer.name}
            style={{
              width: 150,
              objectFit: 'contain',
              border: '1px solid #eee',
              borderRadius: 8,
              backgroundColor: offer.backgroundColor || 'white',
            }}
          />

          <Stack spacing={0.5} sx={{ flexGrow: 1, py: 0.5, pr: 1 }}>
            <CashbackOfferCardInfoItem title={'Ставка'} value={`${offer.paymentRate}`} fontSize={'medium'} />
            <CashbackOfferCardInfoItem
              title={'Срок подтверждения'}
              value={`${offer.averageProcessingPeriodInDays} д.`}
              fontSize={'medium'}
            />
            <CashbackOfferCardInfoItem
              title={'Срок оплаты'}
              value={`${offer.averagePaymentPeriodInDays} д.`}
              fontSize={'medium'}
            />
            <CashbackOfferCardInfoItem title={'% подтверждения'} value={`${offer.approveRate}%`} fontSize={'medium'} />
          </Stack>
        </Stack>

        <Markdown content={offer.description} />
      </DialogContent>
    </Dialog>
  );
}

type Props = {
  offer: CashbackOffer;
  children: React.ReactNode;
  onDialogOpen?: () => void;
};

export default function CashbackOfferDetailsDialog(props: Props) {
  const { offer, children, onDialogOpen } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onDialogOpen && onDialogOpen();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div onClick={handleClickOpen}>{children}</div>
      <OfferDialog offer={offer} open={open} onClose={handleClose} />
    </div>
  );
}
