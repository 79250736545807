import { CSSProperties, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: { position: 'relative' },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& > *': { height: '100%', width: '100%' },
  },
}));

type Props = {
  children: ReactNode;
  ratio: number;
  style?: CSSProperties | undefined;
  onClick?: () => void;
};

const AspectRatioBox = ({ children, ratio = 1, style, onClick }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={onClick} style={style}>
      <div className={classes.wrapper}>{children}</div>
      <div style={{ paddingBottom: (1 / ratio) * 100 + '%' }} />
    </div>
  );
};

export default AspectRatioBox;
