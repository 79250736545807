import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParam = (paramName: string, defaultValue = '') => {
  const location = useLocation();

  const value = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const value = searchParams.get(paramName);
    const resultValue = value || defaultValue;

    return resultValue;
  }, [location, paramName, defaultValue]);

  return value;
};
