import { useCallback } from 'react';
import { useOpenCloudStorageFileRequest } from 'redux/slices/baseApi';

export const useCloudStorageLink = () => {
  const [getFileLink, { isLoading, error }] = useOpenCloudStorageFileRequest();

  const openLink = useCallback(async (url: string) => {
    try {
      const { signedUrl } = await getFileLink(url).unwrap();
      window.open(signedUrl, '_blank')?.focus();
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { openLink, isLoading, error };
};
