import { CircularProgress, Stack } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import CashbackButton from './CashbackButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type OpenLinkCashbackButtonType = {
  isOpeningDeepLink: boolean;
  openDeepLink: () => Promise<void> | boolean;
};

const OpenLinkCashbackButton: React.FC<OpenLinkCashbackButtonType> = ({ isOpeningDeepLink, openDeepLink }) => {
  const translations = useTranslations();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', color: '#0079E9', height: '100%', boxSizing: 'border-box' }}
    >
      <CashbackButton
        startIcon={
          isOpeningDeepLink ? (
            <CircularProgress size={14} color="inherit" />
          ) : (
            <OpenInNewIcon sx={{ fontSize: 14 }} color="inherit" />
          )
        }
        onClick={openDeepLink}
        text={translations.common.bookManually()}
        border="1px solid #0079E9"
        colorText="#0079E9"
        colorButton="info"
      />
    </Stack>
  );
};

export default OpenLinkCashbackButton;
