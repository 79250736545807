import { useTranslations } from 'hooks/useTranslations';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { PATH_PROFILE } from 'routes/paths';
import { useErrorSnackbar } from './useErrorSnackbar';
import { useSuccessSnackbar } from './useSuccessSnackbar';

export const useWithdrawalResultHandler = () => {
  const translations = useTranslations();
  const showSuccessSnackbar = useSuccessSnackbar();
  const showErrorSnackbar = useErrorSnackbar();
  const navigate = useNavigate();

  const handleResult = useCallback(
    (request: Promise<any>) => {
      return request
        .then(() => {
          showSuccessSnackbar(translations.account.withdrawalRequestCreated());
          navigate(PATH_PROFILE.profile.withdrawalHistory);
        })
        .catch(() => showErrorSnackbar(translations.account.withdrawalRequestError()));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [navigate, showErrorSnackbar, showSuccessSnackbar, translations],
  );

  return handleResult;
};
