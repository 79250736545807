import { Icon } from '@iconify/react';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import { PATH_AUTH } from '../../routes/paths';

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Войти',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_AUTH.login,
  },
];

export default menuConfig;
