import useCollapseDrawer from 'hooks/useCollapseDrawer';

export const DRAWER_WIDTH = 280;
export const COLLAPSE_DRAWER_WIDTH = 102;

export const useCurrentDrawerWidth = () => {
  const { isCollapse } = useCollapseDrawer();
  const drawerWidth = isCollapse ? COLLAPSE_DRAWER_WIDTH : DRAWER_WIDTH;
  return drawerWidth;
};
