import React, { useState, useEffect, useCallback, useRef, FC, useMemo } from 'react';
import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  TextField,
} from '@mui/material';
import { Role } from 'types/account';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CashbackOffer } from 'types/models/cashback-offer';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/system';
import { useValidationRules } from 'hooks/ui/useValidationRules';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProps, FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { WebsiteInput } from 'components/inputs/WebsiteInput';
import { useTranslations } from 'hooks/useTranslations';

type RolesDialogProps = {
  open: boolean;
  offer: CashbackOffer;
  onClose: (role?: Role) => void;
};

type TouristLink = {
  link: string;
  loading: boolean;
  error: string;
};

type CashbackLink = {
  link: string;
  cashbackLink: string;
  loading: boolean;
  error: string;
};

type InitialValue = {
  website: string;
  cashback: string;
};

type ICashback = {
  changeStep: (step: number) => void;
  changeLoading: (state: CashbackLink) => void;
  placeholder: string;
  cashbackLink: CashbackLink;
  name: string;
};

async function getCashbackLink2(link: string) {
  let newLink: string = await new Promise((resolve, reject) => setTimeout(() => resolve(`${link}-cashback`), 500));
  return newLink;
}

const Cashback: FC<ICashback & Partial<FormikProps<InitialValue>>> = (props) => {
  const { cashbackLink, name, changeStep, changeLoading } = props;
  const {
    values: { website, cashback },
    setFieldValue,
  } = useFormikContext<InitialValue>();
  const [field] = useField(props);

  const inputRef = useRef<HTMLInputElement | null>();
  const copyNewLink = () => {
    if (inputRef.current) {
      inputRef.current.querySelector('input')?.select();
      document.execCommand('copy');
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> = setTimeout(() => {}, 0);
    let isCurrent = true;

    function getCashbackUrl() {
      if (website.trim() !== '') {
        changeStep(1);
        changeLoading({ ...cashbackLink, loading: true });
        getCashbackLink2(website).then((cashback) => {
          if (isCurrent) {
            setFieldValue(name, cashback);
            changeStep(2);
          }

          changeLoading({ ...cashbackLink, loading: false });
        });
      }
    }

    clearTimeout(timer);
    timer = setTimeout(getCashbackUrl, 500);

    return () => {
      isCurrent = false;
    };
  }, [cashbackLink, changeLoading, changeStep, name, setFieldValue, website]);

  return (
    <>
      <TextField
        fullWidth
        {...props}
        {...field}
        placeholder={props.placeholder}
        size="small"
        sx={{ mt: 2 }}
        InputProps={{
          readOnly: true,
          disabled: !cashback,
          ref: inputRef,
          onClick: copyNewLink,
          startAdornment: (
            <InputAdornment sx={{ color: '#000' }} position="start">
              <InsertLinkIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button
        onClick={copyNewLink}
        sx={{ mt: 2, textTransform: 'initial' }}
        disabled={props.cashbackLink.loading || cashback.trim().length === 0}
        startIcon={<ContentCopyIcon />}
        variant="contained"
      >
        Скопировать ссылку
      </Button>
    </>
  );
};

function OfferDialog(props: RolesDialogProps) {
  const { offer, onClose, open } = props;
  const validationRules = useValidationRules();
  const translations = useTranslations();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [touristLink, setTouristLink] = useState<TouristLink>({
    link: '',
    loading: false,
    error: '',
  });
  const [cashbackLink, setCashbackLink] = useState<CashbackLink>({
    link: '',
    cashbackLink: '',
    loading: false,
    error: '',
  });

  const WebsiteSchema = Yup.object().shape({
    website: validationRules.url(),
    cashback: validationRules.url(),
  });

  const formik = useFormik<InitialValue>({
    initialValues: {
      website: '',
      cashback: '',
    },
    validationSchema: WebsiteSchema,
    onSubmit: (values) => {
      // console.log(values);
    },
    validateOnChange: true,
  });
  const handleClose = () => {
    onClose();
  };

  const changeStep = (step: number) => {
    setActiveStep(step);
  };

  const goToWebsite = useCallback(async () => {
    setTouristLink({ ...touristLink, loading: true });
    let a = document.createElement('a');
    a.target = '_blank';
    await new Promise<string>((resolve, reject) => setTimeout(() => resolve('https://google.com'), 500))
      .then((response: string) => {
        setTouristLink({ ...touristLink, error: '', loading: false, link: response });
        a.href = response;
        a.click();
        changeStep(1);
      })
      .catch((err) => setTouristLink({ ...touristLink, error: translations.common.cashbackLinkError() }));
  }, [touristLink, translations]);

  const steps = useMemo(
    () => [
      {
        title: translations.common.findOptions(),
        text: translations.common.chooseForTourist(),
        action: (
          <>
            {touristLink.error && (
              <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                {touristLink.error}
              </Typography>
            )}
            <LoadingButton
              startIcon={<OpenInNewIcon sx={{ fontSize: 14 }} color="inherit" />}
              onClick={goToWebsite}
              loading={activeStep === 0 && touristLink.loading}
              variant="contained"
              sx={{ mt: 1 }}
            >
              Перейти на {offer.name}
            </LoadingButton>
            <br />
          </>
        ),
      },
      {
        title: translations.common.makeCashbackLink(),
        text: translations.common.copyCashbackLink(),
        action: (
          <Form autoComplete="off">
            <WebsiteInput
              sx={{ mt: 1 }}
              placeholder={translations.common.insertTouristOption()}
              size="small"
              InputProps={{
                error: Boolean(cashbackLink.error),
                disabled: activeStep === 0 || cashbackLink.loading,
                startAdornment: (
                  <InputAdornment position="start">
                    <InsertLinkIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Form>
        ),
      },
      {
        title: translations.common.sendCahsbackLink(),
        text: translations.common.afterSendingCashback(),
        action: (
          <Cashback
            placeholder={translations.common.cashbackLinkHere()}
            changeStep={changeStep}
            cashbackLink={cashbackLink}
            changeLoading={setCashbackLink}
            name="cashback"
          />
        ),
      },
    ],
    [translations, touristLink.error, touristLink.loading, goToWebsite, activeStep, offer.name, cashbackLink],
  );

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle sx={{ mb: 2 }} id="alert-dialog-title">
        Cashback-ссылка для туриста
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormikProvider value={formik}>
          <Stepper sx={{ alignItems: 'baseline' }} orientation="vertical" activeStep={activeStep}>
            {steps.map((step, index) => {
              return (
                <Step expanded sx={{ alignItems: 'baseline' }} key={`${index}step`}>
                  <StepLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                      <Typography sx={{ fontWeight: '700', fontSize: '16px', mr: 2 }}>{step.title}</Typography>
                      {activeStep === 1 && index === 1 && cashbackLink.loading && (
                        <Box sx={{ color: 'rgba(0, 171, 85, 1)' }}>
                          <CircularProgress size={20} color="inherit" />
                        </Box>
                      )}
                    </Box>
                  </StepLabel>
                  <StepContent>
                    <Typography
                      sx={{
                        color: 'rgba(33, 43, 54, 0.68)',
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: '400',
                        ml: 1,
                      }}
                    >
                      {step.text}
                    </Typography>
                  </StepContent>
                  {step.action && (
                    <StepContent>
                      <Box sx={{ ml: 1 }}>{step.action}</Box>
                    </StepContent>
                  )}
                </Step>
              );
            })}
          </Stepper>
        </FormikProvider>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          sx={{ color: 'rgba(33, 43, 54, 1)', border: '1px solid rgba(145, 158, 171, 0.32)' }}
          variant="outlined"
          onClick={handleClose}
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type Props = {
  offer: CashbackOffer;
  children: React.ReactNode;
  onDialogOpen?: () => void;
  onDialogClose?: () => void;
};

export default function CashbackTouristLinkDialog(props: Props) {
  const { offer, children, onDialogOpen, onDialogClose } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onDialogOpen && onDialogOpen();
  };

  const handleClose = () => {
    setOpen(false);
    onDialogClose && onDialogClose();
  };

  return (
    <div style={{ margin: 0, padding: 0, width: '100%', height: '100%' }}>
      <div style={{ margin: 0, padding: 0, height: '100%' }} onClick={handleClickOpen}>
        {children}
      </div>
      <OfferDialog offer={offer} open={open} onClose={handleClose} />
    </div>
  );
}
