import { TextField } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { useTranslations } from 'hooks/useTranslations';
import { useFormikInputProps } from 'utils/ui/formik';

type Props = {
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
};

export const MiddleNameInput = (props: Props) => {
  const { sx, size } = props;
  const translations = useTranslations();
  const { fieldProps, hasError, helperText } = useFormikInputProps('middleName');

  return (
    <TextField
      {...fieldProps}
      fullWidth
      size={size || 'small'}
      label={translations.common.patronymic()}
      sx={sx}
      error={hasError}
      helperText={helperText}
    />
  );
};
