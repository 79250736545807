import { SxProps, Theme } from '@mui/system';
import TextField from '@mui/material/TextField';
import { useTranslations } from 'hooks/useTranslations';
import { useFormikInputProps } from 'utils/ui/formik';

type Props = {
  sx?: SxProps<Theme>;
};

export function SubjectField(props: Props) {
  const { sx } = props;
  const { value, hasError, helperText, fieldProps } = useFormikInputProps('subject');
  const translations = useTranslations();

  return (
    <TextField
      {...fieldProps}
      value={value || ''}
      fullWidth
      label={translations.common.subject()}
      helperText={helperText}
      error={hasError}
      sx={sx}
    />
  );
}
