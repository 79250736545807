import { Alert, Button, Card, Divider, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import PaymentStepTitle from './PaymentStepTitle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useDispatch } from 'redux/store';
import { updateOverprotectionPaymentTransaction } from 'redux/slices/policy';
import { ConfirmTestPaymentButton } from './ConfirmTestPaymentButton';

type Props = {
  currentStep: number;
  isTransactionSucceed: boolean;
  changeStep(step: number): void;
};

export default function PaymentConfirmationCard(props: Props) {
  const stepIndex = 3;
  const { currentStep, isTransactionSucceed, changeStep } = props;
  const dispatch = useDispatch();
  const translations = useTranslations();

  return (
    <Card sx={{ py: 3, px: 3, mt: 3 }}>
      <PaymentStepTitle
        index={3}
        title={translations.policy.travelCancellation.paymentConfirmation()}
        isActive={currentStep >= stepIndex}
        showLoader={currentStep === stepIndex && !isTransactionSucceed}
        isStepCompleted={isTransactionSucceed}
      />

      {currentStep === stepIndex && !isTransactionSucceed && (
        <>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Alert color="info">
            <Typography>{translations.policy.travelCancellation.paymentConfirmationDescription()}</Typography>
          </Alert>

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Alert color="warning">
            <Typography>{translations.policy.travelCancellation.paymentError()}</Typography>
          </Alert>

          <Button
            variant="contained"
            color="warning"
            sx={{ mt: 3, mb: 1 }}
            style={{ textTransform: 'none' }}
            size={'large'}
            onClick={() => {
              dispatch(updateOverprotectionPaymentTransaction(undefined));
              changeStep(1);
            }}
          >
            <AutorenewIcon sx={{ mr: 1 }} />
            {translations.policy.travelCancellation.createNewPaymentTransaction()}
          </Button>

          <ConfirmTestPaymentButton />
        </>
      )}
    </Card>
  );
}
