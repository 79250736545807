import * as React from 'react';
import { useState, forwardRef, useRef, useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import { TextField } from '@mui/material';
import { FormikProps } from 'utils/ui/formik';
import { useTranslations } from 'hooks/useTranslations';

interface PhoneInputComponentProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = forwardRef<HTMLElement, PhoneInputComponentProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  const maskProps = {
    mask: '+7 (9##) ### ## ##',
    definitions: {
      '#': /[0-9]/,
    },
    inputRef: ref,
    onAccept: (value: any) => onChange({ target: { name: props.name, value } }),
    overwrite: true,
  };

  return <IMaskInput {...other} {...maskProps} />;
});

type Props = {
  formikProps: FormikProps;
};

const phoneSymbolsRegex = /[+0-9]/;
const phoneStart = '+7 (9';

export function PhoneInput(props: Props) {
  const { formikProps } = props;

  const [phone, setPhone] = useState('');
  const textInputRef = useRef(null);
  const translations = useTranslations();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);

    const phoneValue = value
      .split('')
      .filter((c) => phoneSymbolsRegex.test(c))
      .join('');
    formikProps.setFieldValue(phoneValue);
  };

  useEffect(() => {
    const element = textInputRef?.current as any;

    if (phone === phoneStart && element) {
      const position = phoneStart.length;
      element.setSelectionRange(position, position);
      setTimeout(() => element.setSelectionRange(position, position), 10);
    }
  }, [phone]);

  return (
    <TextField
      fullWidth
      label={translations.common.mobilePhone()}
      value={phone}
      inputRef={textInputRef}
      onChange={handleChange}
      name={formikProps.fieldProps.name}
      id="phone-input"
      onFocus={(e: any) => {
        if (!phone) {
          setPhone(phoneStart);
        }
      }}
      error={formikProps.hasError}
      helperText={formikProps.helperText}
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
      variant="outlined"
    />
  );
}
