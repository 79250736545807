import { Button } from '@mui/material';
import { ReactNode } from 'react-markdown/lib/react-markdown';

type CashbackButtonType = {
  onClick?: () => void;
  startIcon: ReactNode;
  border: string;
  colorText: string;
  colorButton: 'info' | 'success';
  text: string;
};

const CashbackButton: React.FC<CashbackButtonType> = ({
  onClick = () => void 0,
  startIcon,
  border,
  colorText,
  colorButton,
  text,
}) => {
  return (
    <Button
      onClick={onClick || void 0}
      startIcon={startIcon}
      variant="outlined"
      size="large"
      color={colorButton}
      fullWidth
      sx={{
        m: 0.1,
        flexGrow: 1,
        fontSize: '0.75rem',
        padding: '0 5px',
        textTransform: 'initial',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255, 0.8)',
        border: border,
        borderRadius: '10px',
        color: colorText,
      }}
    >
      {text}
    </Button>
  );
};

export default CashbackButton;
