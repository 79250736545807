import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { CURRENT_ENVIRONMENT } from 'environment';
import { logout, updateProfile } from '../profile';
import { prepareHeaders } from './headers';
import { tokenStorage } from './token-storage';

const baseQuery = fetchBaseQuery({
  baseUrl: CURRENT_ENVIRONMENT.baseUrl,
  prepareHeaders,
});

export const AUTH_PATHS = {
  signIn: 'auth/sign-in',
  tourAgentSignUp: 'auth/sign-up/tour-agent',
  tourAgencySignUp: 'auth/sign-up/tour-agency',
  refreshTokens: 'auth/refresh-tokens',
  confirmEmail: 'auth/confirm-email',
  updateProfile: 'users',
};

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    try {
      await refreshTokens(api, extraOptions);
    } catch (error) {
      console.error(error);
      api.dispatch(logout());
      return result;
    }

    result = await baseQuery(args, api, extraOptions);
  }

  updateTokensInterceptor(args, result);
  updateProfileInterceptor(args, api, result);

  return result;
};

const refreshTokens = async (api: BaseQueryApi, extraOptions: {}) => {
  const refreshResult = await baseQuery(
    {
      url: AUTH_PATHS.refreshTokens,
      method: 'PATCH',
      body: {
        access_token: tokenStorage.getAccessToken(),
        refresh_token: tokenStorage.getRefreshToken(),
      },
    },
    api,
    extraOptions,
  );

  if (refreshResult.error?.status === 401) {
    api.dispatch(logout());
    return;
  }

  if (refreshResult.data) {
    updateTokens(refreshResult);
    updateProfileState(refreshResult, api);
    return;
  }

  throw new Error('Failed to refresh access token');
};

const updateProfileInterceptor = (args: string | FetchArgs, api: BaseQueryApi, result: QueryReturnValue) => {
  const url = typeof args === 'string' ? args : args.url;

  if ([AUTH_PATHS.signIn, AUTH_PATHS.tourAgentSignUp, AUTH_PATHS.tourAgencySignUp].includes(url)) {
    updateProfileState(result, api);
  }

  if (AUTH_PATHS.confirmEmail === url && result.data) {
    updateProfileState(result, api);
  }

  const isGetProfileRequest = url?.match(/users\?user-id=\d+$/);
  if (isGetProfileRequest && result.data) {
    api.dispatch(updateProfile(result.data as any));
  }

  if (AUTH_PATHS.updateProfile === url && result.data) {
    api.dispatch(updateProfile(result.data as any));
  }
};

const updateTokensInterceptor = (args: string | FetchArgs, result: QueryReturnValue) => {
  const url = typeof args === 'string' ? args : args.url;

  if ([AUTH_PATHS.signIn, AUTH_PATHS.tourAgentSignUp, AUTH_PATHS.tourAgencySignUp].includes(url)) {
    updateTokens(result);
  }
};

const updateProfileState = (result: QueryReturnValue, api: BaseQueryApi) => {
  const data = result.data as any;

  if (data && data.user) {
    api.dispatch(updateProfile(data.user));
  }
};

const updateTokens = (result: QueryReturnValue) => {
  const data = result.data as any;

  if (data) {
    const { access_token, refresh_token } = data;
    tokenStorage.saveAccessToken(access_token);
    tokenStorage.saveRefreshToken(refresh_token);
  }
};
