import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role, User } from '../../../types/account';
import { ProfileState } from '../../../types/states/profile-state';
import { logout } from './thunks/logout-thunk';

const initialState: ProfileState = {
  profile: null,
  currentRole: null,
  showVzrPolicyContactInfoNotification: true,
  showVzrPolicyRewardInfoNotification: true,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<User | null>) => {
      const profile = action.payload;

      state.profile = profile;

      const selectedCurrentRoleId = state.currentRole?.id;
      const selectedCurrentRole = profile?.roles && profile.roles.find((r) => r.id === selectedCurrentRoleId);
      const currentRole = selectedCurrentRole || profile?.roles[0] || null;
      state.currentRole = currentRole;
    },

    selectRole: (state, action: PayloadAction<Role>) => {
      state.currentRole = action.payload;
    },

    hideVzrPolicyContactInfoNotification: (state) => {
      state.showVzrPolicyContactInfoNotification = false;
    },

    hideVzrPolicyRewardInfoNotification: (state) => {
      state.showVzrPolicyRewardInfoNotification = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.profile = null;
      state.currentRole = null;
    });
  },
});

export const { updateProfile, selectRole, hideVzrPolicyContactInfoNotification, hideVzrPolicyRewardInfoNotification } =
  profileSlice.actions;

export { logout };

export default profileSlice.reducer;
