import { useSelector } from 'redux/store';

export const useProfile = () => {
  const profile = useSelector((s) => s.profile.profile);

  if (!profile) {
    throw new Error('User not authorized');
  }

  return profile;
};
