import { Fragment, useCallback, useEffect } from 'react';
import { Card, Divider } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { useSubmitOverprotectionPolicyRequest } from 'redux/slices/baseApi';
import PaymentStepTitle from './PaymentStepTitle';
import { useDispatch, useSelector } from 'redux/store';
import { updateOverprotectionPolicy } from 'redux/slices/policy';
import { OverprotectionPolicy } from '../../../../types/models/overprotection-policy';
import ErrorAlert from 'components/alerts/ErrorAlert';

type Props = {
  currentStep: number;
  changeStep(step: number): void;
};

export default function PolicySubmissionCard(props: Props) {
  const stepIndex = 2;
  const { currentStep, changeStep } = props;
  const translations = useTranslations();
  const dispatch = useDispatch();

  const policyProcessId = useSelector((s) => s.policy.overprotectionPolicyProcessId);
  const policy = useSelector((s) => s.policy.overprotectionPolicy);

  const [submitPolicy, { isLoading, error, data }] = useSubmitOverprotectionPolicyRequest();

  const isCurrentStep = currentStep === stepIndex;
  const isSubmitted = data?.status === 'submitted';

  const sendSubmitPolicyRequest = useCallback(async () => {
    if (!policyProcessId || !isCurrentStep || isSubmitted) return;

    const result = await submitPolicy({ policyProcessId }).unwrap();
    const isPolicySubmitted = result?.status === 'submitted';

    if (isPolicySubmitted && policy) {
      const updatedPolicy: OverprotectionPolicy = { ...policy, state: 'submitted' };
      dispatch(updateOverprotectionPolicy(updatedPolicy));
    }

    changeStep(stepIndex + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep, isSubmitted, policyProcessId, submitPolicy]);

  useEffect(() => {
    sendSubmitPolicyRequest();
  }, [sendSubmitPolicyRequest]);

  return (
    <Card sx={{ py: 3, px: 3, mt: 3 }}>
      <PaymentStepTitle
        index={stepIndex}
        title={
          !isSubmitted
            ? translations.policy.travelCancellation.submitPolicyToRss()
            : translations.policy.travelCancellation.policySubmittedToRss()
        }
        isActive={currentStep >= stepIndex}
        showLoader={isLoading}
        isStepCompleted={isSubmitted}
      />

      {error && (
        <Fragment>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <ErrorAlert error={error} retry={sendSubmitPolicyRequest} />
        </Fragment>
      )}
    </Card>
  );
}
