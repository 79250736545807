import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import { baseApi } from './slices/baseApi';
import profileReducer from './slices/profile';
import policyReducer from './slices/policy';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  profile: persistReducer(
    {
      key: 'profile',
      storage,
      keyPrefix: 'redux-',
      whitelist: [
        'profile',
        'currentRole',
        'showVzrPolicyContactInfoNotification',
        'showVzrPolicyRewardInfoNotification',
      ],
    },
    profileReducer,
  ),
  policy: persistReducer(
    {
      key: 'policy',
      storage,
      keyPrefix: 'redux-',
      whitelist: [
        'overprotectionPolicyProcessId',
        'overprotectionCalculation',
        'overprotectionPolicy',
        'overprotectionPaymentTransaction',
      ],
    },
    policyReducer,
  ),
  user: userReducer,
});

export { rootPersistConfig, rootReducer };
