import { Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

type Props = {
  title: string;
  value: string | undefined;
  sx?: SxProps<Theme>;
};

export default function DataItem(props: Props) {
  const { title, value, sx } = props;

  return (
    <Typography variant="body1" sx={{ my: 1, ...sx }}>
      <b>{title + ':'}</b> {value || ''}
    </Typography>
  );
}
