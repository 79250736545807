export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const InputConfig = {
  ogrnLength: 13,
  bikLength: 9,
  correspondentAccountLength: 20,
  paymentAccountLength: 20,
  kppLength: 9,
};

export const LINKS = {
  termsOfUse: 'https://storage.cloud.google.com/touragent-club-docs/TourAgentClub.pdf',
};
