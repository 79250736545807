import { LoadingButton } from '@mui/lab';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { useTranslations } from 'hooks/useTranslations';
import ConfirmWithdrawDialog from './ConfirmWithdrawDialog';
import { PaymentMethod } from './payment-method';

type Props = {
  paymentMethod: PaymentMethod;
  canWithdraw: boolean;
  isLoading: boolean;
  withdraw: () => Promise<any>;
};

export default function WithdrawalButton(props: Props) {
  const { canWithdraw, paymentMethod, isLoading, withdraw } = props;
  const translations = useTranslations();

  return (
    <ConfirmWithdrawDialog
      withdraw={withdraw}
      paymentMethod={paymentMethod}
      isLoading={isLoading}
      canWithdraw={canWithdraw}
    >
      <LoadingButton
        variant="contained"
        size="large"
        sx={{ textTransform: 'none', mt: 2, mb: 4 }}
        disabled={!canWithdraw}
      >
        <ExitToAppOutlinedIcon sx={{ mr: 1 }} />
        {translations.account.doPayout()}
      </LoadingButton>
    </ConfirmWithdrawDialog>
  );
}
