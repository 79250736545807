import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import roundReceipt from '@iconify/icons-ic/round-receipt';
// import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
import { PATH_PROFILE } from '../../routes/paths';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { logout } from 'redux/slices/profile';
import { useDispatch } from 'redux/store';
import { useTranslations } from 'hooks/useTranslations';
import { useCurrentRolePermissions } from 'hooks/user/useCurrentRolePermissions';
import { conditionalItem } from 'utils/conditionalItem';

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { profile } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const translations = useTranslations();
  const permissions = useCurrentRolePermissions();

  const MENU_OPTIONS = useMemo(
    () => [
      {
        label: translations.account.profile(),
        icon: roundAccountBox,
        linkTo: PATH_PROFILE.profile.edit,
      },
      ...conditionalItem(permissions.canPayout, {
        label: translations.account.payout(),
        icon: roundReceipt,
        linkTo: PATH_PROFILE.profile.payout,
      }),
      // {
      //   label: 'Settings',
      //   icon: settings2Fill,
      //   linkTo: PATH_PROFILE.profile.edit,
      // },
    ],
    [translations, permissions],
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout()).unwrap();
      if (isMountedRef.current) {
        navigate('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {profile?.firstName || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profile?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout} style={{ textTransform: 'none' }}>
            {translations.common.logout()}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
