import { useState } from 'react';
import { Icon } from '@iconify/react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormikInputProps } from 'utils/ui/formik';
import { useTranslations } from 'hooks/useTranslations';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { SxProps, Theme } from '@mui/system';

type Props = {
  sx?: SxProps<Theme>;
};

export const PasswordInput = (props: Props) => {
  const { sx } = props;
  const translations = useTranslations();
  const [showPassword, setShowPassword] = useState(false);
  const { fieldProps, hasError, helperText } = useFormikInputProps('password');

  return (
    <TextField
      {...fieldProps}
      fullWidth
      size="small"
      autoComplete="current-password"
      type={showPassword ? 'text' : 'password'}
      label={translations.common.password()}
      sx={sx}
      error={hasError}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
              <Icon icon={showPassword ? eyeFill : eyeOffFill} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
