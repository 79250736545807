import Title from '../components/Title';
import Text from '../components/Text';

type Props = {};

export default function AboutService(props: Props) {
  return (
    <div>
      <Title>Что нужно знать о TourAgent Club?</Title>
      <Text>- Использование всех сервисов TourAgent Club абсолютно бесплатно и доступно сразу после регистрации.</Text>
      <Text>
        - Вам не нужно обучать персонал работе с сервисами. Все наши инструменты интуитивно понятны, просты и уже готовы
        к использованию сразу после регистрации. Но если возникают вопросы – всегда можно посмотреть FAQ (часто
        задаваемые вопросы) или задать вопрос в поддержку.
      </Text>
      <Text sx={{ mb: 4 }}>
        - Мы выплачиваем Вам вознаграждение за продажи и использование наших сервисов. Все условия можно посмотреть в
        разделе этого сервиса в FAQ.
      </Text>

      <Title>Как работать с TourAgent Club?</Title>
      <Text>
        <strong>Для юридических лиц:</strong>
        <br />
        Чтобы наши отношения носили официальный характер, вам, как юридическому лицу, может потребоваться корпоративная
        карта. Завести её вы можете в любом обслуживающем вас банке.
      </Text>
      <Text sx={{ mb: 2 }}>
        Карта потребуется для того, чтобы совершать покупки. Так как они осуществляются непосредственно на сайтах
        партнеров (страховых компаний, кэшбэк-сервисов и т.д.), Вы не сможете использовать банковский платеж. Постоплата
        исключена.
      </Text>
      <Text sx={{ mb: 2 }}>
        <strong>Для физических лиц:</strong>
        <br />
        Для официального характера взаимодействия необходимо иметь статус самозанятого.
      </Text>
      <Text>
        <b>Не перепутайте:</b> мы занимаемся только тем, что связываем Вас с сервисами и позволяем зарабатывать на их
        услугах, а Вы осуществляете саму покупку/бронь напрямую у поставщика через наш сервис. То есть Вы платите не
        нам, а нашим партнёрам, поэтому по всем организационным вопросам по возврату билетов, страховок, неправильно
        выписанным документам и т. д. Вам необходимо обращаться к ним.
      </Text>
      <Text>
        Если Вам не удалось решить проблему самостоятельно, обращайтесь в нашу службу поддержки по адресу
        <strong> support@touragent.club</strong>.
      </Text>
      <Text sx={{ mb: 4 }}>Наши партнёры для нас на первом месте.</Text>

      <Title>Юридические моменты</Title>
      <Text>При регистрации Вы принимаете условия договора оферты.</Text>
      <Text sx={{ mb: 4 }}>
        Физлицам и юрлицам для работы достаточно договора оферты, заключённого в электронном виде.
      </Text>

      <Title>Как производится выплата вознаграждения</Title>
      <Text>
        Вывод средств возможен только после того, как средства попали на доступный баланс. Это происходит после
        подтверждения от партнера. Подробнее об условии выплаты по каждому сервису читайте в описании этого сервиса.
      </Text>
      <Text>
        Получить вознаграждение с доступного баланса можно после набора минимальной суммы выплаты: от 100 руб.
      </Text>
      <Text sx={{ mb: 4 }}>Выплата вознаграждения осуществляется по запросу через выбранный механизм выплаты.</Text>

      <Title>Дополнительная информация</Title>
      <Text>
        Мы не влияем на указанные цены, так как мы предоставляем сервис, связывающий Вас с партнёром, а не продажу. Цена
        всегда окончательна, и ее нельзя изменить.
      </Text>
      <Text>
        Вы можете брать сервисный сбор со своих клиентов за услуги предоставленные через наш сервис. Таким образом, Ваш
        заработок будет состоять из сервисного сбора (устанавливаете его сами) и дохода за покупку/бронирование (уже
        включён в итоговую цену). Двойная выгода для Вас!
      </Text>
    </div>
  );
}
