export type LifestyleActivityOption = { name: string; label: string; value: number | null };

export const LifestyleActivityOptions: LifestyleActivityOption[] = [
  { name: 'None', label: 'Обычный', value: null },
  { name: 'Hazardous Sport', label: 'Спорт', value: 41 },
];

export const getLifestyleActivityName = (value: number | null) => {
  const selectValue = value || null;
  return LifestyleActivityOptions.find((o) => o.value === selectValue)!.name;
};

export const getLifestyleActivityLabel = (value: number | null) => {
  const selectValue = value || null;
  const label = LifestyleActivityOptions.find((o) => o.value === selectValue)?.label;
  return label || '';
};
