import { Button, Card, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import { CashbackOffer } from 'types/models/cashback-offer';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AspectRatioBox from 'components/containers/AspectRatioBox';
import { useCashbackLink } from 'hooks/cashback/useCashbackLink';
import { useState } from 'react';
import CashbackOfferCardInfoItem from './CashbackOfferCardInfoItem';
import CashbackOfferDetailsDialog from './CashbackOfferDetailsDialog';
import OpenLinkCashbackButton from './OpenLinkCashbackButton';
import CreateTouristLinkCashbackButton from './CreateTouristLinkCashbackButton';

type Props = {
  offer: CashbackOffer;
};

export default function CashbackOfferCard(props: Props) {
  const { offer } = props;
  const [showGoByLink, setShowGoByLink] = useState(false);
  const [showTouristLink, setShowTouristLink] = useState(false);
  const { isCopingDeeplink, isOpeningDeeplink, copyToClipboard, openDeeplink } = useCashbackLink(offer.id);

  return (
    <Grid key={offer.id} item xs={12} sm={4} md={3} xl={2}>
      <Card
        onMouseEnter={() => {
          if (!isCopingDeeplink) {
            setShowGoByLink(true);
          }
        }}
        onMouseLeave={() => setShowGoByLink(false)}
      >
        <AspectRatioBox ratio={5 / 3} style={{ backgroundColor: offer?.backgroundColor || 'white' }}>
          {(showTouristLink || showGoByLink || isOpeningDeeplink) && (
            <div
              style={{
                backgroundColor: 'rgba(250,250,250, 0.7)',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                boxSizing: 'border-box',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '17px 6px 15px',
                cursor: 'pointer',
              }}
            >
              <OpenLinkCashbackButton
                isOpeningDeepLink={isOpeningDeeplink}
                openDeepLink={() => !isCopingDeeplink && openDeeplink()}
              />

              <CreateTouristLinkCashbackButton
                offer={offer}
                onDialogOpen={() => setShowTouristLink(true)}
                onDialogClose={() => {
                  setShowTouristLink(false);
                  setShowGoByLink(false);
                }}
              />
            </div>
          )}
          <div style={{ background: `url('${offer.imageUrl}') center / contain`, backgroundRepeat: 'no-repeat' }}></div>
        </AspectRatioBox>

        <Stack sx={{ m: 2 }}>
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Typography sx={{ flexGrow: 1, letterSpacing: 0.3 }} variant="subtitle1">
              {offer.name}
            </Typography>
            <Button
              variant="outlined"
              onClick={copyToClipboard}
              size="small"
              sx={{ minWidth: 40 }}
              disabled={isCopingDeeplink || isOpeningDeeplink}
            >
              {isCopingDeeplink ? <CircularProgress size={16} /> : <InsertLinkIcon sx={{ width: 20, height: 20 }} />}
            </Button>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Stack spacing={0.5}>
            <CashbackOfferCardInfoItem title={'Ставка'} value={`${offer.paymentRate}`} />
            <CashbackOfferCardInfoItem title={'Срок оплаты'} value={`${offer.averagePaymentPeriodInDays} д.`} />
            <CashbackOfferCardInfoItem title={'% подтверждения'} value={`${offer.approveRate}%`} />
          </Stack>

          <CashbackOfferDetailsDialog offer={offer} onDialogOpen={() => setShowGoByLink(false)}>
            <Stack direction="row">
              <Button variant="outlined" size="small" sx={{ mt: 2, flexGrow: 1 }}>
                Подробнее
              </Button>
            </Stack>
          </CashbackOfferDetailsDialog>
        </Stack>
      </Card>
    </Grid>
  );
}
