import * as Yup from 'yup';
import { useMemo } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { RoleTypeValues } from 'types/account';
import { InputConfig } from 'config';

export const useValidationRules = () => {
  const translations = useTranslations();

  const rules = useMemo(() => {
    const nameRegex = /^[\u0401\u0451\u0410-\u044f .'-]+$/i;

    const requiredMessage = translations.forms.requiredField();
    const nameMessage = translations.forms.russianNameRequirements();
    const minLengthMessage = (length: number) => translations.forms.minLength({ length });
    const maxLengthMessage = (length: number) => translations.forms.maxLength({ length });

    const nameRule = Yup.string()
      .matches(nameRegex, nameMessage)
      .min(2, minLengthMessage(2))
      .max(50, maxLengthMessage(50))
      .typeError(requiredMessage)
      .required(requiredMessage);

    return {
      firstName: () => nameRule,
      lastName: () => nameRule,
      middleName: () => nameRule.optional(),

      email: () => Yup.string().email(translations.forms.incorrectEmail()).required(requiredMessage),
      gender: () => Yup.string().required(requiredMessage),

      roleType: () => Yup.string().oneOf(RoleTypeValues, translations.forms.incorrectValue()).required(requiredMessage),

      password: () =>
        Yup.string()
          .min(8, translations.forms.minLength({ length: 8 }))
          .required(requiredMessage),

      inn: () =>
        Yup.string()
          .typeError(translations.forms.invalidInn())
          .test(
            'inn',
            translations.forms.invalidInn(),
            (value) => !value || value?.length === 10 || value?.length === 12,
          ),

      ogrn: () =>
        Yup.string()
          .typeError(translations.forms.invalidOgrn())
          .test(
            'ogrn',
            translations.forms.invalidOgrn(),
            (value) => !value || value?.length === InputConfig.ogrnLength,
          ),

      bik: () =>
        Yup.string()
          .typeError(translations.forms.invalidBik())
          .test('bik', translations.forms.invalidBik(), (value) => !value || value?.length === InputConfig.bikLength),

      correspondentAccount: () =>
        Yup.string()
          .typeError(translations.forms.invalidСorrespondentAccount())
          .test(
            'correspondentAccount',
            translations.forms.invalidСorrespondentAccount(),
            (value) => !value || value?.length === InputConfig.correspondentAccountLength,
          ),

      paymentAccount: () =>
        Yup.string()
          .typeError(translations.forms.invalidPaymentAccount())
          .test(
            'paymentAccount',
            translations.forms.invalidPaymentAccount(),
            (value) => !value || value?.length === InputConfig.paymentAccountLength,
          ),

      kpp: () =>
        Yup.string()
          .typeError(translations.forms.invalidKpp())
          .test('kpp', translations.forms.invalidKpp(), (value) => !value || value?.length === InputConfig.kppLength),

      messageSubject: () => Yup.string().required(requiredMessage),
      messageText: () =>
        Yup.string()
          .min(20, translations.forms.minLength({ length: 20 }))
          .required(requiredMessage),

      phone: (params?: { required?: boolean }) => {
        const rule = Yup.string()
          .matches(/^\+79[0-9]{9,9}$/, translations.forms.invalidPhone())
          .typeError(translations.forms.invalidPhone());

        const isRequired = params?.required === undefined || params?.required;
        return isRequired ? rule.required(requiredMessage) : rule;
      },
      url: () => Yup.string().url(),
    };
  }, [translations]);

  return rules;
};
