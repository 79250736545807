import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTranslations } from 'hooks/useTranslations';
import { FormikProps } from 'utils/ui/formik';
import { getLifestyleActivityName, LifestyleActivityOptions } from 'assets/data/lifestyleActivityOptions';

type Props = {
  formikProps: FormikProps;
};

export default function LifestyleActivitySelect(props: Props) {
  const translations = useTranslations();
  const { id, defaultValue, fieldProps, helperText, hasError, setFieldValue } = props.formikProps;

  const [activityLifestyleValue, setActivityLifestyleValue] = useState(getLifestyleActivityName(defaultValue));

  useEffect(() => {
    if (defaultValue === undefined) return;

    const fieldValue = LifestyleActivityOptions.find((o) => o.value === defaultValue)!.value;
    setFieldValue(fieldValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`} error={hasError}>
        {translations.common.lifestyleActivity()}
      </InputLabel>
      <Select
        {...fieldProps}
        labelId={`${id}-label`}
        id={id}
        value={activityLifestyleValue}
        error={hasError}
        defaultValue={getLifestyleActivityName(defaultValue)}
        label={translations.common.lifestyleActivity()}
        onChange={(e) => {
          const newValue = e.target.value;
          const fieldValue = LifestyleActivityOptions.find((o) => o.name === newValue)!.value;
          setActivityLifestyleValue(newValue);
          setFieldValue(fieldValue);
        }}
      >
        {LifestyleActivityOptions.map((option) => {
          return (
            <MenuItem key={option.name} value={option.name}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText error={hasError}>{helperText}</FormHelperText>}
    </FormControl>
  );
}
