import { useTranslation } from 'react-i18next';
import { ruRU } from '@mui/material/locale';
import moment from 'moment';
import { useEffect } from 'react';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

const LANGS = [
  {
    label: 'Русский',
    value: 'ru',
    systemValue: ruRU,
    icon: '/static/icons/ic_flag_ru.svg',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = (newLang: string) => {
    i18n.changeLanguage(newLang);
  };

  useEffect(() => {
    moment.locale(currentLang.value);
  }, [currentLang]);

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
