import React from 'react';
import { SxProps, Theme } from '@mui/system';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import ruLocale from 'date-fns/locale/ru';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useTranslations } from 'hooks/useTranslations';
import { FormikProps } from 'utils/ui/formik';
import moment from 'moment';

type Props = {
  sx?: SxProps<Theme>;
  startDateFormikProps: FormikProps;
  endDateFormikProps: FormikProps;
};

export default function TravelDatesPicker(props: Props) {
  const { sx, startDateFormikProps, endDateFormikProps } = props;
  const translations = useTranslations();

  const maxDate = moment().add(1, 'year').add(6, 'months').toDate();
  const minDate = moment().add(6, 'days').toDate();

  return (
    <Box sx={sx}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <DateRangePicker
          toolbarTitle={translations.forms.chooseDates()}
          startText={translations.policy.travelCancellation.travelStart()}
          endText={translations.policy.travelCancellation.travelEnd()}
          mask={'__.__.____'}
          value={[startDateFormikProps.value, endDateFormikProps.value]}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(newValue) => {
            const startDate = newValue[0];
            const endDate = newValue[1];

            startDateFormikProps.setFieldValue(startDate);
            endDateFormikProps.setFieldValue(endDate);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <Box sx={{ ml: 1 }}>{translations.common.from()}</Box>
              <TextField
                fullWidth
                {...startProps}
                sx={{ ml: 2 }}
                error={startDateFormikProps.hasError}
                helperText={startDateFormikProps.helperText}
              />
              <Box sx={{ mx: 2 }}>{translations.common.to()}</Box>
              <TextField
                fullWidth
                {...endProps}
                error={endDateFormikProps.hasError}
                helperText={endDateFormikProps.helperText}
              />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}
