import { Box, Button, Card, Typography } from '@mui/material';
import Subtitle from 'components/text/Subtitle';
import { useTourAgencyMissingData } from 'hooks/tour-agency/useTourAgencyMissingData';
import { useTranslations } from 'hooks/useTranslations';
import { useCallback } from 'react';
import { TourAgency } from 'types/models/tour-agency';

type Props = {
  tourAgency: TourAgency;

  goToProfileEditing: () => void;
  goToTourAgencyDataEditing: () => void;
};

export default function FillTourAgencyDataView(props: Props) {
  const { tourAgency, goToProfileEditing, goToTourAgencyDataEditing } = props;
  const translations = useTranslations();
  const missingData = useTourAgencyMissingData(tourAgency);

  const goToEdit = useCallback(() => {
    if (missingData.length === 1 && missingData[0] === translations.common.contactPhone()) {
      goToProfileEditing();
      return;
    }

    goToTourAgencyDataEditing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingData, translations]);

  return (
    <Card sx={{ p: 3, maxWidth: 600 }}>
      <Subtitle>{translations.account.fillTourAgencyData()}</Subtitle>

      <Typography variant="body1" sx={{ mt: 0, mb: 1 }}>
        <b>{translations.account.missingData() + ':'}</b>
      </Typography>

      <Box sx={{ mb: 3 }}>
        {missingData.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </Box>

      <Button size="medium" variant="contained" onClick={goToEdit}>
        {translations.common.fill()}
      </Button>
    </Card>
  );
}
